import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm } from "react-hook-form";
import TextArea from "../../Components/Inputs/TextArea";
import SelectField from "../../Components/Inputs/SelectField";
import { useDispatch, useSelector } from 'react-redux';
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import dummyProfile from "../../Assets/Images/dummy-profile.jpg";
import Swal from "sweetalert2";
import { phoneRegex } from "../../Constants";

function CreateEditModal({
  showEdit,
  handleCloseEdit,
  isEdit,
  userDetails,
  userData,
  fetchData,
  perPage,
  businessId,
}) {
  const [userRoles, setUserRoles] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [panchayatsData, setPanchayatsdata] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [districts, setDistricts] = useState([]);
  const dispatch = useDispatch()
  const authUser = useSelector((state) => state.auth.user);
  const  loginedKey =  authUser.user_uuid;

  const methods = useForm({
    defaultValues: {
      first_name: userDetails.first_name || "",
      last_name: userDetails.last_name || "",
      email: userDetails.email || "",
      phone_number: userDetails.phone_number || "",
      location: userDetails.location || "",
      user_role_id: userDetails.user_role_id || "",
      location_id: userDetails.location_id || "",
      // panchayat_id: userDetails.panchayat_id || "",
      address: userDetails.address || "",
      
    },
  });

  const { handleSubmit, reset, setValue ,watch,resetField } = methods;

  // const districtField = watch("location_id");
  // useEffect(() => {  
  //   setPanchayats(panchayatsData[districtField]);
  //   resetField('panchayat_id');
  // }, [districtField]);


  const fetctDetails = async () => {
    try {
      const response = await MakeAxiosRequest("post", {}, "/user/fetchdetails");
      //  console.log(response)
      if (response.status == 1) {
        const userroles = response.data.userroles?.map((item) => ({
          // Transform each item as needed
          id: item.id,
          name: item.user_role,
        }));
        const districts = response.data.districts?.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setUserRoles(userroles);
        setDistricts(districts);
        setPanchayatsdata(response.data.panchayats);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    if (isEdit) {
      updateUser(data);
    } else {
      createUser(data);
    }
  };
  const createUser = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post", 
          { ...data, hasImage,fileKey,fileCordinates }, 
          "/user/store");
      // console.log(response);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'
        // const userKey = response.userDetails.user_uuid;
    
        reset();
        handleCloseEdit();
        userData && userData(1);
        fetchData && fetchData(1);
        Swal.fire({
          icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, text: response.message
        });
       
     
        // alert(response.message);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const updateUser = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data, hasImage,fileKey,fileCordinates },
        `/user/${userDetails?.user_uuid}/update`
      );
      // console.log(data);
      // console.log(userDetails);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'
        // const userKey = response.userDetails.user_uuid;

        // Call FileUpload function with the user key
        // file && (await FileUpload(userDetails.user_uuid));

        reset();
        handleCloseEdit();
        Swal.fire({
          icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, text: response.message
        });
       
        userData && userData(1, perPage);
        fetchData && fetchData(1, perPage);
        // alert(response.message);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState(dummyProfile);
  const [fileKey, setFileKey] = useState('');
  const [fileCordinates, setFileCordinates] = useState();
  const [hasImage, sethasImage] = useState(0);

  function removeImage() {
    setFile();
    setFilePath(dummyProfile);
    sethasImage(0);
  }

  function handleFileUpload(e) {
    if (e.target && e.target.files[0]) {
      setFile(e.target.files[0]);
      // console.log(e.target.files[0]);
      // setFilePath(URL.createObjectURL(e.target.files[0]));
      sethasImage(1);
      setImageUploaded(true);
      setSubmitDisabled(true);
    }
  }

  // const FileUpload = async (userKey) => {
  //   console.log("file");
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   try {
  //     // console.log(formData);
  //     const response = await MakeFileUpload(
  //       "post",
  //       formData,
  //       `user/${userKey}/uploadprofileimage`
  //     );

  //     // console.log(response);
  //     // }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      rules: { required: "Please enter first name" },
      type: "inputfield",
      // value: userDetails.first_name,
      isShow: true ,
    },
    {
      name: "last_name",
      label: "Last Name",
      rules: { required: "Please enter last name" },
      type: "inputfield",
      isShow: true ,
    },
    {
      name: "email",
      label: "Email",
      rules: {
        required: "Please enter email",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await MakeAxiosRequest(
              "post",
              { email: fieldValue, userid: userDetails?.id, businessid:businessId },
              "/checkemailexist"
            );
            // console.log(response.data.hasData);
            return response.data.hasData == 0 || "Email already exist";
          },
        },
      },
      type: "inputfield",
      isShow: true ,
    },
    {
      name: "phone_number",
      label: "Phone Number",
      rules: {
        required: "Please enter phone number",
        pattern: {
          // value:
          //   /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          value:phoneRegex,
          message: "Please enter a valid phone number",
        },
        validate: {
          phoneExist: async (fieldValue) => {
            const response = await MakeAxiosRequest(
              "post",
              { phone_number: fieldValue, userid: userDetails?.id,businessid:businessId },
              "/checkphoneexist"
            );
            // console.log(response.data.hasData);
            return response.data.hasData == 0 || "Phone number already exist";
          },
        },
      },
      type: "inputfield",
      isShow: userDetails.user_uuid !== loginedKey,

    },
    {
      name: "location_id",
      label: "District",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please select district" },
      type: "select",
      options: districts,
      isShow: businessId ? false :true ,
    },
    // {
    //   name: "panchayat_id",
    //   label: "Panchayath",
    //   className: "col-md-6 col-lg-6 mb-3",
    //   rules: { required: "Please select panchayath" },
    //   type: "select",
    //   options: panchayats,
    //   isShow: businessId ? false :true ,
    // },
    // {
    //   name: "location",
    //   label: "Location",
    //   rules: { required: "Please enter location" },
    //   type: "inputfield",
    //   isShow: businessId ? false :true ,
    // },
    {
      name: "user_role_id",
      label: "User Role",
      rules: { required: "Please select user role" },
      type: "select",
      options: userRoles,
      isShow: (businessId || userDetails?.user_role_id=='4') ? false :true ,
    },
    {
      name: "address",
      label: "Address",
      rules: { required: "Please enter address" },
      type: "textarea",
      isShow: businessId ? false :true ,
    },
  ];

  useEffect(() => {
    fetctDetails();
  }, []);

  useEffect(() => {
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFile();
    setFilePath(userDetails.profile_img || dummyProfile);
    sethasImage(userDetails.hasImage || 0);
    reset({
      first_name: userDetails.first_name || "",
      last_name: userDetails.last_name || "",
      email: userDetails.email || "",
      phone_number: userDetails.phone_number || "",
      location_id: userDetails.location_id || "",
      // panchayat_id: userDetails.panchayat_id || "",
      user_role_id: userDetails.user_role_id || "",
      address: userDetails.address || "",
      business_id: businessId || "",
    });
    return () => {
      setImageUploaded(false);
      setSubmitDisabled(false);
      setFile();
      setFilePath(dummyProfile);
      sethasImage(0);    
      reset();
    };
  }, [showEdit, isEdit, userDetails]);

  const fileRef = useRef(null);
  const openFile = () => {
    fileRef.current.click();
  };

  const croppedImage = (imgPath,tempKey,cordinates) => {
    // console.log(imgPath);
    setFile(null);
    setFilePath(imgPath);
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFileKey(tempKey);
    setFileCordinates(cordinates || null);
  };

  return (
    <>
      <Modal
        show={showEdit}
        //  onHide={handleCloseEdit}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div className="modal-content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-4">
                    <div className="text-center">
                      <h5>{isEdit ? "Edit user" : "Create a new user"}</h5>
                      <p className="gray">
                        {isEdit
                          ? "Edit user on Aladinz app"
                          : "Add a new user on Aladinz app"}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      {!isImageUploaded && (
                        <div className="crte-bsness-top-banner-img modal-prfl">
                          <img className="rounded-pill"  id="createimage" src={filePath}
                            alt="" onClick={() => {
                              openFile();
                            }}
                          />
                          <input
                            style={{ visibility: "hidden" }}
                            ref={fileRef}
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            onChange={handleFileUpload}
                          />

                          {hasImage == "1" ? (
                            <a href="javascript:void(0)" className="img-cnrtl-btn" onClick={removeImage}>
                              <span className="dlt-icon">
                                <i className="fa-regular fa-trash-can"></i>
                              </span>
                            </a>
                          ) : (
                            <a href="javascript:void(0)" className="img-cnrtl-btn" onClick={openFile}>
                              <span className="">
                                <i className="fa-regular fa-add"></i>
                              </span>
                            </a>
                          )}
                        </div>
                      )}
                      {isImageUploaded && (
                        <ImageCropper
                          file={file}
                          onCropped={croppedImage}
                          aspectRatio={1/1}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  {fields?.map((field, index) => {
                  return field.isShow && (
                    <React.Fragment key={index}>
                      {(() => {
                    switch (field.type) {
                      case "inputfield":
                        return (
                          <InputField
                            key={index}
                            className="col-md-6 col-lg-6 mb-3"
                            {...field}
                          />
                        );
                      case "textarea":
                        return (
                          <TextArea
                            key={index}
                            className="col-md-12 mb-3"
                            {...field}
                          />
                        );
                      case "select":
                        return (
                          <SelectField
                            key={index}
                            className="col-md-6 col-lg-6 mb-3"
                            {...field}
                          />
                        );
                      default:
                        break;
                    }
                  })()}
                  </React.Fragment>
                );
              })}
                </div>
              </div>

              <div className="modal-footer b-0">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitDisabled}
                >
                  {isEdit ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
}

export default CreateEditModal;
