import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm } from "react-hook-form";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import Swal from "sweetalert2";
import AddImageField from "../../Components/Inputs/AddImageField";
import SelectField from "../../Components/Inputs/SelectField";
import DateField from "../../Components/Inputs/DateField";
import TextArea from "../../Components/Inputs/TextArea";

function RenewSubscription({ show, handleClose, businessKey, fetchData,viewTab,subscriptionDetails }) {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(true);
  const [minDate, setMindate] = useState();
  const [subscriptionplans, setSubscriptionplans] = useState([]);

  const methods = useForm();

  const { handleSubmit, reset, register, setValue,trigger } = methods;

  const fetctDetails = async () => {
    try {
      const response = await MakeAxiosRequest(
        "post",
        {},
        `business/${businessKey}/subscription/renew/fetchdetails`
      );
      // console.log(response)
      if (response.status == 1) {
        setMindate(response.data.subscription_end_date);
        const subscriptionplans = response.data.subscriptionplans?.map((item) => ({
          id: item.id,
          name: item.subscription_name,
        }));
        setSubscriptionplans(subscriptionplans);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const create = async (data) => {
    // console.log(data);
    setSubmitDisabled(true);
    setShowCancelBtn(false)
    const url = (subscriptionDetails != '') 
      ? `/business/${businessKey}/subscription/${subscriptionDetails.subscription_uuid}/update` 
      : `/business/${businessKey}/subscription/renew`;
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data},
        url
      );
      // console.log(response);
      if (response.status == 1) {
        reset();
        handleClose();     
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        // fetchData(1);
        viewTab('subscriptions')
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  useEffect(() => {  
  
    reset({
      subscription_plan_id: subscriptionDetails.subscription_plan_id || "",
      subscription_start_date: subscriptionDetails.start_date || "",
      /*subscription_end_date: subscriptionDetails.end_date || "",*/
      subscription_description : subscriptionDetails.transaction_details || "",
    });
    return () => {
      reset();
    };
  }, [subscriptionDetails]);

  useEffect(() => {
    fetctDetails();
  }, []);

  useEffect(() => {
    setSubmitDisabled(false);
    setShowCancelBtn(true);
    reset;
    return () => {
      setSubmitDisabled(false);
      setShowCancelBtn(true);
      reset();
    };
  }, [show]);

  const fields = [
    {
      name: "subscription_plan_id",
      label: "Subscription Plan",
      rules: { required: "Please select your subscription plan" },
      type: "select",
      className: "col-12 mb-3",
      options: subscriptionplans,
      isShow: true,
    },
    {
      name: "subscription_start_date",
      label: "Subscription Start Date",
      rules: { required: "Please select subscription start date" },
      type: "date",
      className: "col-12 mb-3",
      minDate: ( subscriptionDetails == '') ? minDate : '',
      isShow: true,
    },
    /* {
    //   name: "subscription_end_date",
    //   label: "Subscription End Date",
    //   rules: { required: "Please select subscription end date" },
    //   type: "date",
    //   className: "col-12 mb-3",
    //   minDate: ( subscriptionDetails == '') ? minDate : '',
    //   isShow:( subscriptionDetails != '') ? true : false,
    },*/
    {
      name: "subscription_description",
      label: "Transaction Note",
      className: "col-md-6 col-lg-12 mb-3",
      rules: { required: "Please enter transaction note" },
      type: "textarea",
      isShow: true,
    },
  ];

  return (
    <>
      <Modal
        show={show}
        // onHide={handleCloseCustom}
        dialogClassName="modal-dialog modal-dialog-centered modal-md"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(create)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="text-center">
                    <h5>Renew subscription</h5>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
               
                  <>
                  {fields?.map((field, index) => {
                          return (
                            field.isShow && (
                              <React.Fragment key={index}>
                                {(() => {
                                  switch (field.type) {
                                    case "select":
                                      return (
                                        <SelectField
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    case "date":
                                      return (
                                        <DateField
                                          key={index}
                                          {...field}
                                        />
                                      );
                                      case "textarea":
                                      return (
                                        <TextArea
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </React.Fragment>
                            )
                          );
                        })}
                  </>
                
              </div>
            </div>
            <div className="modal-footer b-0">
              {showCancelBtn && 
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              }
              
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitDisabled}
              >
                
                {subscriptionDetails ? 'Update' : 'Renew'}
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}

export default RenewSubscription;
