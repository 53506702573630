import React, { useEffect,useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Notifications from './Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { logout, update, updateNotificationCount, updateUnreadNotificationCount } from '../../features/authSlice';
import { navbarTitle } from '../../Constants';
import { Helmet } from 'react-helmet';
import { MakeAxiosRequest } from '../../utils/handler';
import loaderFile   from '../../Assets/Images/loader.gif';


function Navbar({toggleSidebar}) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutUser = ()=> {
    dispatch(logout())
    navigate("/login");
   }
   const unreadNotificationCount = useSelector(state => state.auth.unreadNotificationCount);

   useEffect(() => {
      dispatch(updateNotificationCount());
    }, [dispatch]);

   const [showNotifications, setShowNotifications] = useState(false);
   const [count, setCount] = useState('');
   const [isLoaded, setIsLoaded] = useState(false);
   const [notifications, setNotification] = useState({});
   const [unReadNotifications, setUnReadNotifications] = useState(false);

   const fetchNotifications = async () => {
      setShowNotifications(false);
      setIsLoaded(false);
      try {
  
        const res = await MakeAxiosRequest("post", { }, '/fetchnotifications');
        const result = await MakeAxiosRequest("post", {}, `/notifications/unreadcount`);
         dispatch(updateUnreadNotificationCount(result)); 
        setIsLoaded(true);
        setNotification(res.notification)
        setCount(res.totalcount)
        setShowNotifications(true);
      } catch (error) {
        console.error('Error fetching notification:', error);
      }
    };
    
    

   const userData = async () => {  
      const res = await MakeAxiosRequest("post", {}, `/myprofile`);       
     dispatch(update(res.data.userdets));
     const result = await MakeAxiosRequest("post", {}, `/notifications/unreadcount`);
      dispatch(updateUnreadNotificationCount(result)); 
   }
   useEffect(() => {     
      userData(); 
        
      // getUnReadCount();   
   }, []);

     
   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
   const authUser = useSelector((state) => state.auth.user);
   // const unReadCount = useSelector((state) => state.auth.pendingcount.unReadCount);
   const location = useLocation();
   const currentPath = location.pathname.split('/')[1];

   // Find the item in navbarTitle that matches the current route
   const currentNavItem = navbarTitle.find((item) => item.link === currentPath);
 
   // Display the title from the matched item or a default value if not found
   const title = currentNavItem ? currentNavItem.title : 'Page Not Found';


// console.log(authUser)
  return (
    <>
      <Helmet>
          <title>Aladinz | {title}</title>
      </Helmet>
      <nav className="navbar navbar-expand navbar-light topbar static-top">
               <div className="d-flex align-items-center">
                 {/* Sidebar Toggle (Topbar)  */}
                  <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle ps-0 " onClick={toggleSidebar}>
                  <i className="fa fa-bars"></i>
                  </button>
                  <h1 className="h3 mb-0">{title}</h1>
               </div>
               <div>
                   {/* Topbar Navbar  */}
                  <ul className="navbar-nav ml-auto">
                  <li className="ms-2" onClick={fetchNotifications}>
                        <div className="btn-group notification-sec dropdown-center">
                        <a className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                           <span className="material-symbols-outlined">
                           notifications
                           </span>
                           {unreadNotificationCount == 1 && <span className="not-indication"></span>}
                           </a>
                           <ul className="dropdown-menu dropdown-menu-end ">
                           {!isLoaded ? (
    <div><center><img src={loaderFile} style={{ width: '100px' }} alt="Loading..." /></center></div>
  ) : (
    showNotifications && <Notifications notifications={notifications} count={count} />
  )}
                           </ul>
                        </div>
                     </li> 
                     <li className="ms-2">
                        <div className="btn-group header-profile">
                           <a className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="profie-btn">
                                 <img className="" src={authUser?.profileimg} alt="" />
                                 <div className="pfl-drpup">
                                    <h5>{authUser?.name}</h5>
                                    <span>{authUser?.user_role == "1" ? 'Admin' : 'Staff'}</span>
                                 </div>
                              </div>
                           </a>
                           <ul className="dropdown-menu dropdown-menu-end">
                              <li className="nav-item">
                                 <Link className="nav-link d-flex align-items-center" to={'/myprofile'}>
                                    <span className="material-symbols-outlined mx-2">account_circle</span>
                                    <p className="mb-0">Profile</p>
                                 </Link>
                              </li>
                              <li className="nav-item">
                                 <a href="javascript:void(0)" className="nav-link d-flex align-items-center" onClick={logoutUser}>
                                    <span className="material-symbols-outlined mx-2">logout</span>
                                    <p className="mb-0">Logout</p>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                  </ul>
               </div>
            </nav>
    </>
  )
}

export default Navbar