import React, {useState , useEffect} from 'react';   
import { useNavigate, useParams } from 'react-router-dom';
import { MakeAxiosRequest } from '../../utils/handler';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import dummyProfile  from "../../Assets/Images/dummy-profile.jpg"
import dummyBanner  from "../../Assets/Images/cover.png"
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import loaderFile   from '../../Assets/Images/loader.gif';
import CreateEditModal from './CreateEditModal';
import Business from './Business';
import Transactions from './Transactions';
import Reports from './Reports';
import Overview from './Overview';
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import Agentlist from './Agentlist'
import Payout from './Payouts';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import KycList from './KycList';

function AgentDetails() {
  const statusOptions = [
    { value: "0", className: "btn btn-success-outline m-1", icon:<i class="fa-solid fa-check me-2"></i>,  label: "activate" },
    { value: "1", className: "btn btn-danger-outline m-1", icon:<i class="fa-solid fa-trash-can me-2"></i>,  label: "deactivate" },     
  ];


    const params = useParams()
    const agentKey = params.key;
    const tab = params.tab || 'overview';

    const navigate = useNavigate();

    const [agentDetails, setItems] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [reportsData, setReportsData] = useState([]);
    const [KycData, setKycData] = useState([]);
    const [businessData, setBusinessData] = useState([]);
    const [overviewData, setOverviewData] = useState([]);
    const [agentlistData, setAgentlistData] = useState([]);
    const [payoutData, setPayoutData] = useState([]);
    const [agentTranferData, setAgentTranferData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tabActive, setTabActive] = useState('overview');
    const [error, setError] = useState(null);
    const [listParams, setListParams] = useState({
      offset: "0",  sortBy: "newest",
    });
    const { setBreadcrumbsData } = useBreadcrumb();
     const agentData = async () => {      
      const res = await MakeAxiosRequest("post", {}, `/agent/details/${agentKey}`);       
      setItems(res.data.agentuserdets);    
      setIsLoaded(true);
      setTabList(res.data.tabs);
      const index = res.data.tabs.findIndex((item) => item.value === tab);
      if(index>0){
        changeTabList(tab);
        setActiveTab(index);
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      }else{
        changeTabList('overview');
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      }
    }

    const [activeTab, setActiveTab] = useState(0);
    const onTabClick = (e, index) => {   
      changeTabList(tabList[index].value);
     setActiveTab(index);     
   };

    useEffect(() => {
      // changeTabList('overview');
      setIsLoaded(false);
      agentData();
      changeTabList('overview');
      setActiveTab(0);
    }, [agentKey]);

     // Breadcrumbs
     const updateBreadcrumbs = () => {
      setBreadcrumbsData([
        { label: "Agent", path: `/agents` },
        { label: "Details" },
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 

    const handlePageChange = (page,newOffset,filterName,value) => {       
     changeTabList(page, { ...listParams, offset: newOffset.toString(),[filterName]: value });
    }
    
    const handleSelectChange = (page,filterName,value,newOffset) => {    
      changeTabList(page, { ...listParams, [filterName]: value,offset: newOffset.toString() });     
    };

      const changeTabList = async (page,listParams) => {
        navigate('/agent/'+agentKey+'/'+page, { replace: true });

        const subres = await MakeAxiosRequest("post", {...listParams}, `/agent/${agentKey}/${page}`);       
        setTabActive(page);       
        page==='overview' && setOverviewData(subres.data);
        page==='subagents' && setAgentlistData(subres.data);
        page==='kyc' && setKycData(subres.data);
        page==='transactions' && setTransactionData(subres.data);    
        page==='reports' && setReportsData(subres.data);
        page==='payouts' && setPayoutData(subres.data);
        page==='business'  && setBusinessData(subres.data);
      };

    const renderTextWithLineBreaks = (data) => {
      const html = data.replace(/\n/g, '<br />');
      return {__html:html}  ;
    };
 
    const [isEdit, setisEdit] = useState(false);
    const [showEdit, setshowEdit] = useState(false);
  

    const handleshowEdit = (agent) => {
      setisEdit(true)
      setshowEdit(true)
      setItems(agent)      
    };

  
    const handleCloseEdit = () => setshowEdit(false);  
    const showTransferAct = async () => {    
      try {
        const transferDet = await MakeAxiosRequest("post", {}, `/agent/${agentKey}/transferaccount/fetchdetails`);
         setAgentTranferData(transferDet.data.agentslist); 
         setIsLoaded(true);
         if (transferDet.data.agentslist.length === 0) {
          Swal.fire({ 
            icon: 'warning', 
            customClass: { confirmButton: 'btn btn-primary' },  
            text: `No ${agentDetails.user_role_id ==6 && 'Super '}agents available to transfer the account.`  
          });
        } else {
          $('#tranfer-account').modal('show');      
        }
      } catch (error) {
          console.error(error);
      }
    };

     // Status change of agents 
     const changeStatus = async (agentKey, status, type) => {
      const statusType = type.toLowerCase();    
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          html:  `Are you sure you want to ${status} this <br>${statusType}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, ${status}`,
          customClass: { confirmButton: 'btn btn-primary' },
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          preConfirm: async () => {
            try {
              const res = await MakeAxiosRequest('post', { "status": status }, `agent/${agentKey}/changestatus`);
              if (res.status === 1) {
                return true;
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.fire({
                icon: 'warning',
                title: 'Request Failed!',
                customClass: { confirmButton: 'btn btn-primary' },
                text: ` ${error.message}`,
              });
              return false; 
            }
          },
        });
    
        if (result.isConfirmed && result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({
            icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },   text: type + ' ' + status + 'd successfully.' 
          });
          agentData();
        }
      } catch (error) {
        Swal.fire({ icon: 'error',  title: 'Error',  customClass: { confirmButton: 'btn btn-primary' },
          text: 'An error occurred while changing the status.',
        });
      }
    };
    

const submitTransferData = async () => {
  const selectedValue = document.getElementById("agentList").value;
  try {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to transfer this account?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {  confirmButton: 'btn btn-primary' },
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      const res = await MakeAxiosRequest(
        'post', {"agent_user_uuid":selectedValue}, `agent/${agentKey}/transferaccount`
      );     
      if (res.status===1) {
        Swal.fire({ icon: 'success', title: 'Success!',  customClass: { confirmButton: 'btn btn-primary' },
          text: 'Transfer successfully.'
        });
        $('#tranfer-account').modal('hide');
         agentData();
         changeTabList(tabActive, listParams);        
      } else {       
        Swal.fire({ icon: 'error',  title: 'Error',  customClass: { confirmButton: 'btn btn-primary' },
          text: 'Failed to transfer the account.',
        });
      }     
    } 
  } catch (error) {
    Swal.fire({  icon: 'error',  title: 'Error',   customClass: { confirmButton: 'btn btn-primary'  },  
    text: 'An error occurred while transfer the account.', }); 
  
  }
}

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <center><img className="loader" src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> ;
  } else { 

  return (
    <>
      <div className="page">
      {/* Content Row */}
        <div className="row align-items-center">
          <div className="col-sm-10 col-lg-7">
          <div className="d-flex align-items-center mb-3">
            <a href='javascript:void(0)' onClick={() => navigate(-1)} className="back-btn">
              <i class="fa-solid fa-circle-arrow-left me-1 me-sm-3"></i>
            </a>
            <Breadcrumbs />
            </div>
         </div>
        </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-xl-8 col-xxl-3 mx-auto mb-3">
                      <div className="card cmpny-dtls">
                        <div className="cmpny-cover position-relative mb-4">
                           <img className="cmpny-cover-img" src={dummyBanner} alt="" />
                        
                          <div className="company-prfl">
                      
                          <img className="" src={agentDetails.profile_img || dummyProfile} />

                          </div>
                        </div>
                        <div className="card-body mt-5">
                          <div class="text-center">
                            <h1 className="mb-0">{agentDetails.first_name} {agentDetails.last_name}</h1>                         
                            <p className="mb-2">{agentDetails.email}</p>
                            {agentDetails.created_at && (
                                <p class="join-dt"> <span>Joined on:</span> {agentDetails.created_at} </p>
                             
                            )}
                            <span class="status-tag my-2 px-3">{agentDetails.user_role}</span>
                          </div>
                         
                          <div class="btn-group d-flex justify-content-center align-items-center">
                            <a href="javascript:void(0)" class="btn btn-primary m-1" onClick={() => handleshowEdit(agentDetails)}>
                              <i class="fa-solid fa-pen me-2"></i> Edit </a>
                              {statusOptions.map(option => {
                                 const  statusOpt=  option.label.charAt(0).toUpperCase() + option.label.slice(1)
                                return agentDetails.status === option.value && (                               
                                  <a href="javascript:void(0)"  className={option.className}  onClick={() => changeStatus(agentDetails.agent_user_uuid,option.label,agentDetails.user_role)} >
                                 {option.icon} {statusOpt}</a>
                                );
                              })}
                             
                          </div>
                          {agentDetails.show_trasfer_button=='1'  && (
                          <a href="javascript:void(0)" onClick={() => showTransferAct()}  class="btn btn-outline-primary mt-2"  data-bs-target="#tranfer-account"><i class="fa-solid fa-people-arrows me-2"></i>Transfer Account</a>
                        
                           )}
                          <div class="cmpny-dtls-txt mt-4">
                          {agentDetails.super_agent && (
                                <>
                                  <div className="d-flex align-items-center flex-wrap mb-3">
                                    <img className="thumb-img" src={agentDetails.superagent_img} alt="" />
                                    <div className=''>
                                        <a href="javascript:void(0)" onClick={()=>navigate(`/agent/${agentDetails.super_agent_key}`)}>
                                          <span className="agent-type d-block mb-0">Super Agent</span>
                                          <p className="mb-0">{agentDetails.super_agent}</p>
                                        </a>
                                    
                                    </div>
                                 
                                  </div>  
                                  
                                                            
                            </>
                            )}    
                             {agentDetails.phone_number && (
                                <>
                                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                                    <div>
                                      <span className="d-block mb-2">Phone Number</span>
                                      <p className="mb-0">+91 {agentDetails.phone_number}</p>
                                    </div>
                                    <a className="btn whtsapp-btn" target='_blank' href={`https://wa.me/${agentDetails.phone_number}`} >
                                      <i className="fa-brands fa-whatsapp me-2"></i>Message </a>
                                  </div>                            
                            </>
                            )}                    
                            {agentDetails.address && (
                                <>
                                  <span className="d-block mb-2">Address</span>
                                  <p className='' dangerouslySetInnerHTML={renderTextWithLineBreaks(agentDetails.address)}/>
                               </>
                            )}                           
                           {agentDetails.pincode && (
                                <>
                                  <span className="d-block mb-2">Pin Code</span>
                                  <p>{agentDetails.pincode}</p>
                               </>
                            )}                        
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-12 col-xxl-9 mx-auto mb-3">
                      <div className="cmpny-dtls-tabs">


  
                      <Tabs
                      activeTab={activeTab}
                      onTabClick={onTabClick}                    
                      rightBtnIcon={<i class="fa-solid fa-angle-right"></i>}
                      leftBtnIcon={<i class="fa-solid fa-angle-left"></i>}
                      navBtnCLickAnimationDuration={1000}
                      tabsScrollAmount={3}
                      hideNavBtnsOnMobile	={false}
                      animationDuration={1000}
                      tabsContainerClassName="scrtabs-tabs-fixed-container"
                      tabsUpperContainerClassName="scrtabs-tab-container"
                      navBtnAs="div"
                      navBtnClassName="navBtnClassName"
                      navBtnContainerClassName="navBtnContainerClassName">
                  
                        {tabList.map((option, optionIndex) => (
                          <Tab key={optionIndex} className="tabbbb" tabAs="div">
                            <button className={`nav-link ${option.value}-tab`} id={`#${option.value}-tab`}  data-bs-toggle="tab" data-bs-target={`#${option.value}-tab-pane`} type="button" role="tab"  aria-controls={`${option.value}-tab-pane`}  aria-selected="false"> {option.option}</button>
                            </Tab>
                        
                        ))}
                    
                      </Tabs>
                        




                                {/* Tab section */}
                                <div className="tab-content" id="myTabContent">
                                {tabList.map((option, optionIndex) => (
                                <TabScreen key={option.option}
                                  activeTab={activeTab}
                                  index={optionIndex}  className="some-animation-class"  >
                                    {transactionData && option.option === "Transactions" && (
                                       <Transactions transactionData={transactionData} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange} role={agentDetails.user_role}/>
                                    )}

                                    {reportsData && option.option === "Reported Issues" && (
                                       <Reports reportsData={reportsData} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange}/>
                                    )}

                                    {KycData && option.option === "KYC" && (
                                       <KycList KycData={KycData} agentKey={agentKey} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange}/>
                                    )}

                                    {businessData && option.option === "Businesses" && (
                                      <Business businessData={businessData} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange}/>
                                    )}

                                    {overviewData && option.option === "Overview" && (
                                     <Overview overviewData={overviewData} changeTabList={changeTabList}  />
                                    )}

                                    {agentlistData && option.option === "Agents" && (
                                      <Agentlist agentlistData={agentlistData} superAgentId={agentDetails.id} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange} agentDetailsdata={agentData} />
                                    )}

                                    {payoutData && option.option === "Payouts" && (
                                      <Payout payoutData={payoutData} agentKey={agentKey} handlePageChange={handlePageChange} handleSelectChange={handleSelectChange} role={agentDetails.user_role}/>
                                    )}
                                  </TabScreen>
                                   ))}
                                  </div>

                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {showEdit &&<CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, agentDetails,agentData }} />}

                {/* transferacount */}
              <div class="modal fade" id="tranfer-account" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="tranfer-account" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 mt-4">
                            <div class="text-center">
                              <h5>Transfer Account</h5>
                              <p class="gray">Select a {agentDetails.user_role_id ==6 && 'super '}agent to which you want to transfer the data from this user</p>
                            </div>
                            
                        </div>
                      </div>
                      <form class="row mt-4">
                        <div class="col-12">
                          <label for="inputEmail4" class="form-label">{agentDetails.user_role_id ==6 && 'Super '}Agent List</label>
                          {!isLoaded ? (
                            <div><i class="fa-solid fa-spinner"></i></div>
                          ) : (
                          
                              <select className="form-select" aria-label="Default select example" id="agentList">
                                {agentTranferData.map((option, index) => (
                                  <option key={index} value={option.agent_user_uuid}>
                                    {option.first_name} {option.last_name}
                                  </option>
                                ))}
                              </select>
                           
                          )}
                        </div>
                      </form>

                    </div>
                    
                    <div class="modal-footer b-0">
                      <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-primary" onClick={() => submitTransferData()} >Transfer Account</button>
                    </div>
                  </div>
                </div>
              </div>

            
            
    </>
  )
}
}

export default AgentDetails