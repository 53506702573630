import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function SelectField({ name, label, rules, options, className }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{label}</label>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <select {...field} className="form-select">
            <option value="" disabled>Select {label}</option>
            {options?.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        )}
      />

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default SelectField;
