import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoDataFound from "../../Components/Listing/NoDataFound";
import { useParams } from "react-router";
import { MakeAxiosRequest } from "../../utils/handler";
import Swal from "sweetalert2";
import AddGallery from "./AddGallery";
import ListPagination from "../../Components/Listing/ListPagination";

import loaderFile from "../../Assets/Images/loader.gif";

function Gallery({ listData, handlePageChange, handleSelectChange }) {
  const params = useParams();
  const businessKey = params.key;
  const [show, setShow] = useState(false);
  const totalCount = listData?.totalcount;
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    type: "sortBy",
    value: "newest",
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const removeGallery = async (key,type) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to remove this media ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `/business/${businessKey}/galleryimage/${key}/destroy`
        );

        if (res.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: { confirmButton: "btn btn-primary" },
            text: res.message,
          });
          handleFilterChange(filterData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: { confirmButton: "btn btn-primary" },
            text: res.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: { confirmButton: "btn btn-primary" },
        text: "An error occurred",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [totalCount]);

  const fetchData = (type = "") => {
    setIsLoaded(totalCount !== undefined);
    if (type === 1) {
      handleFilterChange(filterData);
    }
  };

  const handleFilterChange = (type, value) => {
    handleSelectChange("gallery", type, value, currentPage);
    setFilterData({ type, value });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("gallery", newOffset, type, value);
    setCurrentPage(newOffset);
  };

  const [modalSliderIndex, setModalSliderIndex] = useState(0);
  const [modalSliderOpen, setModalSliderOpen] = useState(false);

  const openModalSlider = async (index) => {
    setModalSliderIndex(index);
    setModalSliderOpen(true);
  };

  const closeModalSlider = () => {
    setModalSliderOpen(false);
  };

  const modalSliderSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: listData?.businessAlbum?.length > 1,
    speed: 500,
    initialSlide: modalSliderIndex, // Set the initial slide index
    prevArrow: <div className="slick-prev">Previous</div>,
    nextArrow: <div className="slick-next">Next</div>,
    afterChange: (currentSlide) => {
      document.querySelectorAll('video').forEach((videoElement) => {
        videoElement.pause();
        videoElement.currentTime = 0;
      });
    },
  };
  
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlayPauseVideo = (index) => {
    if (playingIndex === index) {
      const video = document.getElementById(`video-${index}`);
      if (video) {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
          setPlayingIndex(null);
        }
      }
    } else {
      // Pause all other videos
      document.querySelectorAll('video').forEach((videoElement) => {
        if (!videoElement.paused) {
          videoElement.pause();
        }
      });

      setPlayingIndex(index);
      const video = document.getElementById(`video-${index}`);
      if (video) {
        video.play();
      }
    }
  };

  return (
    <div
      className="tab-pane fade show active"
      id="gallery-tab-pane"
      role="tabpanel"
      aria-labelledby="gallery-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-7 mb-3 text-center text-lg-start">
          <h4 className="mb-0">Gallery</h4>
          <p>Business Medias added by Aladinz will be displayed here. </p>
        </div>
        <div className="col-lg-5 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            <button
              type="button"
              className="btn btn-primary my-2"
              onClick={handleShow}
            >
              Add Media
            </button>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-12">
          <div className="tab-service-sec gallery-sec">
            {!isLoaded ? (
              <div>
                <center>
                  <img
                    src={loaderFile}
                    style={{ width: "125px" }}
                    alt="Loading..."
                  />
                </center>
              </div>
            ) : (
              <div className="row">
                {listData?.businessAlbum?.length > 0 ? (
                  <>
                    {listData?.businessAlbum?.map((row, index) => (
                      <div className="col-sm-6 col-xl-4 mb-3" key={index}>
                        <div className="services-imgs popup-img">
                          {row.filetype == "image" && (
                            <img
                              src={row.image}
                              alt={row.original_name}
                              onClick={() => openModalSlider(index)}
                              className="img-fluid"
                            />
                          )}
                          {row.filetype == "video" && (
                            <>
                              <video
                                className=""
                                src={row.image}
                                preload="metadata"
                                onClick={() => openModalSlider(index)}
                                // onEnded={() => setPlayingIndex(null)} // Set playingIndex to null when video ends
                              >
                                Your browser does not support the video tag.
                              </video>
                              {/* <span className="play-icon" onClick={() => handlePlayPauseVideo(index)}>
                              <i className={playingIndex === index ? "fas fa-pause-circle" : "fas fa-play-circle"}></i>
              </span> */}
                              <span className="play-icon" onClick={() => openModalSlider(index)}><i class="fas fa-play-circle"></i></span>
                            </>
                          )}
                          {/* <img src={row.image}      alt={row.original_name} onClick={() => openModalSlider(index)}
                            className="img-fluid" /> */}
                          {!isLoaded && (
                            <img src={loaderFile} alt="Loading..." />
                          )}
                          <a
                            href="javascript:void(0)"
                            onClick={() => removeGallery(row.album_uuid,row.filetype)}
                            className="close-btn"
                          >
                            <i className="fa-regular fa-circle-xmark"></i>
                          </a>
                        </div>
                      </div>
                    ))}

                    <ListPagination
                      pageChange={handlePageClick}
                      totalCount={listData.totalcount}
                      perpage={listData.perpage}
                    />

                    {modalSliderOpen && (
                      <div
                        className="modal fade show gallery-modal"
                        id="imageModalSlider"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="imageModalSliderLabel"
                        aria-hidden="true"
                        style={{ display: modalSliderOpen ? "block" : "none" }}
                      >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                          <div className="modal-content pt-2">
                            <div className="modal-body">
                              <Slider {...modalSliderSettings}>
                                {listData?.businessAlbum?.map((row, index) => (
                                  <div key={index}>
                                    {/* {index === modalSliderIndex && ( */}

                                    {row.filetype == "image" && (
                                      <img
                                        src={row.image}
                                        alt={row.original_name}
                                        className="img-fluid"
                                        id={modalSliderIndex}
                                      />
                                    )}
                                    {row.filetype == "video" && (
                                                          // <ReactPlayer controls url={row.image} />

                                      <video
                                        id={`video-${index}`} 
                                        className=""
                                        src={row.image}
                                        controls
                                        preload="metadata"
                                        autoPlay // Add autoPlay attribute for autoplay
                                      >
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    )}
                                    {/* )} */}
                                  </div>
                                ))}
                              </Slider>
                            </div>
                            <a
                              href="javascript:void(0)"
                              className="modal-close"
                            >
                              <i
                                class="fa-solid fa-xmark"
                                onClick={closeModalSlider}
                              ></i>
                            </a>
                            {/* <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" onClick={closeModalSlider}>Close</button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <NoDataFound text="medias" />
                )}
              </div>
            )}
            <AddGallery {...{ show, handleClose, businessKey, fetchData }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
