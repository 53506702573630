import React, { useEffect, useState } from "react";
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from "date-fns";
import NoDataFound from "../../Components/Listing/NoDataFound";
import { Link } from "react-router-dom";

function Followers({ followersData,handlePageChange,handleSelectChange }) {

  const columns = [
    {
      name: (
        <div className="datatable-hd">
          <h6>Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <Link to={row.status !== '2' ? `/user/${row.user_uuid}` :  "javascript:void(0)" }>
            <div className="profile-sec">
              <img src={row.image} alt="" />
              <div>
                <p>{row.first_name} {row.last_name}</p>
              </div>
            </div>
          </Link>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Email</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "200px" }}
        >
          <div>
            <p>{row.email} </p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Phone</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "200px" }}
        >
          <div>
            <p>{row.phone_number} </p>
          </div>
        </div>
      ),
    },

    {
      name: (
        <div className="datatable-hd">
          <h6>Followed on</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), "dd/MM/y h:ii a")}</p>
        </div>
      ),
    },
  ];

  
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });

  const totalCount = followersData?.totalcount;
  const [isTableLoaded, setIsLoaded] = useState(true);

   useEffect(() => {    
    fetchData();
  }, [totalCount]);

  const fetchData =() =>{   
   setIsLoaded(totalCount !== undefined); 
  
  };

  const handleFilterChange = (type, value) => {
    handleSelectChange('followers',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('followers',newOffset,type,value);   
    setCurrentPage(newOffset);
  };



  return (
    <div
      className="tab-pane fade show active"
      id="followers-tab-pane"
      role="tabpanel"
      aria-labelledby="followers-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-8 mb-3 text-center text-lg-start">
          <h4 className="mb-0">Followers</h4>
          <p class="mb-0">List of all the users following this business is displayed here. </p>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
          {followersData?.filters?.map((filter, index) => (
                  <div className="sort-sec my-2 me-2" key={index}>
                    <p className="me-1">{filter.title} : </p>
                    <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                      {filter.options?.map( option => 
                        <option value={option.value} >{option.option}</option>
                      )}                        
                    </select>
                  </div>
                ))}
          
          </div>
        </div>
      </div>
      <div className="">
          <DataTableList  pageChange={onPageChange}   totalCount={followersData.totalCount}  columns={columns}   tableData={followersData.businessfollowers}  type ={'followers'} isTableLoaded={isTableLoaded} />           
        </div> 
    
     
            {/* <table className="table table-hover table-condensed">
                                    <thead className="">
                                      <tr>
                                        <th className="numeric">Name</th>
                                        <th className="numeric">Email</th>
                                        <th className="numeric">Phone</th>
                                        <th className="numeric">Followed on</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        
                                        <td data-title="Name" className="align-middle">
                                          <div className="profile-sec">
                                            <img src="img/dummy-profile.jpg" alt=""/>
                                            <p>Jain Thomas</p>
                                          </div>
                                        </td>
                                        <td data-title="Email/Phone Number" className="numeric align-middle">
                                          <p>jain@gmail.com</p>
                                        </td>
                                        <td data-title="Created By" className="numeric align-middle">
                                          <p>+91 78786 89346</p>
                                        </td>
                                        <td data-title="Updated By" className="numeric align-middle">
                                          <p>04/02/2023</p>
                                        </td>
                                        
                                      </tr>
                                    </tbody>
                                  </table> */}
         
       
    </div>
  );
}

export default Followers;
