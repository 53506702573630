import React from "react";
import { useFormContext, Controller } from "react-hook-form";

function UrlField({ name, label, rules, className, icon }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={className}>
      <div className="col-md-4 mb-3">
        <div className="d-flex align-items-center">
          <img src={icon} />
          <p className="ms-3 mb-0">{label}</p>
        </div>
      </div>
      <div className="col-md-8 mb-3">
        <div className="input-group icon-form-lft">
          <span className="input-group-text" id="basic-addon1">
            <i className="fa-solid fa-link"></i>
          </span>

          <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={rules}
            render={({ field }) => (
              <input {...field} type="text" className="form-control" />
            )}
          />
        </div>
        {errors[name] && <span className='error'>{errors[name].message}</span>}
      </div>
    </div>
  );
}

export default UrlField;
