import React, { useEffect, useState } from "react";
import { MakeAxiosRequest } from "../../utils/handler";
import { format } from "date-fns";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Import 'chart.js/auto' instead of 'chart.js'
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter
import { Link } from "react-router-dom";
import loaderFile from "../../Assets/Images/loader.gif";
import NoDataFound from "../../Components/Listing/NoDataFound";

function Dashboard() {

    
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // Months are zero-indexed


  const [isLoaded, setIsLoaded] = useState(false);
  const [resData, setResData] = useState([]);
  const [graphOption, setGraphOption] = useState({
    type:'monthlydata',
    month:currentMonth.toString().padStart(2, '0'),
    year:(currentYear-1).toString()
  });
  const [graphArr, setGraphArr] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const fetchData = async () => {
    const res = await MakeAxiosRequest("post", {}, "/dashboard");
    // console.log(res);
    if (res.status == 1) {
      setResData(res.data);
      setIsLoaded(true);
      setGraphArr(res.data.graphArr)
      setGraphData(res.data.graphArr.monthlydata)
    } else {
      console.log(res.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const generateMonths = () => {
  //   const options = [];
  //   for (let i = 1; i <= 12; i++) {
  //     const monthValue = String(i).padStart(2, '0');
  //     const monthLabel = new Date(2000, i - 1, 1).toLocaleString('default', { month: 'long' });
  //     options.push({ value: monthValue, label: monthLabel });
  //   }
  //   return options;
  // };

  const generateYears = () => {
    const options = [];

    for (let year = startYear; year <= currentYear; year++) {
      options.push({ value: year, label: year });
    }
    return options;
  };

 const graphDataChange = async (field,val)=>{

  setGraphOption(prev => ({ ...prev,  [field]: val }));
  if(field==='type'){
    if(val==='yearlydata'){
      const res = await MakeAxiosRequest("post", {year:graphOption.year}, "/dashboard/graph/year");
      // console.log(res);
      if (res.status == 1) {
        setGraphData(res.data.graphArr)
      } else {
        console.log(res.message);
      }
    }else{
      setGraphData(graphArr[val])
    }
  }else if(field==='month'){
    const res = await MakeAxiosRequest("post", {month:val,year:graphOption.year}, "/dashboard/graph/month");
    // console.log(res);
    if (res.status == 1) {
      setGraphData(res.data.graphArr)
    } else {
      console.log(res.message);
    }
  }else if(field==='year'){
    const res = await MakeAxiosRequest("post", {year:val}, "/dashboard/graph/year");
    // console.log(res);
    if (res.status == 1) {
      setGraphData(res.data.graphArr)
    } else {
      console.log(res.message);
    }
  }

  

 }
 
  const label = graphData.map(item => item.label);
  const counts = graphData.map(item => item.count);


  const data = {
    labels: label,
    datasets: [
      {
        label: 'Profile Views ',
        data: counts,
        fill: true,
        backgroundColor: '#df7171', // Light red fill color with opacity
        borderColor: '#f22727', // Red border color
        borderWidth: 2,
        pointBackgroundColor: '#fff', // Purple points
        pointRadius: 4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category', 
        labels: label,
      },
      y: {
        beginAtZero: true,
      },
    },
  };


  return (
    <>
      <div className="page">
        {/* Content Row */}
        {!isLoaded ? (
          <div>
            <center>
              <img
                src={loaderFile}
                style={{ width: "150px" }}
                alt="Loading..."
              />
            </center>
          </div>
        ) : (
          <>
        <div className="row">

          <div className="col-12 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="card-body dash-counts">
                <p>Users</p>
                <h5>{resData.userdeetails?.totalcount || 0}</h5>
                <span>
                  {resData.userdeetails?.thismonth || 0} New users this month
                </span>
                <Link to="/users">
                  View all users
                  <i className="fa-solid fa-arrow-right-long ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="card-body dash-counts">
                <p>Agents</p>
                <h5>{resData.agentuserdeetails?.totalcount || 0}</h5>
                <span>
                  {resData.agentuserdeetails?.thismonth || 0} New agents this
                  month
                </span>
                <Link to="/agents">
                  View all agents
                  <i className="fa-solid fa-arrow-right-long ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="card-body dash-counts">
                <p>Businesses</p>
                <h5>{resData.businessdetails?.totalcount || 0}</h5>
                <span>
                  {resData.businessdetails?.thismonth || 0} New businesses this
                  month
                </span>
                <Link to="/businesses">
                  View all businesses
                  <i className="fa-solid fa-arrow-right-long ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 mb-3">
            <div className="card h-100">
              <div className="card-body rcnt-subscrbs">
                <div className="d-flex align-items-center flex-wrap mb-4">
                  <h3 className="mb-0">Recent Businesses</h3>
                </div>
                {resData.businessdetails?.businesses.length == 0 ? (
                  <>
                    {/* <p className="no-records">No business added</p> */}
                    <NoDataFound text="businesses"/>
                  </>
                ) : (
                  <>
                    {resData.businessdetails?.businesses.map(
                      (business, index) => (
                        <div className="subcribe-list" key={index}>
                            <Link to={`/business/${business.business_uuid}`} >
                          <div className="subcriber-sec">
                        
                            <img src={business.image_path} alt="" />
                            <div className="subcriber-nme">
                              <p>{business.title}</p>
                              <span>Joined on {format(new Date(business.created_at), 'dd/MM/y')}</span>
                            </div>
                           
                          </div>
                          </Link>
                          <div className="subcriber-optn"></div>
                        </div>
                      )
                    )}
                <Link to="/businesses" className="mt-4 d-block">
                  View all businesses
                  <i className="fa-solid fa-arrow-right-long ms-2"></i>
                </Link>

                  </>
                )}

                
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-8 mb-3">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="card">
                  <div className="card-body rcnt-subscrbs">
                    <div className="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap mb-4">
                      <h3 className="my-2 me-3">Businesses Profile Views</h3>
                      {/* {graphOption.type === 'monthlydata' && 
                      <div className="sort-sec">
                        <select className="form-select"
                          aria-label="Default select example"
                          value={graphOption.month} 
                          onChange={(e)=>graphDataChange('month',e.target.value)} 
                        >
                            {generateMonths().map(month => (
                              <option key={month.value} value={month.value}>{graphOption.year} {month.label}</option>
                            ))}
                        </select>
                    </div>} */}
                    <div className="d-flex align-items-center">
                    {graphOption.type === 'yearlydata' && 

                    <div className="sort-sec mx-1">
                        <select className="form-select"
                          aria-label="Default select example"
                          value={graphOption.year} 
                          onChange={(e)=>graphDataChange('year',e.target.value)} 
                        >
                            {generateYears().map(year => (
                              <option key={year.value} value={year.value}>{year.label}</option>
                            ))}
                        </select>
                    </div>}
                    <div className="sort-sec mx-1">
                        <select className="form-select"
                          aria-label="Default select example"
                          value={graphOption.type} 
                          onChange={(e)=>graphDataChange('type',e.target.value)} >
                          <option value="weeklydata">Last 7 days</option>
                          <option value="monthlydata">Last month</option>
                          <option value="yearlydata">Yearly</option>
                        </select>
                      </div>
                      </div>

                    </div>
                    <div>
                      <div id="area-chart">
                      <Line
                          data={data}
                          height={370} 
                          options={{
                            ...options,
                            plugins: {
                              title: {
                                display: true,
                                text: "Note: This graph represents the total number of businesses profile views.",
                                position: 'bottom'
                              },
                              legend: {
                                display: true,
                              },
                            },
                            maintainAspectRatio: false, // Disable aspect ratio for responsiveness
                            scales: {
                              y: {
                                suggestedMin: 0, // Minimum value for the y-axis
                                beginAtZero: true, // Start the y-axis at zero
                                ticks: {
                                  stepSize: 1
                                  // precision: 0
                                }
                              }
                            }
                          }}
                        />
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-xl-4 mb-3">
                <div className="card h-100">
                  <div className="card-body dash-counts sbscrb-counts">
                    <p>Last Month</p>
                    <h5>921</h5>
                    <span>New Businesses</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 mb-3">
                <div className="card h-100">
                  <div className="card-body dash-counts sbscrb-counts">
                    <p>Last Year</p>
                    <h5>94.1k</h5>
                    <span>New Businesses</span>
                  </div>
                </div>
              </div> */}
              {resData.businessdetails?.most_viewed_business && 
              <div className="col-xl-6 mb-3">
                <div className="card h-100">
                  <div className="card-body dash-counts sbscrb-counts">
                    <p>Most Viewed Profile</p>
                    <Link to={resData.businessdetails.most_viewed_business_key !== '' ? `/business/${resData.businessdetails.most_viewed_business_key}` :  "javascript:void(0)"}>
                    <div className="subcriber-sec">
                   
                      <img src={resData.businessdetails?.most_viewed_business_image} alt="" />
                      <div className="subcriber-nme">
                        <p>{resData.businessdetails?.most_viewed_business}</p>
                        <span>{resData.businessdetails?.total_profile_views || 0} Views</span>
                      </div>
                     
                    </div>
                    </Link>
                  </div>
                </div>
              </div>}
              {resData.businessdetails?.top_followed_business && 
              <div className="col-xl-6 mb-3">
                <div className="card h-100">
                  <div className="card-body dash-counts sbscrb-counts">
                    <p>Top Followed Business</p>
                    <Link to={resData.businessdetails.top_followed_business_key !== '' ? `/business/${resData.businessdetails.top_followed_business_key}` :  "javascript:void(0)"}>
                    <div className="subcriber-sec">
                      <img src={resData.businessdetails?.top_followed_business_image} alt="" />
                      <div className="subcriber-nme">
                        <p>{resData.businessdetails?.top_followed_business}</p>
                        <span>{resData.businessdetails?.total_followers || 0} Followers</span>
                      </div>
                    </div>
                    </Link>

                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>
        </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
