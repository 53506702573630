import React from 'react'
import ReactPaginate from "react-paginate";

function ListPagination(props) {
  const perPage=props.perpage || 10;
  return (
    <>
    {props.totalCount  > perPage &&
      <div className='pagination'>
        <ReactPaginate
           breakLabel={<span className="break-label">...</span>}
          nextLabel={<i class="fa-solid fa-angle-right"></i>}
          onPageChange={props.pageChange}
           pageRangeDisplayed={5}
          pageCount={Math.ceil(props.totalCount / perPage)}
          disableInitialCallback={true}
          previousLabel={<i class="fa-solid fa-angle-left"></i>}
          renderOnZeroPageCount={null}
        />
      </div>
    }
    </>
  )
}

export default ListPagination