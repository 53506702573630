
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { flatMap, isEmpty } from 'lodash';
import { MakeAxiosRequest } from '../../utils/handler';
import categorydumy from "../../Assets/Images/categorydumy.png"
import subcategorydumy from "../../Assets/Images/subcategorydumy.png"
import CreateEditModal from './CreateEditModal';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import noRecords from '../../Assets/Images/no-records.png'
import loaderFile   from '../../Assets/Images/loader.gif';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import ListPagination from "../../Components/Listing/ListPagination";
import CategoryList from "../../Components/Listing/Category";

const CategoryListing = ({ searchValue,setsearchValue }) => {

  const navigate = useNavigate();
  const params = useParams();
  const categoryKey = params.key;
  const sortby = params.sortby || "newest";
  const status = params.status || "all";

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [maincategory, setMainCategory] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const handleCloseEdit = () => setshowEdit(false);
  const [categoryDetails, setcategoryDetails] = useState({});
  const { setBreadcrumbsData } = useBreadcrumb();
  const [data, setData] = useState({});
  const [isFilterLoaded, setIsFilterLoaded] = useState(true);

  const [listParams, setListParams] = useState({
    offset: "",
    status: status,
    sortBy: sortby,
  });
  const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
    setcategoryDetails({})
  };

  const value = categoryKey ? "Sub Category" : "Category";
  const msg =  categoryKey ? "Subcategory" : "Category";
  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "InActive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];
  const handleSelectChange = (filterName, value) => {
    setsearchValue('');
    setIsFilterLoaded(false);
    if(filterName==='sortBy')
      navigate(categoryKey ? '/categories/'+categoryKey+'/subcategory/'+value+'/'+listParams.status : '/categories/'+value+'/'+listParams.status);
    if(filterName==='status')
      navigate(categoryKey ? '/categories/'+categoryKey+'/subcategory/'+listParams.sortBy+'/'+value : '/categories/'+listParams.sortBy+'/'+value);
    setListParams(prevParams => ({ ...prevParams, [filterName]: value, offset:'0' }));
  };
 // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData(categoryKey
        ? [
            { label: "Categories", path: "/categories" },
            {label: "Subcategories"},
            { label: "Listing" },
          ]
        : [
            { label: "Categories" },
            { label: "Listing" },
          ]
      );
      
    };  
    useEffect(() => {
      updateBreadcrumbs();
      setListParams({offset: "",status: "newest",sortBy: "all",});
    }, [categoryKey]); 

  useEffect(() => {
    setIsFilterLoaded(false);
    fetchData();
  }, [perPage, listParams, searchValue, categoryKey])



  const fetchData = async () => {

      isFilterLoaded || setIsLoaded(false);
    
    try {
      const url = (categoryKey) ? `categories/${categoryKey}/subcategories` : "/categories";
      const res = await MakeAxiosRequest("post", { ...listParams, searchValue }, url);
      setIsLoaded(true);
      (categoryKey) ? setItems(res.data.subcategories) : setItems(res.data.categories);
      (categoryKey) ? setMainCategory(res.data.categoryname) : setMainCategory('');
      setFilters(res.data.filters);
      setData(res.data);
      setIsFilterLoaded(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageClick = (event) => {
    setListParams(prevParams => ({ ...prevParams,'offset': event.selected, }));

  };

   
    // Status change of categories 
    const changeStatus = async (categoryKey, status) => {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          html: 'Are you sure you want to ' + status + ' this '+value+'?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, ${status}`,
          customClass: { confirmButton: 'btn btn-primary' },
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          preConfirm: async () => {
            try {
              const res = await MakeAxiosRequest(
                'post', { "status": status }, `category/${categoryKey}/changestatus`
              );
              if (res.status === 1) {
                return true;
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.fire({
                icon: 'warning',
                title: 'Request Failed!',
                customClass: { confirmButton: 'btn btn-primary' },
                text: ` ${error.message}`,
              });
              return false; 
            }
          },
        });
    
        if (result.isConfirmed && result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({
            icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },  text:  msg + ' ' + status + 'd successfully.'
          });
          fetchData();
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          customClass: { confirmButton: 'btn btn-primary' },
          text: 'An error occurred while changing the status.',
        });
      }
    };


  const handleshowEdit = (category) => {
    setisEdit(true)
    setshowEdit(true)
    setcategoryDetails(category)
  };


  
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (
      <>
        <div className="row">
          <div className="col-12">

            <div className="row d-flex align-items-center flex-wrap ">
              <div className="col-12 col-xl-3 text-center text-xl-start">
                <h3>{maincategory ? maincategory : ''}</h3>
              </div>
              <div className={maincategory ? "col-12 col-xl-9" : "col-12 col-lg-12"}>
                <div className={"d-flex align-content-center justify-content-center  flex-wrap "+(maincategory ? "justify-content-xl-end" : "justify-content-lg-end")}>

                  {filters?.map((filter, index) => (
                    <div className="sort-sec my-2 me-2" key={index}>
                      <p className="me-1">{filter.title} : </p>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                        {filter.options?.map(option =>
                          <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                        )}
                      </select>
                    </div>
                  ))}

                  <Button className="btn btn-primary my-2" onClick={handleShow}> Create </Button>

                </div>

              </div>
            </div>

            {/* <CategoryList  isFilterLoaded={isFilterLoaded}   /> */}



            {!isFilterLoaded ? (<div> <center><img src={loaderFile} style={{ width: '150px' }} alt="Loading..." /></center> </div>):(
            <div className="row mt-4">
              {items.length > 0 ?
                items.map((category, optionIndex) => (
                  <div className="col-12 col-md-6 col-md-6 col-xl-3 mb-3" key={optionIndex}>
                    {/**/}
                    <div className="category-card">
                      {categoryKey ? <div className="card position-relative">
                          <img src={category.image_path || subcategorydumy} />
                          {isEmpty(category.deleted_at) ? (<span class="offer-tag approved-tag position-absolute mt-3 ms-3">Active</span>) : (<span class="offer-tag deactive-tag position-absolute mt-3 ms-3">Inactive</span>)}
                        </div> :
                        <Link to={`/categories/${category.category_uuid}/subcategory`}>
                        <div className="card position-relative">
                          <img src={category.image_path || categorydumy} />
                          {isEmpty(category.deleted_at) ? (<span class="offer-tag approved-tag position-absolute mt-3 ms-3">Active</span>): (<span class="offer-tag deactive-tag position-absolute mt-3 ms-3">Inactive</span>)}
                          
                        </div>
                      </Link>
                      }
                      
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          {categoryKey ? <div>
                            <h5 className="mb-0">{category.title}</h5>
                          </div> :
                          <Link to={`/categories/${category.category_uuid}/subcategory`}>
                          <div>
                            <h5 className="mb-0">{category.title}</h5>
                            <span className="small gray">{category.subcategorycount} {'Subcategories'} </span>
                          </div>
                          </Link>

                          }
                        
                          <div className="btn-group option-btn h-100">
                            <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li><a href="javascript:void(0)" className="dropdown-item" onClick={() => handleshowEdit(category)}><i className="fa-solid fa-pen me-2"></i> Edit</a></li>
                              {isEmpty(category.deleted_at) ? (
                                <li>
                                  <a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(category.category_uuid, 'deactivate')}>
                                    <i className="fa-solid fa-trash-can me-2"></i>Deactivate
                                  </a>
                                </li>
                              ) : (
                                <li>
                                  <a href="javascript:void(0)" className="dropdown-item success" onClick={() => changeStatus(category.category_uuid, 'activate')}>
                                    <i className="fa-solid fa-square-check me-2"></i>Activate
                                  </a>

                                </li>
                              )}


                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>
                    {/*  */}
                  </div>
                )) :
                (<div className="my-4 text-center">

                  <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
                  {categoryKey ?
                  <div className='mt-3'>There are no subcategories to display.</div>
                  :
                  <div className='mt-3'>There are no categories to display.</div>
                   }
                  
                </div>)}
            </div>)}

          </div>

        </div>
        <ListPagination  perpage={12} pageChange={handlePageClick} totalCount={data.totalcount}/>
        <CreateEditModal {...{ showEdit, handleCloseEdit, isEdit, categoryDetails, fetchData, perPage }} />
      </>
    );
  }
};

export default CategoryListing;