import React, { useState, useRef, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import loaderFile from "../../Assets/Images/loader.gif";


const ImageCropper = (props) => {
  const [tempKey, setTempKey] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [cropCompleted, setCropCompleted] = useState(true);
  const [croppedImage, setCroppedImage] = useState(null);

  const tempFileUpload = async () => {
    setImageSrc(URL.createObjectURL(props.file));
    try {
      const formData = new FormData();
      formData.append("image", props.file);
      const response = await MakeFileUpload("post", formData, "/imageUpload");
      if (response.status == 1) {
        setTempKey(response.data.temp_key);
        console.log(response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    // alert('up')
    tempFileUpload();
  }, [props.file]);

  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const handleSkipCrop = async () => {
    props.onCropped(imageSrc, tempKey);
    setTempKey(null);
  };
  const handleCropClick = async () => {
    const response = await MakeAxiosRequest("post",  {tempKey,crop }, "/cropimage");
    props.onCropped(response.data.image_path, tempKey, crop);
    setTempKey(null);
  };

  const onCropComplete = () => {
    setCropCompleted(!(crop.width == 0 && crop.height == 0));
    if (imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const cropX = (crop.x / 100) * image.width;
        const cropY = (crop.y / 100) * image.height;
        const cropWidth = (crop.width / 100) * image.width;
        const cropHeight = (crop.height / 100) * image.height;

        canvas.width = cropWidth;
        canvas.height = cropHeight;
        canvas.style.backgroundColor = "transparent";

        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = "source-over";
        ctx.drawImage(
          image,
          cropX * scaleX,
          cropY * scaleY,
          cropWidth * scaleX,
          cropHeight * scaleY,
          0,
          0,
          cropWidth,
          cropHeight
        );

        // Convert canvas content to data URL
        const dataURL = canvas.toDataURL("image/jpeg");
        setCroppedImage(dataURL);
      };
    }
  };

  const onImageLoad = async () => {
    console.log(crop);
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      let cropData = {};
      const imageAspectRatio = image.naturalWidth / image.naturalHeight;

      // if (image.naturalWidth > image.naturalHeight) {
      if (props.aspectRatio == 1) {
        if (imageAspectRatio > props.aspectRatio) {
          // Landscape orientation
          const xCoordinate =
            ((image.naturalWidth - image.naturalHeight) /
              2 /
              image.naturalWidth) *
            100;
          cropData = {
            x: xCoordinate,
            y: 0,
            width: (image.naturalHeight / image.naturalWidth) * 100,
            height: 100,
          };
        } else {
          // Portrait orientation
          const yCoordinate =
            ((image.naturalHeight - image.naturalWidth) /
              2 /
              image.naturalHeight) *
            100;
          cropData = {
            x: 0,
            y: yCoordinate,
            width: 100,
            height: (image.naturalWidth / image.naturalHeight) * 100,
          };
        }
      } else {
        if (imageAspectRatio > props.aspectRatio) {
          // Landscape orientation
          const xCoordinate =
            ((image.naturalWidth - (image.naturalHeight * 16) / 9) /
              2 /
              image.naturalWidth) *
            100;
          cropData = {
            x: xCoordinate,
            y: 0,
            width: ((image.naturalHeight * 16) / 9 / image.naturalWidth) * 100,
            height: 100,
          };
        } else {
          // Portrait orientation
          const yCoordinate =
            ((image.naturalHeight - (image.naturalWidth * 9) / 16) /
              2 /
              image.naturalHeight) *
            100;
          cropData = {
            x: 0,
            y: yCoordinate,
            width: 100,
            height: ((image.naturalWidth * 9) / 16 / image.naturalHeight) * 100,
          };
        }
      }
      // Set crop state based on aspect ratio
      setCrop({
        unit: "%", // Can be 'px' or '%'
        ...cropData,
      });

      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const cropX = (cropData.x / 100) * image.width;
      const cropY = (cropData.y / 100) * image.height;
      const cropWidth = (cropData.width / 100) * image.width;
      const cropHeight = (cropData.height / 100) * image.height;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        cropX * scaleX,
        cropY * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        cropWidth,
        cropHeight
      );

      // Convert canvas content to data URL
      const dataURL = canvas.toDataURL("image/jpeg");
      setCroppedImage(dataURL);
    };
  };

  return (
    <>
      {imageSrc && (
        <>
          <div className="text-center">
            <ReactCrop
              crop={crop}
              src={imageSrc}
              onChange={(crop, percentCrop) => setCrop(percentCrop)}
              onComplete={onCropComplete}
              aspect={props.aspectRatio}
            >
              <img src={imageSrc} onLoad={onImageLoad} />
            </ReactCrop>
            <div className="skip-crop-btns d-flex justify-content-center mb-3">
              <button
                type="button"
                onClick={handleSkipCrop}
                className="me-2 btn btn-outline-primary skip-btn"
              >
                Skip Crop
              </button>
              {cropCompleted && (
                <button
                  className="btn btn-primary crop-btn"
                  type="button"
                  onClick={handleCropClick}
                >
                  Crop Image
                </button>
              )}
            </div>
          </div>
          {!tempKey && (
            <div className="modal-loader">
              <img
                className="loader"
                src={loaderFile}
                style={{ width: "120px" }}
                alt="Loading..."
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ImageCropper;
