import React, {useState, useEffect} from 'react';
import { MakeAxiosRequest } from '../../../utils/handler';
 import noRecords   from '../../../Assets/Images/no-records.png'
import { useBreadcrumb } from "../../../Components/Breadcrumbs/BreadcrumbContext";
import loaderFile   from '../../../Assets/Images/loader.gif';
import ListPagination from "../../../Components/Listing/ListPagination";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';




function Listing({searchValue}) {
  const navigate = useNavigate();
  const params = useParams();

  const sortby = params.sortby || 'newest';
  const start_date = params.startdate;
  const end_date = params.enddate;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activityLogData, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [datePickererror, setDatePickerError] = useState(null);
  const [filters, setFilters] = useState([]);
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [listingData, setlistingData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const { setBreadcrumbsData } = useBreadcrumb();
  const [listParams, setListParams] = useState({
    offset: "0",
    status: "active",
    sortBy: sortby,
    start_date:start_date,
    end_date:end_date,
  });
 
  // Breadcrumbs
  const updateBreadcrumbs = () => {
    setBreadcrumbsData([   
      { label: "Reports" },
      { label: "Activity Log" },
      { label: "Listing" },
   
    ]);
  };  
  useEffect(() => {
    updateBreadcrumbs();
  }, []); 

 

  const fetchData = async () => {    
    const res = await MakeAxiosRequest("post", {...listParams,searchValue}, "/activitylogs");    
    setIsLoaded(true);
    setisTableLoaded(true);    
    setItems(res.data.activitylogs);     
     setFilters(res.data.filters); 
    setTotalRows(res.data.totalcount);   
  }
  useEffect(() => {
    fetchData();
  }, [listParams, searchValue]);


  const handleSelectChange = (filterName,value) => {  
    setisTableLoaded(false);  
    if(filterName==='sortBy'){
      if (startDate && endDate) {
        navigate('/activitylogs/'+value+'/'+listParams.start_date+'/'+listParams.end_date);  
      }else{
        navigate('/activitylogs/'+value);  
      }
    }    
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0'}));
  };

  const handlePageClick = (event) => {
    setListParams((prevParams) => ({ ...prevParams, offset: event.selected }));
  };

  const renderTextWithLineBreaks = (data) => {
    const html = data.replace(/\n/g, '<br />');
    return {__html:html}  ;
  };
  

  const [startDate, setStartDate] = useState(start_date && new Date(start_date));
  const [endDate, setEndDate] = useState(end_date && new Date(end_date));

  const handleStartDateChange = (date) => {
    setStartDate(date);  
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);   
  };


  //Function to format the date to IST
  const formatToIST = (date) => {
    const options = { timeZone: 'Asia/Kolkata' };
    return date.toLocaleString('en-US', options);
  };

  const handleDateChange = () => {    
    let errMsg = '';
    if (startDate && endDate) {
      setisTableLoaded(false);    
      setDatePickerError(null);
  
      let formattedStartDate = formatToIST(startDate);
      let formattedEndDate = formatToIST(endDate);
  
      formattedStartDate = format(new Date(formattedStartDate), 'y-MM-dd');
      formattedEndDate = format(new Date(formattedEndDate), 'y-MM-dd');
  
      setListParams((prevParams) => ({
        ...prevParams,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        offset:'0'
      }));
      navigate('/activitylogs/'+listParams.sortBy+'/'+formattedStartDate+'/'+formattedEndDate); 

    } else {
      if(!startDate && !endDate){
        errMsg = 'start date and end date';
      }else  if (!startDate) {
        errMsg = 'start date';
      } else if (!endDate) {
        errMsg = 'end date';
      }
      setDatePickerError(
        <span className="error">Please choose the {errMsg}</span>
      );
    }
  };
  


  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center flex-wrap">
          {/* <div className="col-xl-4 mb-3">
            <div className="d-flex justify-content-center justify-content-xxl-start">
              
            </div>
          </div> */}
          <div className="col-xl-12 mt-0">
            <div className="d-flex justify-content-center justify-content-lg-end flex-wrap">
            <div className="activity-log-date me-3">
              <div className='d-flex justify-content-center flex-wrap'>
              <div class=" date-sort my-2 me-2 position-relative"> 
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                maxDate={endDate || new Date()}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="form-control"
                dateFormat="d/MM/y" 
              />
              <span className='date-icon'><FaCalendarAlt className="calendar-icon" /></span>
              
               
                  </div>
                  <div class=" date-sort my-2 me-2 position-relative"> 
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      maxDate={new Date()}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      className="form-control"
                      dateFormat="d/MM/y" 
                    /> 
                    <span className='date-icon'><FaCalendarAlt className="calendar-icon" /></span>
                  </div>
                 
                  <button type="button" class="btn btn-outline-primary mt-2"  onClick={(e) => handleDateChange()} data-bs-dismiss="modal">Go</button>
                 
            </div>
            {datePickererror}  
              </div>
              <div className=''>
              <div className="d-flex align-content-center justify-content-center justify-content-md-end flex-wrap">
                   
                 
                   {filters?.map((filter, index) => (
                     <div className="sort-sec my-2 me-2" key={index}>
                       <p className="me-1">{filter.title} : </p>
                       <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                         {filter.options?.map( option => 
                           <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                         )}                        
                       </select>
                     </div>
                   ))}
                
                        
                                      
                                   
         
 
           </div>
              </div>
            </div>
          </div>
          
          
       
         
        </div>
        {!isTableLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
      <div className="row mt-4">
      {activityLogData && Object.keys(activityLogData).length > 0 ? (
          Object.keys(activityLogData).map((date, dateIndex) => (
            <div className="col-12 mb-4" key={dateIndex}>
              <h5 className="mt-2 mb-0">{date}</h5>
              {activityLogData[date].map((activityLog, logIndex) => (
                <div className="activity-card" key={logIndex}>
                  <div className="card mt-3">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-4 col-lg-3 col-xl-2">
                          <Link to={'/user/'+activityLog.userKey}>
                          <div className="profile-sec">
                            <img src={activityLog.profileimg} alt="" />
                                <p className="mb-0 ms-2">{activityLog.username}</p>
                          </div>
                          </Link>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-10">
                          {activityLog.url ? 
                          <Link to={activityLog.url}>
                            <p className='gray my-2' dangerouslySetInnerHTML={renderTextWithLineBreaks(activityLog.msg)}/>
                            </Link>  :
                            <p className='gray my-2' dangerouslySetInnerHTML={renderTextWithLineBreaks(activityLog.msg)}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="small gray ms-2 mt-1 d-block">{activityLog.time}</span>
                </div>
              ))}
              
            </div>
            
          ))
        ) : (
          <>
            <div className='mt-4 text-center'>
              <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
              <div className='mt-3'>There are no activity logs to display.</div>
            </div>
          </>
        )}
 
         
      </div>
      
 )} 

   
<ListPagination
       pageChange={handlePageClick}
       totalCount={totalRows}
     />     
      </>
    );
  }
}

export default Listing;
