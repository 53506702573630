import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import dummyProfile from "../../Assets/Images/dummy-profile.jpg"
import dummyBanner from "../../Assets/Images/cover.png"
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm } from "react-hook-form";
import TextArea from "../../Components/Inputs/TextArea";
import SelectField from "../../Components/Inputs/SelectField";
import { logout, update } from '../../features/authSlice';
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import OTPInput, { ResendOTP } from "otp-input-react";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import loaderFile from '../../Assets/Images/loader.gif';

function Myprofile() {


    

  function nl2br(text) {
    console.log( text.split('\n').map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    )))
    return text.split('\n').map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    ));
  }

  // const params = useParams()
  // const agentKey = params.key;
  const [userDetails, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setErrors] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const authUser = useSelector((state) => state.auth.user);
  const [otpvalues, setOtpvalues] = useState({
    otp_uuid: "",
    otp: "",
  });
  const [OTP, setOTP] = useState("");
  const [otperror, setOtperror] = useState("");

  const userData = async () => {

    const res = await MakeAxiosRequest("post", {}, `/myprofile`);
    setItems(res.data.userdets);
    setIsLoaded(true);


    const username = `${userDetails.first_name} ${userDetails.last_name}`;

    dispatch(update(res.data.userdets));
    // // Save the updated user data back to localStorage
    localStorage.setItem('userName', username);
    localStorage.setItem('profileImage', userDetails.profile_img);

  }
  useEffect(() => {
    userData();
  }, []);

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const verifyOtp = async () => {
    setButtonDisabled(true);
    const res = await MakeAxiosRequest("post", otpvalues, "/deleteaccount");

    if (res.status == 1) {
      $('#otp-modal').modal('hide');
      navigate("/login");

    } else {
      setButtonDisabled(false);
      setOtperror(res.message);
    }

  };

  const verifyMobileOtp = async () => {
    // console.log('test')
    const res = await MakeAxiosRequest("post", otpvalues, "/updatephonenumber");
    // console.log(res);
    if (res.status == 1) {
      $('#phoneotp-modal').modal('hide');
      userData();
      Swal.fire({
        icon: "success",
        title: "Success!",
        confirmButtonColor: "#F87416",
        text: res.message,
      });
    } else {
      setButtonDisabled(false);
      setOtperror(res.message);
    }

  };


  const [userRoles, setUserRoles] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const methods = useForm();
  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState(dummyProfile);
  const [hasImage, sethasImage] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [fileKey, setFileKey] = useState('');
  const [fileCordinates, setFileCordinates] = useState();
  const [isUpdateNumDisabled, setIsUpdateNumDisabled] = useState(true);

  const { handleSubmit, reset, getValues,register, formState: { errors },setError, setValue, watch } = methods;
  const phoneRegex = /^\d{10}$/;

  const onSubmit = (data) => {
    // alert('1');
    setSubmitDisabled(true);
    updateProfile(data);
  };

  function removeImage() {
    setFile();
    setFilePath(dummyProfile);
    sethasImage(0);
  }

  function handleFileUpload(e) {
    if (e.target && e.target.files[0]) {
      setFile(e.target.files[0]);
      // console.log(e.target.files[0]);
      // setFilePath(URL.createObjectURL(e.target.files[0]));
      sethasImage(1);
      setImageUploaded(true);
      setSubmitDisabled(true);
    }
  }


  useEffect(() => {
    setOtpvalues({ ...otpvalues, otp: OTP });
    setOtperror('');
    if (OTP.length == 4)
      setButtonDisabled(false);
    else
      setButtonDisabled(true);
  }, [OTP]);

  // const FileUpload = async (userKey) => {
  //   console.log("file");
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   try {

  //     const response = await MakeFileUpload(  "post",   formData, `user/${userKey}/uploadprofileimage`   );

  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };


  useEffect(() => {
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFile();
    setFilePath(userDetails.profile_img || dummyProfile);
    sethasImage(userDetails.hasImage || 0);
    reset({
      first_name: userDetails.first_name || "",
      last_name: userDetails.last_name || "",
      email: userDetails.email || "",
      location: userDetails.location || "",
      user_role_id: userDetails.user_role_id || "",
      address: userDetails.address || "",
      user_uuid: userDetails.user_uuid || "",
    });
    return () => {
      setImageUploaded(false);
      setSubmitDisabled(false);
      setFile();
      setFilePath(dummyProfile);
      sethasImage(0);
      reset();
    };
  }, [userDetails]);


  const closeModal = () => {
    setIsOpen(false);
    // reset();
  }
  const updateProfile = async (data) => {
    try {
      const response = await MakeAxiosRequest("post", { ...data, hasImage, fileKey, fileCordinates }, `/updateprofile`);
      // file && (await FileUpload(userDetails.user_uuid));
      if (response.status === 1) {
        userData();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });

      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    setSubmitDisabled(false);
  };



  const fields = [
    {
      name: "first_name",
      label: "First Name",
      rules: { required: "Please enter First name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "last_name",
      label: "Last Name",
      rules: { required: "Please enter Last name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "email",
      label: "Email",
      rules: {
        required: "Plese enter email ",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await MakeAxiosRequest(
              "post", { email: fieldValue, userid: userDetails?.id }, "/checkemailexist");
            return response.data.hasData == 0 || "Email already exist";
          },
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "location",
      label: "Location",
      rules: { required: "Location is required" },
      type: "inputfield",
      isShow: true,
    },

    {
      name: "address",
      label: "Address",
      rules: { required: "Address is required" },
      type: "textarea",
      isShow: true,
    },
  ];


  const fileRef = useRef(null);
  const openFile = () => {
    fileRef.current.click();
  };

  const croppedImage = (imgPath, tempKey, cordinates) => {
    // console.log(imgPath);
    setFile(null);
    setFilePath(imgPath);
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFileKey(tempKey);
    setFileCordinates(cordinates || null);
  };

  const deleteAccount = async () => {
    try {


      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete your aladinz account?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, Delete`,
        customClass: { confirmButton: 'btn btn-primary' },
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });



      if (result.isConfirmed) {
        try {
          const res = await MakeAxiosRequest(
            'post', {}, `/deleteaccountrequest`
          );

          if (res.status === 1) {
            reset();
            setOtpvalues({
              ...otpvalues,
              otp_uuid: res.data.otp_uuid,
            });
            alert(res.data.otp);
            $('#otp-modal').modal('show');



          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              customClass: { confirmButton: 'btn btn-primary' },
              text: res.message,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            customClass: { confirmButton: 'btn btn-primary' },
            text: 'An error occurred while deleting this account.',
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  const changeNumber = () => {
    // $('#phone-modal').modal('show');
    setIsOpen(true);
    setValue("phone", userDetails.phone_number || "");

    // reset({
    //   phone: userDetails.phone_number || "",
    // });
  }
  const phone = watch("phone");

  useEffect(() => {
    setIsUpdateNumDisabled(phone==userDetails.phone_number);
  }, [phone]);

  const updateNumber = async (numberupdate) => {
    // const phone = getValues('phone');
    
    try {
      const res = await MakeAxiosRequest(
        'post', { phone_number: phone }, `/changephonenumber`
      );

      if (res.status === 1) {
        // reset();
        setIsOpen(false);
        setOtpvalues({
          ...otpvalues,
          otp_uuid: res.data.otp_uuid,
        });
        alert(res.data.otp);
        $('#phoneotp-modal').modal('show');



      } else {
        setError('phone',{type:'custom',message:res.message})
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        customClass: { confirmButton: 'btn btn-primary' },
        text: 'An error occurred while changing the number.',
      });
    }
    
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <center><img className="loader" src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center>;
  } else {

    return (
      <>
        <div className="page">

          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-8 col-xl-4 col-xxl-3 mx-auto mb-3">
                  <div className="card cmpny-dtls h-100">
                    <div className="cmpny-cover position-relative mb-4">
                      <img className="cmpny-cover-img" src={dummyBanner} alt="" />

                      <div className="company-prfl">

                        <img className="" src={filePath} />

                      </div>
                    </div>
                    <div className="card-body mt-5">
                      <div class="text-center">
                        <h1 className="mb-0">{userDetails.first_name} {userDetails.last_name}</h1>
                        <p className="mb-2">{userDetails.email}</p>
                        {userDetails.created_at && (
                          <p class="join-dt"> <span>Joined on:</span> {userDetails.created_at} </p>
                        )}
                        {userDetails.user_role && (
                          <span class="status-tag my-2 px-3">{userDetails.user_role}</span>)}
                      </div>



                      <a href="javascript:void()" onClick={() => deleteAccount()} class="btn btn-danger-outline m-1 w-100">
                        <i class="fa-solid fa-trash-can me-2"></i>Delete Account</a>




                      <div class="cmpny-dtls-txt mt-4">

                        {userDetails.phone_number && (
                          <>
                            <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                              <div className="">
                                <span className="d-block mb-2">Phone Number</span>
                                <p className="mb-0">+91 {userDetails.phone_number}<a className="ms-2" href="javascript:void()" onClick={changeNumber}><i class="fa fa-pencil" aria-hidden="true"></i></a></p>
                              </div>
                            </div>
                          </>
                        )}
                        {userDetails.address && (
                          <>
                           <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div>
                              <span className="d-block mb-2">Address</span>
                              <p> {userDetails.address.split('\n').map((part, index) => (
                                  <React.Fragment key={index}>
                                    {part}
                                    <br />
                                  </React.Fragment>
                                ))}</p>
                            </div>
                            </div>
                          </>
                        )}
                        {userDetails.pincode && (
                          <>
                            <span className="d-block mb-2">Pin Code</span>
                            <p>{userDetails.pincode}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-xl-8 col-xxl-9 mx-auto mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4>Edit Profile</h4>
                      <p>You can edit your profile details here</p>
                      <FormProvider {...methods}>
                        <form class="row mt-3" onSubmit={handleSubmit(onSubmit)}>
                          <div class="col-12 mb-4">
                            {!isImageUploaded && (
                              <div className="crte-bsness-top-banner-img p-1 modal-prfl">
                                <img className="rounded-pill" id="createimage" src={filePath} alt="" onClick={() => { openFile(); }} />
                                <input style={{ visibility: "hidden" }} ref={fileRef} type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleFileUpload} />
                                {hasImage == "1" ? (
                                  <a href="javascript:void(0)" className="img-cnrtl-btn" onClick={removeImage} >
                                    <span className="dlt-icon"><i className="fa-regular fa-trash-can" ></i></span>
                                  </a>
                                ) : (
                                  <a href="javascript:void(0)" className="img-cnrtl-btn" onClick={() => { openFile(); }}>
                                    <span className=""><i class="fa-solid fa-pen" ></i> </span>
                                  </a>
                                )}
                              </div>
                            )}
                            {isImageUploaded && (
                              <ImageCropper
                                file={file}
                                onCropped={croppedImage}
                                aspectRatio={1 / 1}
                              />
                            )}

                          </div>


                          {fields?.map((field, index) => {
                            return field.isShow && (
                              <React.Fragment key={index}>
                                {(() => {
                                  switch (field.type) {
                                    case "inputfield":
                                      return (<InputField key={index} className="col-md-6 col-lg-6 mb-3" {...field} />);
                                    case "textarea":
                                      return (<TextArea key={index} className="col-md-6 col-lg-6 mb-3" {...field} />);
                                    default:
                                      break;
                                  }
                                })()}
                              </React.Fragment>
                            );
                          })}

                          <div class="col-12 text-end mt-3">
                            <button type="submit" class="btn btn-primary" disabled={isSubmitDisabled}>Update Profile</button>
                          </div>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Payout Modal */}
        <div class="modal fade" id="otp-modal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 mt-4">
                    <div class="text-center">
                      <h5>Confirm Deletion</h5>
                      <p class="gray">Enter the otp code you have recived on your registerd mobile number</p>
                    </div>
                  </div>
                </div>
                <form
                  className="my-4"
                  onSubmit={handleSubmit(verifyOtp)}
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      OTP Code
                    </label>
                    <OTPInput
                      className="otp-input"
                      inputClassName="form-control"
                      value={OTP}
                      onChange={setOTP}
                      secure={true}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                    />
                    {otperror && (
                      <span className="error">
                        {otperror}
                      </span>
                    )}
                  </div>
                  <div class="modal-footer b-0">
                    <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn btn-primary" disabled={isButtonDisabled} >Verify</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>

        <div class="modal fade" id="phoneotp-modal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 mt-4">
                    <div class="text-center">
                      <h5>Confirm Updation</h5>
                      <p class="gray">Enter the otp code you have recived on your registerd phone number</p>
                    </div>
                  </div>
                </div>
                <form
                  className="my-4"
                  // onSubmit={handleSubmit(verifyMobileOtp)}
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      OTP Code
                    </label>
                    <OTPInput
                      className="otp-input"
                      inputClassName="form-control"
                      value={OTP}
                      onChange={setOTP}
                      secure={true}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                    />
                    {otperror && (
                      <span className="error">
                        {otperror}
                      </span>
                    )}
                  </div>
                  <div class="modal-footer b-0">
                    <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" onClick={verifyMobileOtp}>Verify</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
{modalIsOpen &&
        <Modal
          show={modalIsOpen}
          contentLabel="Example Modal"
        >
          <form onSubmit={handleSubmit(updateNumber)}>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mt-4">
                <div class="text-center">
                  <h5>Update Phone Number</h5>
                </div>
              </div>
            </div>
            
              <input className="form-control mt-4" type="text" name="phone" placeholder="Enter Phone Number" {...register('phone', {
                required: 'Please enter phone number',
                pattern: {
                  value: phoneRegex,
                  message: 'Please enter a valid phone number'
                }
              })}/>
               {errors.phone && <span className="error">{errors.phone.message}</span>}
             
          </div>
          <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" onClick={closeModal} >Cancel</button>
                <button type="submit" class="btn btn-primary"  disabled={isUpdateNumDisabled} >Update</button>
              </div>
              </form>

        </Modal>
  }

       

      </>
    )
  }
}

export default Myprofile