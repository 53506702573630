// export const baseUrl = 'http://localhost/aladinz-manage-api/'
export const baseUrl = process.env.REACT_APP_API_URL;
export const apiKey = process.env.REACT_APP_API_KEY;
export const appSecretKey = process.env.REACT_APP_SECRET_KEY;
export const phoneRegex = /^(\+?91[\s.-]?)?([06789]\d{9,10})$/;

const user = localStorage.getItem('user');
const data = JSON.parse(user)

export const sidebarData = [
    {
        type:'dashboard',
        title:'Dashboard',
        icon:'dashboard',
        link:'/dashboard',
        sublist:[],
        is_show:true
    },
    {
        type:'businesses',
        title:'Businesses',
        icon:'storefront',
        link:'/businesses',
        sublist:[],
        is_show:true
    },
    {
        type:'agents',
        title:'Agents',
        icon:'handshake',
        link:'/agents',
        sublist:[],
        is_show:true
    },
    {
        type:'users',
        title:'Users',
        icon:'group',
        link:'/users',
        sublist:[],
        is_show:true
    },
    {
        type:'reports',
        title:'Reports',
        icon:'lab_profile',
        link:'/reports',
        is_show:true,
       // is_show:data.user_role == "1",
        sublist:[
            {
                type:'searchreports',
                title:'Search Reports',
                icon:'manage_search',
                link:'/searchreports',
                is_show:true,
                //is_show:data.user_role == "1",
            },
            {
                type:'paymentreports',
                title:'Payment Reports',
                icon:'receipt_long',
                link:'/paymentreports',
                is_show:true,
                //is_show:data.user_role == "1",
            },

            {
                type:'activitylogs',
                title:'Activity Log',
                icon:'summarize',
                link:'/activitylogs',
                is_show:true,
               // is_show:data.user_role == "1",
            }
        ]
    },
    {
        type:'categories',
        title:'Categories',
        icon:'category',
        link:'/categories',
        sublist:[],
        is_show:true
    },
    {
        type:'offers',
        title:'Offers',
        icon:'redeem',
        link:'/offers',
        sublist:[],
        is_show:true,
        is_count:1,
    },
    {
        type:'reviews',
        title:'Reviews',
        icon:'reviews',
        link:'/reviews',
        sublist:[],
        is_count:1,
       // is_show:data.user_role == "1"

    },
    {
        type:'subscriptions',
        title:'Subscriptions',
        icon:'subscriptions',
        link:'/subscriptions',
        sublist:[],
        is_show:true
    },
    {
        type:'reportedissues',
        title:'Reported Issues',
        icon:'flag',
        link:'/reportedissues',
        sublist:[],
        is_show:true,
        is_count:1,
    }
    
   
]

export const navbarTitle = [
    {
        title:'Dashboard',
        link:'dashboard',
    },
    {
        title:'Businesses',
        link:'businesses',
    },
    {
        title:'Agents',
        link:'agents',
    },
    {
        title:'Users',
        link:'users',
    },
    {
        title:'Reports',
        link:'reports',
    },
    {
        title:'Search Reports',
        link:'searchreports'
    },
    {
        title:'Payment Reports',
        link:'paymentreports'
    },
    {
        title:'Subscriptions',
        link:'subscriptions',
    },
    {
        title:'Categories',
        link:'categories',
    },
    {
        title:'Offers',
        link:'offers',
    },
    {
        title:'Reviews',
        link:'reviews',
    },
    {
        title:'Reported Issues',
        link:'reportedissues',
    },
    {
        title:'User details',
        link:'user',
    },
    {
        title:'Agent details',
        link:'agent',
    },
    {
        title:'Business details',
        link:'business',
    },
    {
        title:'Activity Log',
        link:'activitylogs',
    },
    {
        title:'Profile',
        link:'myprofile',
    },
    {
        title:'Notifications',
        link:'notificationslist',
    }
]

export const breadcrumbsData= [
    {
        title:'Dashboard',
        link:'/dashboard',
    },
    {
        title:'Businesses',
        link:'/businesses',
    },
    {
        title:'Agents',
        link:'/agents',
    },
    {
        title:'Users',
        link:'/users',
    },
    {
        title:'Reports',
        link:'/reports',
    },
    {
        title:'Search Reports',
        link:'/searchreports'
    },
    {
        title:'Payment Reports',
        link:'/paymentreports'
    },
    {
        title:'Subscriptions',
        link:'/subscriptions',
    },
    {
        title:'Reported Issues',
        link:'/reportedissues',
    },
    {
        title:'User details',
        link:'/user',
    },
]
