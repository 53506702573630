import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MakeAxiosRequest } from '../../utils/handler';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
 import loaderFile   from '../../Assets/Images/loader.gif';
import { Button } from 'react-bootstrap';
import CreateEditModal from './CreateEditModal';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";

function Listing({searchValue}) {

  const navigate = useNavigate();
  const params = useParams();
  const type = params.type || "all";
  const sortby = params.sortby || "newest";
  const status = params.status || "all";

  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
    { value: "1", className: "status-tag status-tag-active",   label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];


  const columns = [ 
    {
      name: <div className="datatable-hd"><h6>Name</h6></div>,
      selector: row => (
        <UserRowActions  row={{
          primaryData: row.first_name,
          secondryData: row.last_name,
          imagePath :row.profile_img,
          sublistData: row.user_role,      
          viewUrl:row.status !== '2' ? `/agent/${row.agent_user_uuid}` :  "javascript:void(0)" 
        }} />       
      ),
    },
    {
        name: <div className="datatable-hd"><h6>Email/Phone Number</h6></div>,
        selector: row => (
          <div data-title="" className="numeric data-table-sec">
            <p>{row.email}</p>
            <span>{row.phone_number}</span>
        </div>
        ),   
    },
        
    {
        name:<div className="datatable-hd"><h6>Created By</h6></div>,
        selector: row => (<div data-title="" className="numeric data-table-sec">
            <p>{row.created_by}</p>
            <span>{format(new Date(row.created_at), 'dd/MM/y')}</span>
        </div>),   
    },
    {
        name: <div className="datatable-hd"><h6>Updated By</h6></div>,
        selector: row => ( <div data-title="" className="numeric data-table-sec">
            {row.updated_at && 
             <> <p>{row.updated_by}</p> <span>{format(new Date(row.updated_at),  'dd/MM/y')}</span> </>
            } 
        </div>),        
    },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {statusOptions.map(option => {
              return row.status === option.value && (       
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })}
        </div>
      ),
    }, 
     { 
      selector: row => (
        <UserRowActions
          row={{
            tableData:row,
            actionkey: row.agent_user_uuid,
            actionrole: row.user_role,
            actionstatus: row.status,
            isModal:'1',
            type:'action',
            viewUrl:`/agent/${row.agent_user_uuid}`
          }}
          handleshowEdit={handleshowEdit} changeStatus={changeStatus} makeSuperAgent={makeSuperAgent}
        />
      ),    
     },    
  ];



  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState([]);
  const [agentrolefilters, setAgentrolefilters] = useState([]);
  // const [selectedOption, setSelectedOption] = useState('all');
  const { setBreadcrumbsData } = useBreadcrumb();   
  const [isEdit, setisEdit] = useState(false);
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [agentDetails, setuserDetails] = useState({});
  const [showEdit, setshowEdit] = useState(false);
  const [listParams, setListParams] = useState({
    offset: "0",  status: status,   sortBy: sortby,userrole:type
  });

    // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([ { label: "Agents" },  { label: "Listing"},  ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 

    // Status change of agents 
    const changeStatus = async (agentKey, status, type) => {
      const statusType = type.toLowerCase();    
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          html:  `Are you sure you want to ${status} this <br>${statusType}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Yes, ${status}`,
          customClass: { confirmButton: 'btn btn-primary' },
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          preConfirm: async () => {
            try {
              const res = await MakeAxiosRequest('post', { "status": status }, `agent/${agentKey}/changestatus`);
              if (res.status === 1) {
                return true;
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error(error); // Log the error
              Swal.fire({
                icon: 'warning',
                title: 'Request Failed!',
                customClass: { confirmButton: 'btn btn-primary' },
                text: ` ${error.message}`,
              });
              return false; 
            }
          },
        });
    
        if (result.isConfirmed && result.value === true) {
          $('#tranfer-account').modal('hide');
          Swal.fire({
            icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },   text: type + ' ' + status + 'd successfully.' 
          });
          agentData();
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          customClass: { confirmButton: 'btn btn-primary' },
          text: 'An error occurred while changing the status.',
        });
      }
    };
    // making agents to  super agents
    const makeSuperAgent = async (agentKey) => {
      try {
        let showConfirmButton = true; 
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to change the role of agent?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, change it!',
          cancelButtonText: 'Cancel',
          customClass: {   confirmButton: 'btn btn-primary'  },
          reverseButtons: true,
        //  showLoaderOnConfirm: true,        
          preConfirm: async () => {
            try {             
              Swal.update({
                showConfirmButton: showConfirmButton
              });    
              const res = await MakeAxiosRequest( 'post', {}, `agent/${agentKey}/markassuperagent` );    
              if (res.status === 1) {
                return true; // close the modal on success
              } else {
                throw new Error(res.message);
              }
            } catch (error) {
              console.error('Error:', error);
              Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          },
                
        });    
        if (result.isConfirmed && result.value === true) {
          Swal.fire({ icon: 'success',  title: 'Success!', customClass: {  confirmButton: 'btn btn-primary'  },
            text: 'Status changed successfully.',
          });
          agentData();
        }
      } catch (error) {
        console.error('Error:', error);    
        Swal.fire({  icon: 'error',  title: 'Error',  customClass: {  confirmButton: 'btn btn-primary'  },
          text: 'An error occurred while changing the status.',
        });
      }
    };
  
  const agentData = async () => {      
      const res = await MakeAxiosRequest("post", {...listParams,searchValue}, "/agents");
      
      setIsLoaded(true);
      setItems(res.data.agentusers);
      const statusCounts = {
        all: res.data.totalcount,
        active: res.data.activecount,
        inactive: res.data.inactivecount,
        pending: res.data.pendingcount,
      };
      setisTableLoaded(true); 
      const selectedStatus = listParams.status || 'active'; // Default to 'active' if status is not provided      
      setTotalRows(statusCounts[selectedStatus]);
      setFilters(res.data.filters); 
      setAgentrolefilters(res.data.agentrolefilters); 
      
  }
  useEffect(() => {
    agentData();
  }, [listParams,searchValue]);


 
    const handlePageChange = page => {
      const newOffset = (page - 1) ;
      setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));
       
    }
  
  const handleSelectChange = (filterName,value) => {  
    setisTableLoaded(false); 
    if(filterName==='sortBy')
      navigate('/agents/'+listParams.userrole+'/'+value+'/'+listParams.status);  
    if(filterName==='status')
      navigate('/agents/'+listParams.userrole+'/'+listParams.sortBy+'/'+value);
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value,offset:'0' }));
  };


   const handleClickChange = (filterName,value) => {  
    navigate('/agents/'+value+'/'+listParams.sortBy+'/'+listParams.status);
    setisTableLoaded(false); 
      // setSelectedOption(value);
      setListParams(prevParams => ({ ...prevParams,  [filterName]: value,offset:'0' }));
    };

  const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
    setuserDetails({})
   };


  const handleshowEdit = (user) => {
    setisEdit(true)
    setshowEdit(true)
    setuserDetails(user)
    console.log(user);
  };
  const handleCloseEdit = () => setshowEdit(false);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center flex-wrap ">
          <div className="col-xl-5 mb-3">
            <div className="d-flex justify-content-center justify-content-xl-start">
              <ul className="sort-btn-group">
                {agentrolefilters?.flatMap((filter) => filter.options)?.map((option, optionIndex) => (
                  <li key={optionIndex}>
                   <a  className={`btn tabview ${option.value === type ? 'active' : ''}`}   onClick={() => handleClickChange(agentrolefilters[0].type, option.value)}>
                      {option.option}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        <div className="col-xl-7 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-md-end flex-wrap">
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value}>{option.option}</option>
                )}                        
              </select>
            </div>
          ))}
             <Button className="btn btn-primary my-2" onClick={handleShow}> Create </Button>
          </div>
          </div>
        </div>
        <div className="">          
          <DataTableList  pageChange={handlePageChange}   totalCount={totalRows}  columns={columns}  tableData={items}  type ={'agents'} isTableLoaded={isTableLoaded}/>    
        </div>
        <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, agentDetails,agentData,perPage }} />

      </>
    );
  }
}

export default Listing;
