import React from "react";
import { Link } from "react-router-dom";
import { useBreadcrumb } from "./BreadcrumbContext";

// ... (rest of the Breadcrumbs component)

function Breadcrumbs() {
    
  const { breadcrumbs } = useBreadcrumb();

  // console.log(useBreadcrumb);

  return (
    <nav className="breadcrumb-nav my-2">  
      <ol className="breadcrumb mb-0">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={breadcrumb.path}>
            {index > 0 && (
            <span className="mx-1 mx-md-3">
              <i className="fa-solid fa-angle-right"></i>
            </span>
          )}
            <li className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? "active" : ""}`}>
              {index === breadcrumbs.length - 1 ? (
                breadcrumb.label
              ) : (
                <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
              )}
            </li>
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
