import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Navigate, Route, Routes } from "react-router-dom";
import React,{ useEffect, useState } from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import axios from 'axios'
import { useSelector } from "react-redux";
import Layout from "./Components/Layout/Layout";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Users from "./Pages/Users/Users";
import Agents from "./Pages/Agents/Agents";
import Business from "./Pages/Businesses/Business";
import Subscription from "./Pages/Subscription/Subscription";
import BusinessCreate from "./Pages/Businesses/BusinessCreate";
import BusinessDetail from "./Pages/Businesses/BusinessDetail";
import AgentDetails from "./Pages/Agents/Details";
import Category from "./Pages/Category/Category";
import Offers from "./Pages/Offers/Offers";
import Activitylog from "./Pages/Reports/activitylog/Activitylog";
import Myprofile from "./Pages/Profile/Myprofile";
import SearchReports from "./Pages/Reports/searchreports/SearchReports";
import PaymentReports from "./Pages/Reports/paymentreports/PaymentReports";
import ReportedIssues from "./Pages/Reportedissues/ReportIssues";
import Reviews from "./Pages/Reviews/Reviews";
import NotificationsList from "./Pages/Notifications/NotificationsList";
import UserSubscriptions from "./Pages/Users/Subscriptions"
import UserDetails from "./Pages/Users/Details";
import ReportPre from "./Pages/Reports/ReportPreview";

// const UserDetails = React.lazy(() => import('./Pages/users/Details'));
// const UserSubscriptions = React.lazy(() => import('./Pages/users/Subscriptions'));
// const Users = React.lazy(() => import('./Pages/users/Users'));
// const Agents = React.lazy(() => import('./Pages/agents/Agents'));
// const Business = React.lazy(() => import('./Pages/businesses/Business'));
// const Subscription = React.lazy(() => import('./Pages/subscription/Subscription'));
// const BusinessCreate = React.lazy(() => import('./Pages/businesses/BusinessCreate'));
// const BusinessDetail = React.lazy(() => import('./Pages/businesses/BusinessDetail'));
// const AgentDetails = React.lazy(() => import('./Pages/agents/Details'));
// const Category = React.lazy(() => import('./Pages/category/Category'));
// const Offers = React.lazy(() => import('./Pages/offers/Offers'));
// const Activitylog = React.lazy(() => import('./Pages/reports/activitylog/Activitylog'));
// const Myprofile = React.lazy(() => import('./Pages/profile/Myprofile'));
// const SearchReports = React.lazy(() => import('./Pages/reports/searchreports/SearchReports'));
// const PaymentReports = React.lazy(() => import('./Pages/reports/paymentreports/PaymentReports'));
// const ReportedIssues = React.lazy(() => import('./Pages/reportedissues/ReportIssues'));
// const Reviews = React.lazy(() => import('./Pages/reviews/Reviews'));
// const NotificationsList = React.lazy(() => import('./Pages/notifications/NotificationsList'));



function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const storedIP = localStorage.getItem('ip') || '';
  const [ip, setIP] = useState(storedIP);
  const getData = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      console.log(res.data);
      const newIP = res.data.IPv4;      
      setIP(newIP);
      localStorage.setItem('ip', newIP);
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  // console.log(isAuthenticated);
//  useEffect(() => {
//    // Set the default time zone for the entire system to India Standard Time (IST)
//    const indiaTimeZone = 'Asia/Kolkata';
//    // Override Intl.DateTimeFormat to use India Standard Time
//    Intl.DateTimeFormat = function(locale, options) {
//      options = options || {};
//      options.timeZone = indiaTimeZone;
//      return new Intl.DateTimeFormat(locale, options);
//    };
//  }, []); // Run this effect only once on component mount
  return (
    <>
      {/* {currpath !== '/login' && <><Sidebar /><Navbar /></>} */}
      {/* {!excludedRoutes.includes(window.location.pathname) && <Navbar />} */}
      {/* {!isEmpty(accesstoken)&&<Navbar />}  */}
 
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
         <Route
          path="/paymentdownload"
          element={
            <ProtectedRoute>
              <Layout>
                <ReportPre />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/businesses"
          element={
            // <React.Suspense fallback="Loading...">
            <ProtectedRoute>
              <Layout>
                <Business />
              </Layout>
            </ProtectedRoute>
            // </React.Suspense>
          }
        />
        <Route path="/businesses/:sortby/:status" element={
            // <React.Suspense fallback="Loading...">
            <ProtectedRoute>
              <Layout>
                <Business />
              </Layout>
            </ProtectedRoute>
            // </React.Suspense>
          }
        />
        <Route path="/business/:key" element={<ProtectedRoute><Layout><BusinessDetail /></Layout></ProtectedRoute>}/>
        <Route path="/business/:key/:tab" element={<ProtectedRoute><Layout><BusinessDetail /></Layout></ProtectedRoute>}/>
            
         <Route
          path="/businesses/create"
          element={
            <ProtectedRoute>
              <Layout>
                <BusinessCreate />
              </Layout>
            </ProtectedRoute>
          }
        />
<Route
          path="/businesses/edit/:userKey"
          element={
            <ProtectedRoute>
              <Layout>
                <BusinessCreate />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents"
          element={
            <ProtectedRoute>
              <Layout>
                <Agents />
              </Layout>
            </ProtectedRoute>
          }
        />
<Route
          path="/agents/:type"
          element={
            <ProtectedRoute>
              <Layout>
                <Agents />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/:type/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Agents />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Layout>
                <Users />
              </Layout>
            </ProtectedRoute>
            
          }
        
        />
          <Route
          path="/users/:type/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Users />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/notificationslist"
          element={
            <ProtectedRoute>
              <Layout>
                <NotificationsList />
              </Layout>
            </ProtectedRoute>
            
          }
        
        />

        <Route
          path="/searchreports"
          element={
            <ProtectedRoute>
              <Layout>
                <SearchReports />
              </Layout>
            </ProtectedRoute>
          }
        />
  <Route
       path="/searchreports/:type"
          element={
            <ProtectedRoute>
              <Layout>
                <SearchReports />
              </Layout>
            </ProtectedRoute>
          }
        />
      <Route
       path="/searchreports/:type/:sortby"
          element={
            <ProtectedRoute>
              <Layout>
                <SearchReports />
              </Layout>
            </ProtectedRoute>
          }
        />
           <Route
          path="/activitylogs"
          element={
            <ProtectedRoute>
              <Layout>
                <Activitylog />
              </Layout>
            </ProtectedRoute>
          }
        />
<Route
          path="/activitylogs/:sortby"
          element={
            <ProtectedRoute>
              <Layout>
                <Activitylog />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/activitylogs/:sortby/:startdate/:enddate"
          element={
            <ProtectedRoute>
              <Layout>
                <Activitylog />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/myprofile"
          element={
            <ProtectedRoute>
              <Layout>
                <Myprofile />
              </Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories/:key/subcategory"
          element={
            <ProtectedRoute>
              <Layout>
                <Category />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/:key/subcategory/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Category />
              </Layout>
            </ProtectedRoute>
          }
        />
          <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <Layout>
                <Category />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Category />
              </Layout>
            </ProtectedRoute>
          }
        />
           <Route
          path="/offers"
          element={
            <ProtectedRoute>
              <Layout>
                <Offers />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/offers/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Offers />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reviews"
          element={
            <ProtectedRoute>
              <Layout>
                <Reviews />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reviews/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout>
                <Reviews />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <ProtectedRoute>
              <Layout>
                <Subscription />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions/:sortby"
          element={
            <ProtectedRoute>
              <Layout>
                <Subscription />
              </Layout>
            </ProtectedRoute>
          }
        />
         <Route
          path="/paymentreports"
          element={
            <ProtectedRoute>
              <Layout>
                <PaymentReports />
              </Layout>
            </ProtectedRoute>
          }
        />
<Route
          path="/paymentreports/:type"
          element={
            <ProtectedRoute>
              <Layout>
                <PaymentReports />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentreports/:type/:startdate/:enddate"
          element={
            <ProtectedRoute>
              <Layout>
                <PaymentReports />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportedissues"
          element={
            <ProtectedRoute>
              <Layout> <ReportedIssues />  </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportedissues/:sortby/:status"
          element={
            <ProtectedRoute>
              <Layout> <ReportedIssues />  </Layout>
            </ProtectedRoute>
          }
        />
         <Route path="/user/:key" element={<ProtectedRoute><Layout><UserDetails /></Layout></ProtectedRoute>}>
            {/* <Route index element={<Navigate to="./subscriptions" />} />
            <Route path="subscriptions" element={<UserSubscriptions />} /> */}
        </Route>
        <Route path="/user/:key/:tab" element={<ProtectedRoute><Layout><UserDetails /></Layout></ProtectedRoute>}/>

        <Route path="/agent/:key" element={<ProtectedRoute><Layout><AgentDetails /></Layout></ProtectedRoute>}/>
        <Route path="/agent/:key/:tab" element={<ProtectedRoute><Layout><AgentDetails /></Layout></ProtectedRoute>}/>
        <Route path='*' element={<Navigate to='/dashboard' />} />

      </Routes>
    </>
  );
}

export default App;
