import React, {useState, useEffect} from 'react';
import AddService from "./AddService";
import NoDataFound from "../../Components/Listing/NoDataFound";
import { useParams } from "react-router";
import loaderFile   from '../../Assets/Images/loader.gif';
import { MakeAxiosRequest } from "../../utils/handler";
import Swal from "sweetalert2";
import ListPagination from "../../Components/Listing/ListPagination";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Services({ listData, handlePageChange, handleSelectChange }) {
  const params = useParams();
  const businessKey = params.key;
  const [show, setShow] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const totalCount = listData?.totalcount;

  const handleShow = () => {
    setServiceData(null);
    setShow(true);
    //  console.log(offerDetails);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShowEdit = (data) => {
    setServiceData(data);
    setShow(true);
    //  console.log(offerDetails);
  };



  const removeService = async (serviceKey) => {
    try {     
    
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: "Are you sure you want to remove this service?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {  confirmButton: 'btn btn-primary'  },
        cancelButtonText: 'Cancel',
        //showLoaderOnConfirm: true,
        reverseButtons: true,  
        preConfirm: async () => {
          try {             
            const res = await MakeAxiosRequest(
              "post", {},  `/business/${businessKey}/service/${serviceKey}/destroy`
            );    
            if (res.status === 1) {
              return true; 
            } else {
              throw new Error(res.message);
            }
          } catch (error) {          
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },        
      });
      if (result.isConfirmed) {
        if (result.value === true) {
          Swal.fire({  icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },  
                text: 'Service deleted successfully.',
          });
          handleFilterChange(filterData);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred.',
      });
    }
  };
  

  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    type: "sortBy",
    value: "newest",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    fetchData();
  }, [totalCount]);

    const fetchData =(type='') =>{
       setIsLoaded(totalCount !== undefined);  
      if(type==1){ 
        handleFilterChange(filterData);
      } 
   };

  const handleFilterChange = (type, value) => {
    handleSelectChange("services", type, value, currentPage);
    setFilterData({ type, value });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    const type = filterData.type;
    const value = filterData.value;
    handlePageChange("services", newOffset, type, value);
    setCurrentPage(newOffset);
  };

  const [modalSliderIndex, setModalSliderIndex] = useState(0);
  const [modalSliderOpen, setModalSliderOpen] = useState(false);

  const openModalSlider = async(index) => {
   setModalSliderIndex(index);
    setModalSliderOpen(true);
  };

  const closeModalSlider = () => {
    setModalSliderOpen(false);
  };
  const modalSliderSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: listData?.businessAlbum?.length > 1,
    speed: 500,
   initialSlide: modalSliderIndex, // Set the initial slide index
   prevArrow: <div className="slick-prev">Previous</div>,
   nextArrow: <div className="slick-next">Next</div>,
   
  };

  return (
    <div
      className="tab-pane fade show active"
      id="services-tab-pane"
      role="tabpanel"
      aria-labelledby="service-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-8 mb-3 text-center text-lg-start">
          <h4 className="mb-0">Services</h4>
          <p>Services added by Aladinz to this business will be displayed here. </p>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {/* Button trigger modal  */}
            {/* <button type="button" className="btn btn-primary my-2" data-bs-toggle="modal" data-bs-target="#service-tab-modal">Add Service</button> */}
            <button
              type="button"
              className="btn btn-primary my-2"
              onClick={handleShow}
            >
              Add Service
            </button>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-12">
          <div className="tab-service-sec">
          {!isLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
            <div className="row">
              {listData?.businessServices?.length > 0 ? (
                <>
                  {listData?.businessServices?.map((row, index) => (
                    <div
                      className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-3"
                      key={index}
                    >
                      <div className="services-imgs offers-card-hd popup-img">
                        <img className="" src={row.image}
                          onClick={() => openModalSlider(index)}
                        alt="" />
                        <p className="mt-2 mb-0">{row.title}</p>
                        {/* <a
                          href="javascript:void(0)"
                          onClick={() => removeService(row.service_uuid)}
                          className="close-btn"
                        >
                          <i className="fa-regular fa-circle-xmark"></i>
                        </a> */}
                        <div className="offer-optn justify-content-end mt-2">
                          <div className="btn-group option-btn close-btn">
                            <a
                              href="javascript:void(0)"
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-display="static"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  className="dropdown-item"
                                  onClick={() => handleShowEdit(row)}
                                >
                                  <i className="fa-solid fa-pen me-2"></i> Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  className="dropdown-item reject"
                                  onClick={() =>
                                    removeService(row.service_uuid)
                                  }
                                >
                                  <i className="fa-solid fa-trash-can me-2"></i>
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <ListPagination  pageChange={handlePageClick} totalCount={listData.totalcount} />
                  {modalSliderOpen && (
                    <div className="modal fade show gallery-modal" id="imageModalSlider" tabIndex="-1" role="dialog" aria-labelledby="imageModalSliderLabel" aria-hidden="true" style={{ display: modalSliderOpen ? 'block' : 'none' }}>
                
                        <div className="modal-dialog modal-dialog-centered modal-sm" style={{ maxWidth: '70%' }}>
                          <div className="modal-content">
                            <div className="modal-body">
                              <Slider {...modalSliderSettings}>
                                {listData?.businessServices?.map((row, index) => (
                                  <div key={index}>
                                    <img
                                      src={row.image}
                                      alt="" className="img-fluid" 
                                    />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                            <a href="javascript:void(0)" className='modal-close'><i class="fa-solid fa-xmark" onClick={closeModalSlider}></i></a>
                            {/* <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" onClick={closeModalSlider}>Close</button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      )} 
           
                </>
              ) : (
                <NoDataFound text="services" />
              )}
            </div>
          )}
          </div>
        </div>
      </div>

      <AddService
        {...{ show, handleClose, businessKey, fetchData, serviceData }}
      />
    </div>
  );
}

export default Services;
