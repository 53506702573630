import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MakeAxiosRequest } from "../../utils/handler";
import dummyProfile from "../../Assets/Images/dummy-profile.jpg";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import FormFields from "../../Components/Inputs/FormFields";
import { phoneRegex } from "../../Constants";

function CreateEditModal({
  showEdit,
  handleCloseEdit,
  isEdit,
  agentDetails,
  agentData,
  perPage,
  isSuperAgent,
  superAgentId,
}) {

  const [userRoles, setUserRoles] = useState([]);
  const [reportingTo, setReportingTo] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [showReportingTo, setShowReportingTo] = useState(false);

  const methods = useForm();
  const { handleSubmit, reset, setValue, watch, trigger, getValues } = methods;

  const agentType = watch('agent_type');

  const fetctDetails = async () => {
    try {
      const response = await MakeAxiosRequest(
        "post",
        {},
        "/agent/fetchdetails"
      );
      // console.log(response)
      if (response.status == 1) {
        const agenttypes = response.data.agenttypes?.map((item) => ({
          // Transform each item as needed
          id: item.id,
          name: item.user_role,
        }));
        const superagents = response.data.superagents?.map((item) => ({
          // Transform each item as needed
          id: item.id,
          name: item.first_name + " " + item.last_name,
        }));
        setUserRoles(agenttypes);
        setReportingTo(superagents);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const onSubmit = (data) => {
    if (isEdit) {
      updateUser(data);
    } else {
      createUser(data);
    }
  };
  const createUser = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post",  { ...data, hasImage, fileKey,fileCordinates }, "/agent/store");
      // console.log(response);
      if (response.status == 1) {
        const agentKey = response.data.userDetails.agent_user_uuid;

        // Call FileUpload function with the key
        (docsKey.length >= 1) && (await MakeAxiosRequest("post",{ filesKey:docsKey },`agent/${agentKey}/documents/add`));
        
        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        agentData(1);
        // alert(response.message);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const updateUser = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post",{ ...data, hasImage,fileKey,fileCordinates },`/agent/${agentDetails?.agent_user_uuid}/update`);
      if (response.status == 1) {

        // Call FileUpload function with the key
        // (docsKey.length >= 1) && (await MakeAxiosRequest("post",{ filesKey:docsKey },`agent/${agentDetails?.agent_user_uuid}/documents/add`));
        
        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        agentData(1);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const [file, setFile] = useState();
  const [fileKey, setFileKey] = useState('');
  const [fileCordinates, setFileCordinates] = useState();
  const [filePath, setFilePath] = useState(dummyProfile);
  const [hasImage, sethasImage] = useState(0);
  const [docsKey, setDocsKey] = useState([]);

  function removeImage() {
    setFile();
    setFilePath(dummyProfile);
    sethasImage(0);
  }

  function handleFileUpload(e) {
    if (e.target && e.target.files[0]) {
      setFile(e.target.files[0]);
      // console.log(e.target.files[0]);
      setFilePath(URL.createObjectURL(e.target.files[0]));
      sethasImage(1);
      setImageUploaded(true);
      setSubmitDisabled(true);
    }
  }

  // Watch the phone_number field
  const phoneNumber = watch("phone_number");

  useEffect(() => {
    if (phoneNumber && phoneRegex.test(phoneNumber)) {
      // Trigger any action or validation when phone_number changes  
      trigger("phone_number");
    }
  }, [phoneNumber]);

  const fields = [
    {
      name: "phone_number",
      label: "Phone Number",
      className: "col-md-6 col-lg-6 mb-3",
      rules: {
        required: "Please enter phone number",
        pattern: {
          value:phoneRegex,
          message: "Please enter a valid phone number",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await MakeAxiosRequest(
              "post",
              { phone_number: fieldValue, userid: agentDetails?.user_id },
              "/checkagentphoneexist"
            );
            if(response.data?.userDetails){
              const userData = response.data?.userDetails
              // setValue("first_name", userData.first_name || getValues('first_name'));
              // setValue("last_name", userData.last_name || getValues('last_name'));
              // setValue("email", userData.email || getValues('email'));
              // setValue("address", userData.address || getValues('address'));
              getValues('first_name') || setValue("first_name", userData.first_name);
              getValues('last_name') || setValue("last_name", userData.last_name);
              getValues('email') || setValue("email", userData.email);
              getValues('address') || setValue("address", userData.address);

              setFilePath(userData.profile_img || dummyProfile);
              sethasImage(userData.hasImage);
            }
            return response.data?.hasData == 0 || "Phone number already exist";
          },
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "email",
      label: "Email",
      className: "col-md-6 col-lg-6 mb-3",
      rules: {
        required: "Please enter email ",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await MakeAxiosRequest(
              "post",
              { email: fieldValue, userid: agentDetails?.user_id },
              "/checkagentemailexist"
            );
            // console.log(response.data.hasData);
            return response.data.hasData == 0 || "Email already exist";
          },
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "first_name",
      label: "First Name",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please enter first name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "last_name",
      label: "Last Name",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please enter last name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "address",
      label: "Address",
      className: "col-12 mb-3",
      rules: { required: "Please enter address" },
      type: "textarea",
      isShow: true,
    },
    {
      name: "agent_type",
      label: "Agent Type",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please select agent type" },
      type: "select",
      options: userRoles,
      isShow: isSuperAgent === 1 ? false :true ,
    },
    {
      name: "commission_percentage",
      label: "Commission Percentage",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { 
                required: "Please enter commission percentage",
                pattern: {
                  value:
                    /^\d+(\.\d+)?$/,
                  message: "Please enter a valid commission percentage",
                },
                validate: {
                  percentageCheck: async (fieldValue) => {
                    return fieldValue <= 100 || "Please enter commission percentage under 100";
                  },
                },
              },
      type: "inputfield",
      isShow: true ,
    },
    {
      name: "reporting_to",
      label: "Reporting To",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please select Reporting to" },
      type: "select",
      options: reportingTo,
      isShow: showReportingTo ,
    },
    {
      name: "documents",
      type: "dropzone",
      className: "col-md-12 mb-3",
      title: "Add documents",
      subtitle: "Upload KYC documents (image/pdf)",
      // rules: {
      //   validate: {
      //     validateImg: async () => {
      //       return docsKey.length >= 1 || "Please upload KYC document";
      //     },
      //   },
      // },
      hideRemovebtn: isSubmitDisabled,
      acceptedFileTypes:{"image/*": [],"application/pdf": []},
      errMsg:"Please upload KYC document",
      dropzoneMsg:"Please upload an image/pdf",
      filesKey: docsKey,
      setFilesKey: setDocsKey,
      setSubmitDisabled:setSubmitDisabled,
      isShow: !isEdit,
    }
  ];

  useEffect(() => {
    fetctDetails();
  }, []);

  useEffect(() => {
    agentType == "5" ? setShowReportingTo(true) : setShowReportingTo(false);
  }, [agentType]);

  const clearData=()=>{
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFile();
    setDocsKey([]);
    setFileKey(null);
    setFileCordinates(null);
  }

  useEffect(() => {
    clearData();
    setFilePath(agentDetails.profile_img || dummyProfile);
    sethasImage(agentDetails.hasImage);
    setShowReportingTo(agentDetails.user_role_id == "5");
    
    reset({
      first_name: agentDetails.first_name || "",
      last_name: agentDetails.last_name || "",
      email: agentDetails.email || "",
      phone_number: agentDetails.phone_number || "",
      agent_type: agentDetails.user_role_id || "",
      address: agentDetails.address || "",
      commission_percentage: agentDetails.commission_percentage || "",
      reporting_to : superAgentId || agentDetails.reporting_to || "",
    });
    return () => {
      clearData();
      setFilePath(dummyProfile);
      sethasImage(0);
      reset();
    };
  }, [showEdit, isEdit, agentDetails]);

  const fileRef = useRef(null);
  const openFile = () => {
    fileRef.current.click();
  };

  const croppedImage = (imgPath,tempKey,cordinates) => {
    // console.log(imgPath);
    setFile(null);
    setFilePath(imgPath);
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFileKey(tempKey);
    setFileCordinates(cordinates || null);
  };

  return (
    <>
      <Modal
        show={showEdit}
        // onHide={handleCloseEdit}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg" centered id="agent-modal" 
      >

        <div className="modal-content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-4">
                    <div className="text-center">
                      <h5>
                        {isEdit ? "Edit Agent Details" : "Create a new agent"}
                      </h5>
                      <p className="gray">
                        {isEdit
                          ? "Edit agent details on Aladinz app"
                          : "Add a new agent on Aladinz app"}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      {!isImageUploaded && (
                        <div className="crte-bsness-top-banner-img">
                          <img
                            className="rounded-pill"
                            id="createimage"
                            src={filePath}
                            alt=""
                            onClick={openFile}
                          />
                          <input
                            style={{ visibility: "hidden" }}
                            ref={fileRef}
                            type="file"
                            accept="image/jpeg, image/png, image/jpg"
                            onChange={handleFileUpload}
                          />

                          {hasImage == "1" ? (
                            <a className="img-cnrtl-btn" onClick={removeImage}>
                              <span className="dlt-icon">
                                <i className="fa-regular fa-trash-can"></i>
                              </span>
                            </a>
                          ) : (
                            <a href="javascript:void(0)" className="img-cnrtl-btn" onClick={openFile} >
                              <span className="">
                                <i className="fa-regular fa-add"></i>
                              </span>
                            </a>
                          )}
                        </div>
                      )}
                      {isImageUploaded && (
                        <ImageCropper
                          file={file}
                          onCropped={croppedImage}
                          aspectRatio={1/1}
                        />
                      )} 
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <FormFields fields={fields}/>
                </div>
              </div>

              <div className="modal-footer b-0">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitDisabled}
                >
                  {isEdit ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
}

export default CreateEditModal;
