import React, { useState } from 'react'
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import { useEffect } from 'react';
import Listing from './Listing';

function Reviews() {

   const [searchValue, setsearchValue] = useState('');

  return (
    <>
      <div className="page">
      {/* Content Row */}
      <div className="row align-items-center">
        <div className="col-9 col-lg-7">
          <Breadcrumbs />
        </div>
        <div className="col-3 col-lg-5">
          <ul className="top-search">
            <li className="d-none d-lg-block">
              <form className=" form-inline  ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                  <div className="input-group-append">
                    <button className="btn" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control border-0 small"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"  value={searchValue}  onChange={(e) => setsearchValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </form>
            </li>
            <li className="dropdown no-arrow d-lg-none">
              <div className="btn-group search-drpdwn">
                <a
                  href=""
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  <span className="material-symbols-outlined">search</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <form className="d-inline-block form-inline  ml-md-3 my-md-0 mw-100 navbar-search">
                      <div className="input-group">
                        <div className="input-group-append">
                          <button className="btn" type="button">
                            <i className="fas fa-search fa-sm"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control border-0 small"
                          placeholder="Search for.."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              
              
                <Listing searchValue={searchValue}/>

                
                 
            </div>
          </div>
        </div>
      </div>
    </div>
   
    </>
  )
}

export default Reviews