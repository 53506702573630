import { isEmpty } from 'lodash';
import React from 'react'
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    let location = useLocation();
    const accesstoken = localStorage.getItem('accesstoken');
    const user = localStorage.getItem('user');
    const userData = JSON.parse(user);

    const restrictedRoutes = ['searchreports', 'paymentreports','activitylogs'];
    const url = window.location.pathname.split('/');

    if(isEmpty(accesstoken)) {
        // Redirect to login page with the current location state
        return <Navigate to="/login" state={{ from: window.location.pathname }} replace />;
    }

    // If the current URL is in the array of restricted routes, redirect to dashboard
    if ((userData.user_role!='1') && (restrictedRoutes.includes(url[1]))) {
        return <Navigate to="/dashboard" replace />;
    }
    
 return children

};

export default ProtectedRoute;