import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
// import dummyProfile from "../../Assets/Images/dummy-profile.jpg";
import Swal from "sweetalert2";
import addImg from "../../Assets/Images/add-image.svg";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";


function CreateEditModal({
  showEdit,
  handleCloseEdit,
  isEdit,
  fetchData,
  categoryDetails,
  perPage,
  iscategory,
}) {

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);

  const params = useParams();
  const categoryKey = iscategory ? '' : params.key;
  const CategoryKeyMain = categoryKey;
  const value = categoryKey ? "subcategory" : "category";
  const methods = useForm({
    defaultValues: {
      title: categoryDetails.title || "",
      image_path: categoryDetails.image_path || "",
    },

  });


  const { handleSubmit, reset, register, formState: { errors }, } = methods;

  const onSubmit = (data) => {
    if (isEdit) {
      updateCategory(data);
    } else {
      createCategory(data);
    }
  };
  const createCategory = async (data) => {

    setSubmitDisabled(true);
    try {
      const url = (categoryKey) ? `categories/${categoryKey}/subcategory/store` : "/category/store";
      const response = await MakeAxiosRequest("post", { ...data, hasImage,fileKey,fileCordinates }, url);
      // console.log(response);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'  
        const categoryUKey = (categoryKey) ? response.data.subcategoryDetails.category_uuid : response.data.categoryDetails.category_uuid;
       

        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        fetchData(1, perPage);
        // alert(response.message);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Warning!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const updateCategory = async (data) => {
    setSubmitDisabled(true);
    try {
      const updateurl = (categoryKey) ? `/categories/${CategoryKeyMain}/subcategory/${categoryDetails?.category_uuid}/update` : `/category/${categoryDetails?.category_uuid}/update`;
      const response = await MakeAxiosRequest(
        "post",
        { ...data, hasImage,fileKey,fileCordinates },
        updateurl
      );
      // console.log(data);
      // console.log(agentDetails);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'
        // const userKey = response.agentDetails.user_uuid;

        // Call FileUpload function with the user key
        // file && (await FileUpload(categoryDetails.category_uuid));

        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        fetchData(1, perPage);
        // alert(response.message);
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState(null);
  const [fileKey, setFileKey] = useState('');
  const [fileCordinates, setFileCordinates] = useState();
  const [hasImage, sethasImage] = useState(0);

  const validateImage = async () => {
    var validationmessage = ((hasImage == 0 || hasImage == undefined) && value == "category") ? (`Please upload ${value} image`) : true;
    // var validationmessage = (hasImage == 0 || hasImage == undefined) ? (`Please upload ${value} image`) : true;
    return  validationmessage;
  };

  function removeImage() {
    setFile();
    setFilePath(null);
    sethasImage(0);
  }

  function handleFileUpload(e) {
    if (e.target && e.target.files[0]) {
      setFile(e.target.files[0]);
      // console.log(e.target.files[0]);
      setFilePath(URL.createObjectURL(e.target.files[0]));
      sethasImage(1);
      setImageUploaded(true);
      setSubmitDisabled(true);
      validateImage();
    }
  }

  // const FileUpload = async (categoryKey) => {
  //   console.log("file");
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   try {
  //     // console.log(formData);
  //     const response = await MakeFileUpload(
  //       "post",
  //       formData,
  //       `category/${categoryKey}/uploadimage`
  //     );

  //     // console.log(response);
  //     // }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  useEffect(() => {
    sethasImage(0);
  }, [])

  const clearData=()=>{
    setFile();
    setImageUploaded(false);
    setFileKey(null)
    setFileCordinates(null)
  }

  useEffect(() => {
    clearData();
    setFilePath(categoryDetails.image_path || '');
    // sethasImage(categoryKey ? 0 : categoryDetails.hasImage); // subcategory img
    sethasImage(categoryDetails.hasImage);
    reset({
      title: categoryDetails.title || "",
      image_path: categoryDetails.image_path || "",
    });
    return () => {
      clearData();
      setFilePath(null);
      sethasImage(0);
      reset();
    };
  }, [showEdit, isEdit, categoryDetails]);

  const fileRef = useRef(null);
  const openFile = () => {
    fileRef.current.click();
  };

  // const croppedImage = (image) => {
  //   console.log(image);

  //   setFilePath(image);
  //   setImageUploaded(false);
  //   setFile(image);
  //   setSubmitDisabled(false);
  //   validateImage();
  // };

  const croppedImage = (imgPath,tempKey,cordinates) => {
    console.log(imgPath);
    setFile(null);
    setFilePath(imgPath);
    setImageUploaded(false);
    setSubmitDisabled(false);
    setFileKey(tempKey);
    setFileCordinates(cordinates || null);
    validateImage();
  };

  return (
    <>
      <Modal
        show={showEdit}
        // onHide={handleCloseEdit}
        dialogClassName="modal-dialog modal-dialog-centered modal-md"
      >
        <form className="row" onSubmit={handleSubmit(onSubmit)}>
          <div className="body">
          <div className="modal-body">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="text-center">
                  <h5 className="">
                    {isEdit ? `Edit ${value}` : `Create ${value}`}
                  </h5>
                  <p className="gray">
                    {isEdit
                      ? `Edit service ${value}`
                      : `Create service ${value}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-3">
              {!isImageUploaded && (
                <>
                  <input
                    style={{ display: "none" }}
                    ref={fileRef}
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={handleFileUpload}
                  />

                  {hasImage == "1" ? (
                    <>
                    <div className="services-imgs modal-category-img mx-auto">
                      <img className="" src={filePath} />
                      <a href="javascript:void(0)" className="close-btn">
                        
                          <i
                            className="fa-regular fa-circle-xmark"
                            onClick={removeImage}
                          ></i>
                      </a>
                      
                      </div>
                    </>
                  ) : (
                    <a  href="javascript:void(0)"
                      className="upload-img-sec w-100"
                      onClick={() => {
                        openFile();
                      }}
                    >
                      <div className="upload-img">
                        <img src={addImg} />
                        <h6 className="mt-3 mb-0"> {categoryKey ? "Add a subcategory image" : "Add a category image"}</h6>
                        <span className="small gray">
                          Recommended Size :200px x 150px
                        </span>
                      </div>
                    </a>
                  )}
                </>
              )}
              {isImageUploaded && (
                <ImageCropper
                file={file}
                onCropped={croppedImage}
                aspectRatio={1/1}
                width='200'
                height='200'
              />

                // <ImageCropper
                //   imageSrc={filePath}
                //   onCropped={croppedImage}
                //   aspectRatio={4/3}
                //   width='200'
                //   height='150'
                // />
              )}
              <input
                style={{ display: "none" }}
                type="text"
                value={hasImage}
                className="form-control"
                id="image"
                placeholder=""
                {...register("image", { validate: validateImage })}
              />


              {/* Display validation error if any */}
              {errors.image && hasImage != 1 && (
                <span className="error">{errors.image.message}</span>
              )}
              <div className="d-flex align-items-center mb-2 mt-3">

                <input
                  type="title"
                  className="form-control"
                  id="inputEmail4"

                  placeholder={categoryKey ? "Subcategory Name" : "Category Name"}
                  {...register("title", {
                    required: `Please enter ${value} name`,
                    validate: {
                      nameExist: async (fieldValue) => {
                        const response = await MakeAxiosRequest(
                          "post",
                          { title: fieldValue,type: value,id: categoryDetails?.id },
                          "/checkcategoryexist"
                        );
                        // console.log(response.data.hasData);
                        return response.data.hasData == 0 || `${value} name already exist`;
                      },
                    },
                  })}
                />
              </div>
              {errors.title && (
                <span className="error">{errors.title.message}</span>
              )}
            </div>
          </div>

          <div className="modal-footer b-0">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-bs-dismiss="modal"
              onClick={handleCloseEdit}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitDisabled}
            >
              {isEdit ? `Update` : `Create`}
            </button>
          </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default CreateEditModal;
