import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../Navbar/Navbar'

function Layout({ children }) {
    

  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
 };

  useEffect(() => {
    if (isSidebarVisible) {
       document.body.classList.remove('sidebar-toggled');
    } else {
       document.body.classList.add('sidebar-toggled');
    }
 }, [isSidebarVisible]);

  useEffect(() => {
    setSidebarVisible(window.innerWidth>=768);
  },[window.innerWidth]);

  const handleClickOutside = (event) => {
    if ((!event.target.closest(".sidebar")) && (window.innerWidth<768)) {
      // alert("click");
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
        <div id="wrapper" className="wrapper">
          <Sidebar {...{toggleSidebar,isSidebarVisible}}/>

            <div id="content-wrapper" className="d-flex flex-column content-wrapper">
            {/* Main Content */}
                <div id="content" className="content">
                    <Navbar {...{toggleSidebar,isSidebarVisible}}/>
                    <div className="container-fluid">
                        {children}

                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default Layout