import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';


function DateField(props) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={props.className}>
      <label htmlFor={props.name} className="form-label">{props.label}</label>

      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        rules={props.rules}
        render={({ field }) => (
          <input {...field} type="date"  className="form-control" min={props.minDate}/>
        )}
      />

      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default DateField;
