import React, {useState, useEffect} from 'react';
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';


const Reports = ({ reportData ,handlePageChange}) => {

  const totalCount = reportData?.totalcount;
  const [isTableLoaded, setIsLoaded] = useState(true);
  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "InActive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    handlePageChange('reports',newOffset);      
  };
 // const   isloading  = (totalCount !== undefined) ? false : true;
    useEffect(() => {    
      fetchData();
    }, [totalCount]);

    const fetchData =() =>{   
     setIsLoaded(totalCount !== undefined);    
    };

 

  const columns = [
    {
    
      name: <div className="datatable-hd"><h6>Issue Type</h6></div>,
      selector: row => ( <div data-title="" className="numeric data-table-sec" style={{ width: '200px' }}> <div><p>{row.issue_type} </p> </div>  </div> ),
  
  },
    {
    
      name: <div className="datatable-hd"><h6>Description</h6></div>,
      selector: row => ( <div data-title="" className="numeric data-table-sec" style={{ width: '200px' }}> <div><p>{row.description} </p> </div>  </div> ),
  
  },
  {
    name: <div className="datatable-hd"><h6>Status</h6></div>,
    selector: row => (
      <div data-title="" className="numeric data-table-sec">
        {statusOptions.map(option => {
          return row.status_id === option.value && (
            <span key={option.value} className={option.className}>{option.label}</span>
          );
        })}
     </div>
    ),
  },
  
    {
      name: <div className="datatable-hd"><h6>Created On</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
        <p>{format(new Date(row.created_at), 'MM/dd/Y')}</p>
      
      </div>),   
  },
  
 
    
    
  
      
  ];

  return (
    <>
     <div class="tab-pane fade show active" id="reports-tab-pane" role="tabpanel" aria-labelledby="reports-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
        <div class="col-12 mb-3 text-center text-lg-start">
          <h4 class="mt-3">Reported Issues</h4>
          <p class="mb-0">You can view all the Reported Issues here</p>
        </div>
        </div>
        <div className="">
          <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}   tableData={reportData?.businessReports}  type ={'reported issues'} isTableLoaded={isTableLoaded} />           
        </div>      
      </div>
    </>
  );
};

export default Reports;
