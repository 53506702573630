import React, {useState, useEffect} from 'react';

import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../Assets/Images/logo.png';



function Listing() {


    
 
    return (<>
    
  
        <table className='' style={{ width: "100%" }}>
          <tr>
            <td colSpan={2} className='' style={{ textAlign: "center", paddingBottom: "50px" }}>
              <img src={logo} class="logo-aldz" style={{paddingBottom: "10px" }}  alt="Logo" />
              <h4 style={{ fontSize: "1.5rem" }}>Payment Receipt</h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{width: "100%", paddingBottom: "30px"}}>
              <table style={{width: "100%"}}>
                  <tr>
                    <td colSpan={2} className='' style={{width: "100%", paddingBottom: "20px" }}>
                      <h4 style={{ fontSize: "1.25rem", color: "#f87416", borderBottom:"1px solid #f87416", paddingBottom:'20px' }}>Payment Receipt</h4>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Transaction Type</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p>Transaction Date</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p>Paid By</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p style={{fontSize:'1.25rem', fontWeight: '800'}}>Amount</p></td>
                    <td style={{ textAlign: "end"}}><p style={{fontSize:'1.25rem', fontWeight: '800'}}>gfghfdghfdghfdgh</p> </td>
                  </tr>
                </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{width: "100%", paddingBottom: "30px"}}>
              <table style={{width: "100%"}}>
                  <tr>
                    <td colSpan={2} className='' style={{width: "100%", paddingBottom: "20px" }}>
                      <h4 style={{ fontSize: "1.25rem", color: "#f87416", borderBottom:"1px solid #f87416", paddingBottom:'20px' }}>Billing Info</h4>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Receipt ID</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p>Email</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p>Address</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                  <tr>
                    <td><p>Phone Number</p></td>
                    <td style={{ textAlign: "end"}}><p>gfghfdghfdghfdgh</p> </td>
                  </tr>
                </table>
            </td>
          </tr>
        
          <tr>
            
          </tr>
        </table>
      
           
      </>
    )
}

export default Listing;
