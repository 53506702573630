import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import Swal from "sweetalert2";
import DropzoneField from "../../Components/Inputs/DropzoneField";
import FormFields from "../../Components/Inputs/FormFields";

function AddGallery({ show, handleClose, businessKey, fetchData }) {
    
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(true);
  const [filesKey, setFilesKey] = useState([]);

  const methods = useForm();

  const { handleSubmit, reset, register, setValue,trigger } = methods;

  const create = async (data) => {
    // console.log(data);
    setSubmitDisabled(true);
    setShowCancelBtn(false)
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data, filesKey },
        `/business/${businessKey}/galleryimages/add`
      );
      // console.log(response);
      if (response.status == 1) {
        // image && (await FileUpload(`service/${response.servicedets.service_uuid}/uploadimage`,image));
        reset();
        handleClose();
        fetchData(1);
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  useEffect(() => {
    setSubmitDisabled(false);
    setShowCancelBtn(true);
    setFilesKey([]);
    reset;
    return () => {
      setSubmitDisabled(false);
      setShowCancelBtn(true);
      setFilesKey([]);
      reset();
    };
  }, [show]);
  
  const fields = [
    {
      name: "image",
      type: "dropzone",
      className: "col-md-12 mb-3",
      title:"Drag images/videos",
      subtitle: "Recommended video time limit : 30sec",
      dropzoneMsg: "Warning! Not supported file type",
      acceptedFileTypes:{"image/*": [],"video/*": []},
      rules: {
        validate: {
          validateImg: async () => {
            return filesKey.length >= 1 || "Please upload image/video";
          },
        },
      },
      hideRemovebtn: !showCancelBtn,
      filesKey: filesKey,
      setFilesKey: setFilesKey,
      setSubmitDisabled:setSubmitDisabled,
      isShow: true,
    },
  ];

  return (
    <>
      <Modal
        show={show}
        // onHide={handleCloseCustom}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(create)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="text-center">
                    <h5>Add Media</h5>
                    <p className="px-2">Please upload image/video</p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <FormFields fields={fields}/>
              </div>
            </div>
            <div className="modal-footer b-0">
              {showCancelBtn && 
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              }
              
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitDisabled}
              >
                {/* {isSubmitDisabled ? 'Loading':'Add'} */}
                Add
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}

export default AddGallery;
