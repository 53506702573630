import { placeholder } from '@babel/types';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import CurrencyInput from "react-currency-input-field";


function AmountField(props) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={props.className}>
      {props.label && <label htmlFor={props.name} className="form-label">{props.label}</label> }
       

      {props.icon ? (
          <div className="input-group icon-form-lft">
            <span className="input-group-text" id="basic-addon1">{props.icon}</span>
            <Controller
          name={props.name}
          control={control}
          defaultValue="0"
          rules={props.rules}
          render={({ field }) => (
            <CurrencyInput
              prefix={props.prefix}
              name="currencyInput"
              id="currencyInput"
              value={props.value}
              placeholder=""
              onChange={props.onChange}
              onBlur={props.onBlur}
              className="form-control"
            />
          )}
        />
          </div>
        ) : 
        
        <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <CurrencyInput
              prefix={props.prefix}
              name="currencyInput"
              id="currencyInput"
              value={props.value}
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleOnBlur}
              allowDecimals
              decimalsLimit="2"
              disableAbbreviations
              className="form-control"
            />
          )}
        />
        
        
        }

        

      

      {errors[props.name] && props.value == 0 && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default AmountField;
