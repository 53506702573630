import React, {useState , useEffect,useRef} from 'react'; 
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { MakeAxiosRequest } from '../../utils/handler';
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import NoDataFound from "../../Components/Listing/NoDataFound";
import { Link, useParams } from "react-router-dom";
import RenewSubscription from "./RenewSubscription";
import CreateEditModal from "../Users/CreateEditModal";

function Subscriptions({ subscriptions,handlePageChange,handleSelectChange,viewTab }) {
  const formRef = useRef(null); 
  const params = useParams();
  const businessKey = params.key;
  const [showEdit, setshowEdit] = useState(false);
  const [subscriptionDetails, setsubscriptionDetails] = useState({});
  const [show, setShow] = useState(false);

  const handleshowEdit = (subscription) => {
    setsubscriptionDetails(subscription);
    setShow(true);
    // console.log(user);
  };
  const handleCloseEdit = () => {
    setsubscriptionDetails({})
    setShow(false);
  }
  const columns = [
    {
      name: (
        <div className="datatable-hd" style={{ width: "200px" }}>
          <h6>Subscription Plan</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec" style={{ width: "200px" }}>
            <div className="profile-sec">
              <div>
                <p>{row.subscription_name}</p>
              </div>
            </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Amount</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec" >
          <div>
            <p>₹{row.amount} </p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd" >
          <h6>Transaction Note</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec" >
            <div className="profile-sec">
              <div>
                <p className={` `}><span className={`trans_details_${row.subscription_uuid}`}>{row.transaction_details}</span> </p>
              </div>
            </div>
        </div>
      ),
    },

    {
      name: (
        <div className="datatable-hd">
          <h6>Start date</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.start_date), "dd/MM/y")}</p>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>End date</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.end_date), "dd/MM/y")}</p>
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <span className={row.status==='Active' ? 'status-tag status-tag-active' : 'status-tag status-tag-inactive' }>{row.status}</span>
       </div>
      ),
    }, 
    {
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end"> 
              <li><a href='javascript:void(0)' className="dropdown-item danger" onClick={() => handleshowEdit(row)} ><i className="fa-solid fa-pen me-2"></i>Edit</a></li>           
              </ul>
            </div>
          </div>
        </div>
      ),
    } 
  ];

  
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });

  const totalCount = subscriptions?.totalcount;
  const [isTableLoaded, setIsLoaded] = useState(true);

   useEffect(() => {    
    fetchData();
  }, [totalCount]);

  const fetchData =(type='') =>{   
   setIsLoaded(totalCount !== undefined); 
   if(type==1){
    handleFilterChange(filterData);
   }
  
  }; 

  
  const handleFilterChange = (type, value) => {
    handleSelectChange('subscriptions',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
  console.log(pageNumber)
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('subscriptions',newOffset,type,value);   
    setCurrentPage(newOffset);
  };



  const handleShow = () => {
    setsubscriptionDetails(false)
    setShow(true);
  };

  const handleClose = () => {
    setsubscriptionDetails({})
    setShow(false);
  };

  const gettransactiondata = async (transactionData) => {
    formRef.current.reset();
   
    $('#transaction-modal').modal('show');   
   $("#transaction_note").val($('.trans_details_'+transactionData.subscription_uuid).html());
   // $("#transaction_note").val(transactionData.transaction_details); 
    $("#subscriptionKey").val(transactionData.subscription_uuid); 
   
  };

  const {
    register,
    handleSubmit,
    formState: { errors }, 
    reset 
  } = useForm();


  const updateTranNote = async () => {
    try {
      const transaction_note = $("#transaction_note").val();
      const subscriptionKey = $("#subscriptionKey").val();
       const response = await MakeAxiosRequest("post", {"transaction_note":transaction_note}, `/business/${subscriptionKey}/transactionnote/update`);
      
       if (response.status===1) {
        Swal.fire({icon: 'success',  title: 'Success!', customClass: {  confirmButton: 'btn btn-primary'   },
          text: 'Updated successfully'
        });  
        $('#transaction-modal').modal('hide'); 
           $('.trans_details_'+subscriptionKey).html(transaction_note);
          
           //fetchData(1);   
       //fetchData();         
      }else{
        Swal.fire({ icon: 'error', title: 'Error!',   customClass: {  confirmButton: 'btn btn-primary' }, text:'failed to update'  }); 
      }
    } catch (error) {     
      console.error(' error:', error);
    }
  };

  return (
    <>
    <div
      className="tab-pane fade show active"
      id="reviews-tab-pane"
      role="tabpanel"
      aria-labelledby="review-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-8 mb-3 text-center text-lg-start">
          <h4 className="mb-0">Subscriptions</h4>
          <p>Details of the Subscriptions by this business will be displayed here.</p>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
            {subscriptions?.filters?.map((filter, index) => (
              <div className="sort-sec my-2 me-2" key={index}>
                <p className="me-1">{filter.title} : </p>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    handleFilterChange(filter.type, e.target.value)
                  }
                >
                  {filter.options?.map((option) => (
                    <option value={option.value}>{option.option}</option>
                  ))}
                </select>
              </div>
            ))}
            {/* Button trigger modal */}
            
            <button
              type="button"
              className="btn btn-primary my-2"
              onClick={handleShow}
            >
              Renew subscription
            </button>
          </div>
        </div>
      </div>
        <div className="">
          <DataTableList  pageChange={onPageChange}   totalCount={subscriptions.totalCount}  columns={columns}   tableData={subscriptions.subscriptions}  type ={'subscriptions'} isTableLoaded={isTableLoaded} />           
        </div> 
     
      {show && <RenewSubscription {...{ show, handleClose, businessKey, fetchData, viewTab ,subscriptionDetails}} />}
    
    </div>

<div class="modal fade" id="transaction-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mt-4">
            <div class="text-center">
              <h5>Edit Transaction Note</h5>
            
            </div>                            
        </div>
      </div>
      <form class="row mt-4" ref={formRef}    onSubmit={handleSubmit(updateTranNote)}>
         <input type="hidden"  id="subscriptionKey"/>
          <div class="col-md-12 col-lg-12">
            <div class="row ">             
              <div class="col-12 mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Transaction Note</label>
                <textarea
                    class="form-control"
                    id="transaction_note"
                    name="transaction_note"
                    rows="3"
                    {...register("transaction_note", {
                      required: "Please enter the transaction notes.",
                      minLength: {
                        value: 5,
                        message: "Transaction must be at least 5 characters long.",
                      },
                    })}
                  />
                {errors.transaction_note && (
                  <span className="error">{errors.transaction_note.message}</span>
                )}

              </div>
            </div>
            
          </div>
          <div class="modal-footer b-0">
            <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary" >Submit</button>
          </div>
      </form>
    </div>
  
  </div>
</div>
</div>
</>
  );
}

export default Subscriptions;
