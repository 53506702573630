import React, { useEffect, useState } from 'react'
import logo from '../../Assets/Images/logo.png'
import { sidebarData } from '../../Constants'
import SidebarItems from './SidebarItems'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forEach } from 'lodash';
import { MakeAxiosRequest } from '../../utils/handler';
import {updateCount} from '../../features/authSlice';

function Sidebar({toggleSidebar,isSidebarVisible}) {

    
   const dispatch = useDispatch()
   const user = localStorage.getItem('user');
   const data = JSON.parse(user)
   const staffItems = ['dashboard','agents','users','categories','offers','businesses','subscriptions','reportedissues','reviews'];
   
   const fetchData = async () => {
    try {
      const res = await MakeAxiosRequest("post", {}, "/pendingcount");
      dispatch(updateCount(res.data));    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 10000); // 10 seconds    
    return () => clearInterval(fetchDataInterval);
  }, []);
 
   
   const [sideBarMenu, setSideBarMenu] = useState([]);

   useEffect(() => {
      if (data.user_role == '1') {
        setSideBarMenu(sidebarData);
      } else {
        // Filter out items not in staffItems
        const filteredSidebarData = sidebarData.filter(item => staffItems.includes(item.type));
    
        // Update the state with the filtered data
        setSideBarMenu(filteredSidebarData);
      }
    }, [data, sidebarData, staffItems]);
    
   
   const classes = `navbar-nav bg-gradient-primary sidebar sidebar-dark accordion justify-content-between ${!isSidebarVisible ? 'toggled' : ''} ${window.innerWidth >= 767 ? 'greater' : 'less'}`;

  return (
    <>
      <div className={classes} id="accordionSidebar">
         <div className="left-nav">
            {/* Sidebar - Brand */}
            <Link className="sidebar-logo" to="/dashboard">
            <img src={logo} /> 
            </Link>
            <ul className="side-menu">
               
              {sideBarMenu?.map( (item,index) => {
                return <SidebarItems item={item} key={index}/>
                
              })}
             
            </ul>
         </div>
         <a href='#' onClick={toggleSidebar} className='nav-close'><i class="fa-solid fa-xmark"></i></a>
         <div>
            <ul>
                {/* Sidebar Toggler (Sidebar) */}
               <li className="nav-item d-none d-md-inline toggle-item" onClick={toggleSidebar}>
                  <a className="nav-link mb-2" href="#" id="sidebarToggle">
                     <span className="material-symbols-outlined"> close_fullscreen </span>
                     <p>Toggle Sidenav</p>
                  </a>
               </li>
               
            </ul>
         </div>
      </div>
    </>
  )
}

export default Sidebar