import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import CategoryListing from './Listing';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';

function Category() {

    
  const [searchValue, setsearchValue] = useState('');
  const params = useParams();
  const categoryKey = params.key;
  useEffect(() => {
    setsearchValue('');
  }, [categoryKey])
  
  return (
    <>
      <div className="page">
        {/* Content Row */}
        <div className="row align-items-center">
          <div className="col-10 col-lg-7">
          <div className="d-flex align-items-center">
            {categoryKey ? <Link to={`/categories`}  className="back-btn"><i class="fa-solid fa-circle-arrow-left me-1 me-sm-3"></i></Link> : ''}
          
            <Breadcrumbs />
            </div>
          </div>
          <div className="col-2 col-lg-5">
            <ul className="top-search">
              <li className="d-none d-lg-block">
                <form className=" form-inline  ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    <div className="input-group-append">
                      <button className="btn" type="button">
                        <i className="fas fa-search fa-sm"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 small"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2" value={searchValue} onChange={(e) => setsearchValue(e.target.value)}  
                        onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </form>
              </li>
              <li className="dropdown no-arrow d-lg-none">
                <div className="btn-group search-drpdwn">
                  <a
                    href=""
                    className="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-expanded="false"
                  >
                    <span className="material-symbols-outlined">search</span>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <form className="d-inline-block form-inline  ml-md-3 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                          <div className="input-group-append">
                            <button className="btn" type="button">
                              <i className="fas fa-search fa-sm"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control border-0 small"
                            placeholder="Search for.."
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            value={searchValue} 
                            onChange={(e) => setsearchValue(e.target.value)}  
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </form>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">


                <CategoryListing {...{searchValue,setsearchValue}}/>



              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Category