import React, { useEffect, useState } from "react";
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import CreateEditModal from "../Users/CreateEditModal";
import Swal from "sweetalert2";
import { MakeAxiosRequest } from "../../utils/handler";

function Users({ usersData,perPage,handlePageChange,handleSelectChange }) {

  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "Inactive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];

  const columns = [
    {
      name: (
        <div className="datatable-hd" style={{ width: "150px" }} >
          <h6>Name</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec" style={{ width: "150px" }} >
            <Link to={row.status !== '2' ? `/user/${row.user_uuid}` :  "javascript:void(0)" }>
            <div className="profile-sec">
              <img src={row.image} alt="" />
              <div>
                <p>{row.first_name} {row.last_name}</p>
              </div>
            </div>
            </Link>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd" style={{ width: "150px" }}>
          <h6>Email</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "150px" }}
        >
          <div>
            <p>{row.email || '--' } </p>
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="datatable-hd">
          <h6>Phone</h6>
        </div>
      ),
      selector: (row) => (
        <div
          data-title=""
          className="numeric data-table-sec"
          style={{ width: "150px" }}
        >
          <div>
            <p>{row.phone_number} </p>
          </div>
        </div>
      ),
    },

    {
      name: (
        <div className="datatable-hd">
          <h6>Joined</h6>
        </div>
      ),
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{format(new Date(row.created_at), "dd/MM/y")}</p>
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {statusOptions.map(option => {
            return row.status === option.value && (
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })}
       </div>
      ),
    },
    {
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          <div className="d-flex justify-content-end">
            <div className="btn-group option-btn">
              <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                <i className="fa-solid fa-ellipsis"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-end"> 
              <li><a href='javascript:void(0)' className="dropdown-item danger" onClick={() => handleshowEdit(row)} ><i className="fa-solid fa-pen me-2"></i>Edit</a></li>           
          
              {(() => {
                switch (row.status) {
                  case "0":
                    return (
                      <>
                       <li><Link to={`/user/${row.user_uuid}`} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</Link></li>
                      <li><a href='javascript:void(0)' className="dropdown-item success" onClick={() => changeStatus(row.user_uuid,'activate')}><i className="fa-solid  fa-square-check me-2"></i>Activate</a></li>
                      </>
                    );
                  case "1":
                    return (
                      <>
                       <li><Link to={`/user/${row.user_uuid}`} className="dropdown-item"><i className="fa-solid fa-eye me-2"></i>View</Link></li>
                        <li><a href='javascript:void(0)'  className="dropdown-item reject" onClick={() => changeStatus(row.user_uuid,'deactivate')} ><i className="fa-regular fa-trash-can me-2"></i>Deactivate</a></li>
                      </>
                    );
                  case "2":
                    return (
                      <>
                        <li><a href='javascript:void(0)'  className="dropdown-item reject" onClick={() => changeStatus(row.user_uuid,'delete')} ><i className="fa-regular fa-trash-can me-2"></i>Delete</a></li>
                      </>
                    );
                  default:
                    return null;
                }
              })()}
              </ul>
            </div>
          </div>
        </div>
      ),
    } 
  ];



  const changeStatus = async (userKey,status) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to '+status+' this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, '+status,
        cancelButtonText: 'Cancel',
        customClass: {  confirmButton: 'btn btn-primary' },
        reverseButtons: true,
        preConfirm: async () => {
          try {
            const res = await MakeAxiosRequest(  'post', {"status":status}, `user/${userKey}/changestatus` );      
            if (res.status === 1) {
              return true;
            } else {
              throw new Error(res.message);
            }
          } catch (error) {
            console.error(error); // Log the error
            Swal.fire({
              icon: 'warning',
              title: 'Request Failed!',
              customClass: { confirmButton: 'btn btn-primary' },
              text: "Failed to change the status.",
            });
            return false; 
          }
        },

      });    
      if (result.isConfirmed && result.value === true) {       
        Swal.fire({   icon: "success",    title: "Success!",  customClass: { confirmButton: "btn btn-primary",  },
          text: "User " + status + "d successfully.",
        });
        handleFilterChange(filterData);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        customClass: { confirmButton: 'btn btn-primary' },
        text: 'An error occurred while changing the status.',
      });
    }





  }
  
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });
  const totalCount = usersData?.totalcount;
  const [isTableLoaded, setIsLoaded] = useState(true);



  const fetchData =(type='') =>{   
    setIsLoaded(totalCount !== undefined);  
    if(type===1){
      handleFilterChange(filterData);
    }      
   
  };

  useEffect(() => {    
    fetchData();
  }, [totalCount]);

  const handleFilterChange = (type, value) => {
    handleSelectChange('users',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('users',newOffset,type,value);   
    setCurrentPage(newOffset);
  };
  
  const [showEdit, setshowEdit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [userDetails, setuserDetails] = useState({});

  const handleShow = () => {
    setisEdit(false)
    setshowEdit(true)
    setuserDetails({})
    console.log(userDetails);
  };


  const handleshowEdit = (user) => {
    setisEdit(true)
    setshowEdit(true)
    setuserDetails(user)
    // console.log(user);
  };
  const handleCloseEdit = () => {
    setshowEdit(false);
    setisEdit(false)
    setuserDetails({})
  }
  return (
    <>
    <div
      className="tab-pane fade show active"
      id="users-tab-pane"
      role="tabpanel"
      aria-labelledby="users-tab"
      tabIndex="0"
    >
      <div className="row d-flex align-items-center justify-content-center flex-wrap ">
        <div className="col-lg-7 mb-3 text-center text-lg-start">
          <h4 className="mb-0">User</h4>
          <p class="mb-0">  Users associated with this business is displayed here. </p>
        </div>
        <div className="col-lg-5 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
                {usersData?.filters?.map((filter, index) => (
                  <div className="sort-sec my-2 me-2" key={index}>
                    <p className="me-1">{filter.title} : </p>
                    <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                      {filter.options?.map( option => 
                        <option value={option.value} >{option.option}</option>
                      )}                        
                    </select>
                  </div>
                ))}
                {usersData && usersData!='' && (
                  <button
                    type="button"
                    className="btn btn-primary my-2"
                    onClick={handleShow} >  Add User  </button>
                )}

          </div>
        </div>
      </div>
      <div className="">
          <DataTableList  pageChange={onPageChange}   totalCount={usersData.totalCount}  columns={columns}   tableData={usersData.businessusers}  type ={'users'} isTableLoaded={isTableLoaded} />           
        </div> 
    
        {showEdit && <CreateEditModal {...{ showEdit, handleCloseEdit,isEdit, userDetails,fetchData,perPage }} businessId={usersData?.businessdets?.id} />}
    </div>
    </>
  );
}

export default Users;
