import React, {useState , useEffect} from 'react'; 
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import { format } from 'date-fns';
import dummyBusiness from "../../Assets/Images/business-img.png";
import StarRating from '../../Components/Listing/StarRating';
import loaderFile   from '../../Assets/Images/loader.gif';
const ListingComponent = ({ reviewData,handlePageChange,handleSelectChange }) => {
  const statusOptions = [
    { value: "0", className: "status-tag status-tag-inactive", label: "InActive" },
    { value: "1", className: "status-tag status-tag-active", label: "Active" },
    { value: "2", className: "status-tag status-tag-pending", label: "Pending" },
  ];

  const [isTableLoaded, setisTableLoaded] = useState(true);

  //const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({ type: 'sortBy', value: 'newest' });

  const userReviews = reviewData?.userReviews || [];
  const totalCount = reviewData?.totalcount;
  const filters = reviewData?.filters || []; 

  //const   isloading  = (totalCount !== undefined) ? false : true;

  const fetchData = () => {
    setisTableLoaded(totalCount !== undefined);
   
  };
  
  useEffect(() => {
    fetchData();
  }, [totalCount]);
  
  

  const onPageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) ;
    const type=filterData.type;
    const value=filterData.value; 
    handlePageChange('reviews',newOffset,type,value);   
    setCurrentPage(newOffset);  
  };

  const handleFilterChange = (type, value) => {    
    handleSelectChange('reviews',type,value,currentPage);   
    setFilterData({ type, value });
  };

  const columns = [

    {
      name: <div className="datatable-hd"><h6>Business Name</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          
            <div className="profile-sec">
              <img src={row.business_image ||  dummyBusiness} alt="" />
              <div>
              <p> {`${row.business_name}`} </p>
  
               
              </div>
            </div>
         
        </div>
      ),
    },

    {
      name: <div className="datatable-hd"><h6>Reviewer Name</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          
            <div className="profile-sec">
              <img src={row.reviewer_image} alt="" />
              <div> <p> {`${row.reviewer_name}`} </p> </div>
            </div>
         
        </div>
      ),
    },
 
  


    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {statusOptions.map(option => {
            return row.status === option.value && (
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })}
       </div>
      ),
    },

      {
    
        name: <div className="datatable-hd"><h6>Rating and Review</h6></div>,
        selector: row => ( <div data-title="" className="numeric data-table-sec" style={{ width: '200px' }}> 
        
           <StarRating count={row.rating}/>
            <p>{row.comment}.</p>
         </div> ),
    
        },
  
    {
      name: <div className="datatable-hd"><h6>Reiewed On</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
        <p>{format(new Date(row.created_at), 'dd/MM/y')}</p>
      
      </div>),   
  },
  
 
    
    
  
      
  ];



  return (
    <>
     <div class="tab-pane fade show active" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabindex="0">
      <div class="row d-flex align-items-center justify-content-center flex-wrap ">
            <div class="col-lg-8 mb-3  text-lg-start">
              <h4 class="mt-3">Reviews</h4>
              <p class="mb-0">You can view all the reviews you have approved/rejected on aladinz </p>
            </div>
           <div class="col-lg-4 mb-3">
                 <div class="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap">
                 {filters?.map((filter, index) => (
                    <div className="sort-sec my-2 me-2" key={index}>
                      <p className="me-1">{filter.title} : </p>
                      <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleFilterChange(filter.type, e.target.value)}  >
                        {filter.options?.map( option => 
                          <option value={option.value} >{option.option}</option>
                        )}                        
                      </select>
                    </div>
                  ))}        
                 </div>
          </div>
        </div>
        <div className="">
        <DataTableList  pageChange={onPageChange}   totalCount={totalCount}  columns={columns}    tableData={userReviews}  type ={'reviews'}  isTableLoaded={isTableLoaded}/>  
        
        </div>
      </div>
    </>
  );
};


export default ListingComponent;
