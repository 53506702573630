import React, { useEffect, useState } from "react";
import EditService from "./EditService";
import AddService from "./AddService";
import { useParams } from "react-router";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Import 'chart.js/auto' instead of 'chart.js'
import "chartjs-adapter-date-fns"; // Import the date-fns adapter
import ListPagination from "../../Components/Listing/ListPagination";
import loaderFile from "../../Assets/Images/loader.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Swal from "sweetalert2";
import { MakeAxiosRequest } from "../../utils/handler";
import NoDataFound from "../../Components/Listing/NoDataFound";
import { isEmpty } from "lodash";

function Overview({ overviewdata, handlePageChange, handleSelectChange,viewTab }) {
  const params = useParams();
  const businessKey = params.key;
  const [show, setShow] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [serviceLoading, setServiceLoading] = useState(false);

  const handleShowCreate = () => {
    setServiceData(null);
    setShow(true);
  };

  const handleClose = () => {
    setServiceData(null);
    setShow(false);
  };
  const handleShowEdit = (data) => {
    setServiceData(data);
    setShow(true);
  };


  const graphData = overviewdata.overviewarr?.graphdets  || [];

  const months = graphData.map(item => item.month);
  const counts = graphData.map(item => item.count);

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Profile views',
        data: counts,
        fill: true,
        backgroundColor: '#df7171', // Light red fill color with opacity
        borderColor: '#f22727', // Red border color
        borderWidth: 2,
        pointBackgroundColor: '#fff', // Purple points
        pointRadius: 4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category', 
        labels: months,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const fetchData = () => {
    handleFilterChange(filterData);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    type: "sortBy",
    value: "newest",
  });

  const handleFilterChange = (type, value) => {
    handleSelectChange("overview", type, value, currentPage);
    setFilterData({ type, value });
  };

  // const onPageChange = (pageNumber) => {
  //   const newOffset = pageNumber - 1;
  //   const type = filterData.type;
  //   const value = filterData.value;
  //   handlePageChange("overview", newOffset, type, value);
  //   setCurrentPage(newOffset);
  // };

  const [activeSlide, setActiveSlide] = useState(0);

  const handlePageClick = async (event) => {
    setServiceLoading(true);
    const newOffset = event.selected;
    const type = filterData.type;
    const value = filterData.value;
    await handlePageChange("overview", newOffset, type, value);
    setCurrentPage(newOffset);
    setServiceLoading(false);
  };

  const removeService = async (serviceKey) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to remove this service?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `/business/${businessKey}/service/${serviceKey}/destroy`
        );
        console.log(res);
        if (res.status === 1) {
          console.log("1");
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: res.message,
          });
          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: res.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred",
      });
    }
  };

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => { 
    setisLoading(isEmpty(overviewdata));
  }, [overviewdata]);

  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlayPauseVideo = (index) => {
    if (playingIndex === index) {
      const video = document.getElementById(`video-${index}`);
      if (video) {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
          setPlayingIndex(null);
        }
      }
    } else {
      // Pause all other videos
      document.querySelectorAll('video').forEach((videoElement) => {
        if (!videoElement.paused) {
          videoElement.pause();
        }
      });

      setPlayingIndex(index);
      const video = document.getElementById(`video-${index}`);
      if (video) {
        video.play();
      }
    }
  };

  return (
    <>
    {isLoading ? (
       <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>
      ) : (
    <div
      className="tab-pane fade show active"
      id="overview-tab-pane"
      role="tabpanel"
      aria-labelledby="overview-tab"
      tabIndex="0"
    >
      <div className="row">
        <div className="col-12 pt-4">
          <div className="overview-sec">
            <h4 className="mb-3">Overview</h4>
            <div className="row">
              <div className="col-sm-6 col-xl-3 mb-3">
                <div className="card ovrview-cards follow-card">
                  <div className="card-body">
                    <p>Followers</p>
                    <h3>{overviewdata.overviewarr?.followersCount || 0}</h3>
                    <span>Total followers
                      {/* {overviewdata.overviewarr?.lastMonthFollowersCount || 0}
                      new followers */}
                    </span> 
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-3">
                <div className="card ovrview-cards profile-card">
                  <div className="card-body">
                    <p>Profile Views</p>
                    <h3>
                      {overviewdata.overviewarr?.businessdets
                        ?.no_of_profile_views || 0}
                    </h3>
                    <span>Past 30 days</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-3">
                <div className="card ovrview-cards whatsapp-card">
                  <div className="card-body">
                    <p>WhatsApp Clicks</p>
                    <h3>
                      {overviewdata.overviewarr?.businessdets?.no_of_messages ||
                        0}
                    </h3>
                    <span>Past 30 days</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 mb-3">
                <div className="card ovrview-cards call-card">
                  <div className="card-body">
                    <p>Call Clicks</p>
                    <h3>
                      {overviewdata.overviewarr?.businessdets?.no_of_calls || 0}
                    </h3>
                    <span>Past 30 days</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <div className="card h-100">
                  <div className="card-body service-sec">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <h3 className="mb-0">Services</h3>
                      {/* <button
                        type="button"
                        className="btn btn-primary my-2"
                        onClick={handleShowCreate}
                      >
                        Create
                      </button> */}
                    </div>

                    {overviewdata?.overviewarr?.services.length == 0 ? (
                        <NoDataFound text="services"/>
                    ) : (
                      <>
                        {serviceLoading ? (
                          <div>
                            <center>
                              <img
                                src={loaderFile}
                                style={{ width: "125px" }}
                                alt="Loading..."
                              />
                            </center>
                          </div>
                        ) : (
                          <>
                            {overviewdata?.overviewarr?.services.map(
                              (service, index) => (
                                <div
                                  className="subcribe-list service-list d-flex align-items-center justify-content-between"
                                  key={index}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={service.image}
                                      alt=""
                                      className=""
                                    />
                                    <p>{service.title}</p>
                                  </div>
                                  <div className="option-sec-hover">
                                    <div className="btn-group option-btn">
                                      <a
                                        href="javascript:void(0)"
                                        className="btn dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        data-bs-display="static"
                                        aria-expanded="false"
                                      >
                                        <i className="fa-solid fa-ellipsis"></i>
                                      </a>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleShowEdit(service)
                                            }
                                          >
                                            <i className="fa-solid fa-pen me-2"></i>{" "}
                                            Edit
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item reject"
                                            onClick={() => removeService(service.service_uuid)}
                                          >
                                            <i className="fa-solid fa-trash-can me-2"></i>
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        )}
                        {overviewdata.overviewarr?.servicecount > '5' &&<a href="javascript:void(0)" onClick={()=>viewTab('services')} class="link mb-3 ms-2 d-block">View all services<i class="fa-solid fa-arrow-right-long ms-2"></i></a>}

                        {/* <ListPagination
                          pageChange={handlePageClick}
                          totalCount={overviewdata?.overviewarr?.servicecount}
                          perpage={overviewdata?.overviewarr?.service_pagelimit}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
                <AddService
        {...{ show, handleClose, businessKey, fetchData, serviceData }}
      />
                {/* <AddService
                  {...{show, businessKey, fetchData,serviceData }}
                  show={show}
                  handleClose={handleCloseCreate}
                  serviceData={serviceData}
                />
                <EditService
                  {...{ businessKey, serviceData, fetchData }}
                  show={showEdit}
                  handleClose={handleCloseEdit}
                /> */}
              </div>
              <div className="col-xl-6 mb-3">
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h3 className="mb-0">Profile Views</h3>
                        </div>
                        <div>
                          <div id="area-chart">

                          <Line
                                data={data}
                                height={370} 
                                options={{
                                  ...options,
                                  plugins: {
                                    title: {
                                      display: true,
                                      text: "Note:This graph represents the total number of business profile views in the current year",
                                      position: 'bottom'
                                    },
                                    legend: {
                                      display: true,
                                    },
                                  },
                                  maintainAspectRatio: false, // Disable aspect ratio for responsiveness
                                  scales: {
                                    y: {
                                      suggestedMin: 0, // Minimum value for the y-axis
                                      beginAtZero: true, // Start the y-axis at zero
                                      ticks: {
                                        stepSize: 1
                                        // precision: 0
                                      }
                                    }
                                  }
                                }}
                              />

                           

                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6 mb-2">
                            <div className="card">
                              <div className="card-body dash-counts sbscrb-counts">
                                <h5>{overviewdata?.overviewarr?.businessdets.last_week_profile_views}</h5>
                                <span>Viewed this week </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="card">
                              <div className="card-body dash-counts sbscrb-counts">
                                <h5>{overviewdata?.overviewarr?.businessdets.total_profile_views}</h5>
                                <span>Total profile views</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {overviewdata.overviewarr?.albums?.length > 0 && (

<div class="row">
<div class="col-md-12">
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3 class="mb-0">Gallery</h3>
      </div>
    </div>
        
    <Carousel>
      {overviewdata.overviewarr.albums?.map((image, index) => (
        <div key={index}>
          {image.filetype === "image" && <img src={image.image} alt={`Image ${index}`} />}
          {image.filetype === "video" && (
            <>
              <video id={`video-${index}`} src={image.image} preload="metadata" onEnded={() => setPlayingIndex(null)} >
                Your browser does not support the video tag.
              </video>
              <span className="play-icon" onClick={() => handlePlayPauseVideo(index)}>
                <i className={playingIndex === index ? "fas fa-pause-circle" : "fas fa-play-circle"}></i>
              </span>
            </>
          )}
        </div>
      ))}
    </Carousel>
            <a href="javascript:void(0)" onClick={()=>viewTab('gallery')} class="link my-3 ms-2 d-block">View all gallery images<i class="fa-solid fa-arrow-right-long ms-2"></i></a>

                  </div>
                                        </div>
                                      </div>

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default Overview;
