import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import InputField from "../../Components/Inputs/InputField";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { FormProvider, useForm, Controller } from "react-hook-form";
import TextArea from "../../Components/Inputs/TextArea";
import SelectField from "../../Components/Inputs/SelectField";
import { MakeAxiosRequest } from "../../utils/handler";
import Swal from "sweetalert2";
import AddImageField from "../../Components/Inputs/AddImageField";
import DateField from "../../Components/Inputs/DateField";
import FormFields from "../../Components/Inputs/FormFields";
import TextEditor from "../../Components/Inputs/TextEditor";

function CreateEditModal({
  showEdit,
  handleCloseEdit,
  isEdit,
  offerDetails,
  userData,
  fetchData,
  perPage,
  businessId,
}) {
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [business, setBusiness] = useState([]);
  const methods = useForm();

  const { register,handleSubmit, reset,watch,formState:{errors}, clearErrors} = methods;

  const endDate = watch('end_date');
  const startDate = watch('start_date');

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    if (isEdit) {
      updateOffer(data);
    } else {
      createOffer(data);
    }
  };
  const createOffer = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post",
      { ...data, hasImg,imgfileKey,imgfileCordinates, hasBannerImg,bannerfileKey,bannerfileCordinates },
      "/offer/store");
      console.log(response);
      console.log(response.data)
      if (response.status == 1) {
        
        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        userData && userData(1, perPage);
        fetchData && fetchData(1, perPage);
        // alert(response.message);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Warning!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const validateDates = (value, e) => {
    console.log(e.end_date);
    var errorMessage = null
    if (e.end_date < e.start_date) {
      errorMessage = 'End date must be greater than start date';
    } else {
      errorMessage = null;
    }
    return errorMessage;
    
  };

  const updateOffer = async (data) => {
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data, hasImg,imgfileKey,imgfileCordinates, hasBannerImg,bannerfileKey,bannerfileCordinates },
        `/offer/${offerDetails?.offer_uuid}/update`
      );
      console.log(data);
      console.log(offerDetails);
      if (response.status == 1) {
        
        reset();
        handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
        });
        userData && userData(1, perPage);
        fetchData && fetchData(1, perPage);
        // alert(response.message);
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  useEffect(() => {
    fetctDetails();
  }, []);

  const clearData=()=>{
    // setImageUploaded(false);
    setSubmitDisabled(false);
    setAddImg();
    setImgFileKey(null);
    setImgFileCordinates(null);
    setBannerImg();
    setBannerFileKey(null);
    setBannerFileCordinates(null);
  }

  useEffect(() => {
    clearData();
    setSubmitDisabled(false);
    setBannerImgPath(offerDetails.banner_image_path || '');
    offerDetails.banner_image_path ? sethasBannerImg(1) : sethasBannerImg(0);
    setImgPath(offerDetails.card_image_path || '');
    offerDetails.card_image_path ? sethasImg(1) : sethasImg(0);
    // isEdit ? sethasImage(offerDetails.hasImage) : "0";
    reset({
      business_id: offerDetails.business_id || businessId || "",
      offer_title: offerDetails.title || "",
      offer_description: offerDetails.description || "",
      terms_condition:offerDetails.terms_condition || "",
      phone_number: offerDetails.phone_number || "",
      start_date: offerDetails.start_date || "",
      end_date: offerDetails.end_date || "",
      banner_image: offerDetails.banner_image_path || "",
      card_image: offerDetails.card_image_path || "",
    });
    return () => {
      clearData();
      setSubmitDisabled(false);
      removeImg();
      removeBannerImg();
      reset();
    };
  }, [showEdit, isEdit, offerDetails]);

  const validatebannerImage = async () => {
    var validationmessage = (hasBannerImg == 0 || hasBannerImg == undefined) ? (`Please upload banner image`) : true;
    return  validationmessage;
  };

  const [imageModalShow, setImageModalShow] = useState(false);
  const [bannerModalShow, setBannerModalShow] = useState(false);
  const handleClose = () => {
    setImageModalShow(false);
  };
  const BannerModalClose = () => {
    setBannerModalShow(false);
  };

  const [bannerImg, setBannerImg] = useState();
  const [bannerImgPath, setBannerImgPath] = useState();
  const [hasBannerImg, sethasBannerImg] = useState(0);
  const [bannerfileKey, setBannerFileKey] = useState('');
  const [bannerfileCordinates, setBannerFileCordinates] = useState();

  const bannerImgRef = useRef(null);
  const selectBannerImg = () => {
    bannerImgRef.current.click();
  };
  function handleBannerImgUpload(e) {
    // console.log("click");
    if (e.target && e.target.files[0]) {
      // console.log("if");
      setBannerImg(e.target.files[0]);
      setBannerModalShow(true);
      setSubmitDisabled(true);
      clearErrors("bannerImage");
    }
  }

  const croppedBannerImage = (imgPath,tempKey,cordinates) => {
    sethasBannerImg(1);
    // console.log(imgPath);
    setBannerImgPath(imgPath);
    setBannerModalShow(false);
    setSubmitDisabled(false);
    setBannerFileKey(tempKey);
    setBannerFileCordinates(cordinates || null);
  };

  const fetctDetails = async () => {
    try {
      const response = await MakeAxiosRequest(
        "post",
        {},
        "/offer/fetchdetails"
      );
      // console.log(response)
      if (response.status == 1) {
        const businesses = response.data.businesses?.map((item) => ({
          // Transform each item as needed
          id: item.id,
          name: item.title,
        }));
        console.log(businesses)
        setBusiness(businesses);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  function removeBannerImg() {
    setBannerImg();
    setBannerImgPath(null);
    sethasBannerImg(0);
  }

  const [addImg, setAddImg] = useState();
  const [imgPath, setImgPath] = useState();
  const [hasImg, sethasImg] = useState(0);
  const [imgfileKey, setImgFileKey] = useState('');
  const [imgfileCordinates, setImgFileCordinates] = useState();

  const addImageRef = useRef(null);
  const openFile = () => {
    addImageRef.current.click();
  };

  function handleImgUpload(e) {
    if (e.target && e.target.files[0]) {
      setAddImg(e.target.files[0]);
      // setFilePath(URL.createObjectURL(e.target.files[0]));
      // sethasImage(1);
      setImageModalShow(true);
      setSubmitDisabled(true);
      clearErrors("image");
    }
  }

  const croppedImage = (imgPath,tempKey,cordinates) => {
    // console.log(imgPath);
    sethasImg(1);
    setImgPath(imgPath);
    setImageModalShow(false);
    setSubmitDisabled(false);
    setImgFileKey(tempKey);
    setImgFileCordinates(cordinates || null);
  };

  function removeImg() {
    setAddImg(null);
    setImgPath(null);
    sethasImg(0);
  }


  const fields = [
    {
      name: "business_id",
      label: "Business",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please select a business" },
      type: "select",
      options:business,
      isShow: businessId ? false : true,
    },
    {
      name: "offer_title",
      label: "Offer Title",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please enter offer title" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "start_date",
      label: "Start Date",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please enter start date" },
      type: "date",
      onchange:{validateDates},
      isShow: true,
    },
    {
      name: "end_date",
      label: "End Date",
      className: "col-md-6 col-lg-6 mb-3",
      rules: { required: "Please enter end date",validate : validateDates },
      onchange:{validateDates},
      type: "date",
      isShow: true,
    },
    {
      name: "offer_description",
      label: "Offer Details",
      className: "col-12 mb-3",
      rules: { required: "Please enter offer details" },
      type: "textarea",
      isShow: true,
    },
    {
      name: "terms_condition",
      label: "Terms and Conditions",
      className: "col-12 mb-3",
      rules: { required: "Please enter terms and conditions" },
      type: "texteditor",
      isShow: true,
    },
  
    // {
    //   name: "image",
    //   label: "Offer Image",
    //   className: "col-12 mb-3",
    //   rules: {
    //     validate: {
    //       validateImg: async () => {
    //         // return (hasImage == 0 || hasImage == undefined) && "Please upload service image";
    //         var validationmessage = (hasImg == 0 || hasImg == undefined) ? (`Please upload offer image`) : true;
    //         return  validationmessage;
    //       },
    //     },
    //   },
    //   type: "addImage",
    //   title: "Add your offer image",
    //   subtitle: "Recommended Size :1080px x 1080px",
    //   hasImage: hasImg,
    //   imgRef: addImageRef,
    //   openFile: openFile,
    //   upload: handleImgUpload,
    //   remove: removeImg,
    //   imagePath: imgPath,
    //   showAddIcon:true,
    //   isShow: true,
    // },
  ];



  return (
    <>
      <Modal
        show={showEdit}
        // onHide={handleCloseEdit}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="crte-bsness-top-banner">
              <div className="crte-bsness-top-banner-dtls text-center">
                <div className="banner-img">
                  {hasBannerImg == "1" && (
                    <img className="" src={bannerImgPath} alt="" />
                  )}


                </div>
                <div className="banner-cndnt py-4 d-flex align-items-center justify-content-center">
                  <div>
                    <h3>{isEdit ? `Edit an offer` : `Add a new offer`}</h3>
                  <p className="px-2">{isEdit ? `Edit an offer on Aladinz app.` : `Add a new offer on Aladinz app.`}</p>
                  </div>
               
                </div>
               
              </div>
              <input
                style={{ display: "none" }}
                type="text"
                value={hasBannerImg}
                className="form-control"
                id="image"
                placeholder=""
                {...register("bannerImage", { validate: validatebannerImage })}
              />
              {hasBannerImg == "1" ? (
                <a
                  className="btn btn-outline-light rounded-pill banner-btn"
                  onClick={() => {
                    removeBannerImg();
                  }}
                >
                  <i class="fa-solid fa-trash-can me-2"></i>
                  <span className="">Remove banner</span>
                </a>
              ) : (
                <a
                  className="btn btn-outline-light rounded-pill banner-btn"
                  onClick={() => {
                    selectBannerImg();
                  }}
                >
                  <i className="fa-solid fa-pen me-0 me-md-2"></i>
                  <span>Add a banner</span>
                </a>
              )}

              <input
                style={{ visibility: "hidden" }}
                ref={bannerImgRef}
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onChange={handleBannerImgUpload}
              />
            </div>
            <div className="modal-body">
            {errors.bannerImage && <span className='error'>{errors.bannerImage.message}</span>}

              <div className="row mt-5">
              {bannerModalShow && (
                <ImageCropper
                file={bannerImg}
                onCropped={croppedBannerImage}
                aspectRatio={16/9}
              />
            )}
          
                {/* <FormFields fields={fields} /> */}
                {fields?.map((field, index) => {
                  return (
                    field.isShow && (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (field.type) {
                            case "inputfield":
                              return (
                                <InputField
                                  key={index}
                                  className="col-md-6 col-lg-6 mb-3"
                                  {...field}
                                />
                              );
                         
                            case "date":
                              return (
                                <DateField
                                  key={index}
                                  onChange={(value, e) => this.validateDates(value, e)}
                                  {...field}
                                />
                              );
                            case "select":
                              return (
                                <SelectField
                                  key={index}
                                  {...field}
                                />
                              );
                            case "textarea":
                                return (
                                  <TextArea
                                    key={index}
                                    {...field}
                                  />
                                );
                            case "texteditor":
                                return (
                                  <TextEditor
                                    key={index}
                                    {...field}
                                  />
                                );
                            default:
                              break;
                          }
                        })()}
                      </React.Fragment>
                    )
                  );
                })}
              {imageModalShow && (
                <ImageCropper
                file={addImg}
                onCropped={croppedImage}
                aspectRatio={1/1}
              />
            )}
              </div>
            </div>

            <div className="modal-footer b-0">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                onClick={handleCloseEdit}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitDisabled}
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>




          </form>
        </FormProvider>



      </Modal>
    </>
  );
}

export default CreateEditModal;
