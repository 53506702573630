import React from 'react'
import DataTable from 'react-data-table-component';
import noRecords from '../../Assets/Images/no-records.png';
import loaderFile  from '../../Assets/Images/loader.gif';


function Datatablelisting(props) {
  const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
  return (
    <>
     {!props.isTableLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
      <div className="col-12">
            <div className={`list-table table-responsive ${props.addclass || ''}`}>
              <DataTable
                columns={props.columns}
                data={props.tableData} 
                pagination
                paginationServer
                paginationTotalRows={props.totalCount}
                onChangePage={props.pageChange}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                }}
                
                customStyles={customStyles}
                // onTableUpdate={props.handleTableUpdate}
                progressPending={props.isloading} // This property displays a loading indicator when true
                noDataComponent={
                  <div className="mt-4 text-center">
                    <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
                    <div className='mt-3'>There are no {props.type} to display.</div>
                  </div>
                }
              />
            </div>
          </div>
      )}
    </>
  )
}

export default Datatablelisting