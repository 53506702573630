import React, { useEffect, useRef, useState } from "react";
import InputField from "../../Components/Inputs/InputField";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import TextArea from "../../Components/Inputs/TextArea";
import SelectField from "../../Components/Inputs/SelectField";
import { MakeAxiosRequest, MakeFileUpload } from "../../utils/handler";
import dummyProfile from "../../Assets/Images/business-img.png";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Facebook from "../../Assets/Images/social-media/facebook.png";
import Instagram from "../../Assets/Images/social-media/instagram.png";
import Twitter from "../../Assets/Images/social-media/twitter.png";
import Web from "../../Assets/Images/social-media/web.png";
import UrlField from "../../Components/Inputs/UrlField";
import DateField from "../../Components/Inputs/DateField";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DevTool } from "@hookform/devtools";
import { Modal,CloseButton  } from "react-bootstrap";
import ImageCropper from "../../Components/ImageCrop/ImageCropper";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import loaderFile from "../../Assets/Images/loader.gif";

import { StyledEngineProvider } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
 
import { format } from 'date-fns';
import HoursField from "../../Components/Inputs/HoursField";
import { Stack } from "@mui/material";
import { phoneRegex } from "../../Constants";
dayjs.extend(utc);
 dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Kolkata');
function BusinessCreate() {

  const { userKey } = useParams();
  const navigate = useNavigate();

  // const [value, onChange] = useState('10:00');

  //  console.log(userKey)
  const [isLoading, setIsloading] = useState(true);
  const [bussinessDetails, setBussinessDetails] = useState({});
  const [businesshours, setBusinesshours] = useState({});
  const [businessusers, setbusinessusers] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [bannerModalShow, setBannerModalShow] = useState(false);
  const [showPanchayat, setShowPanchayat] = useState(false);
  const [showMunicipality, setShowMunicipality] = useState(false);
  const [showCorporation, setShowCorporation] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };
  const BannerModalClose = () => {
    setBannerModalShow(false);
  };
  const methods = useForm();

 // Breadcrumbs
 const { setBreadcrumbsData } = useBreadcrumb();
 const updateBreadcrumbs = () => {
  setBreadcrumbsData([
    { label: "Business",path: "/businesses" },
    { label: (userKey) ? "Edit" : "Create" },
  ]);
};  
useEffect(() => {
  updateBreadcrumbs();
}, []);

  useEffect(() => {
    // console.log("Start of useEffect");
    const prefillData = async () => {
      // console.log("Fetching data");
      setProfileImgPath(bussinessDetails.profile_img || dummyProfile);
      setBannerImgPath(bussinessDetails.logo_img || "");
      sethasBannerImg(bussinessDetails.hasBannerImg=='1' ? 1 : 0);
      sethasProfileImg(bussinessDetails.hasProfileImg=='1' ? 1 : 0);
      if (bussinessDetails.local_body_type == '1') {
        setShowPanchayat(true);
        setShowMunicipality(false);
        setShowCorporation(false);
      } else if (localBodyType == '2') {
        setShowPanchayat(false);
        setShowMunicipality(true);
        setShowCorporation(false);
      } else if (localBodyType == '3') {
        setShowPanchayat(false);
        setShowMunicipality(false);
        setShowCorporation(true);
      } else {
        setShowPanchayat(false);
        setShowMunicipality(false);
        setShowCorporation(false);
      }
        resetField('panchayat_id');
      reset({
        ...bussinessDetails,
        hours: businesshours,
        users: businessusers,
      });
      setFieldsHours((prevFields) =>
        prevFields.map((field) => ({
          ...field, 
          is_open: businesshours[field.name] ? businesshours[field.name].is_open : false,
          opens_at: businesshours[field.name] ? businesshours[field.name].opens_at : null,
          closes_at: businesshours[field.name] ? businesshours[field.name].closes_at : null,
        }))
      );

      // Prefill users if available in bussinessDetails
      // if (businessusers && businessusers.length > 0) {
      //   append(businessusers);
      // }
    };

    prefillData();
    window.scrollTo(0, 0);
    return () => {
      reset();
    };

  }, [bussinessDetails,businesshours]);


  const {register, formState: { errors }, handleSubmit, reset, resetField, control, watch } = methods;
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [agentusers, setAgentusers] = useState([]);
  const [businesstype, setBusinesstype] = useState([]);
  const [localBodytype, setLocalBodyType] = useState([]);
  const [panchayatsData, setPanchayatsdata] = useState([]);
  const [corporationData, setCorporationdata] = useState([]);
  const [municipalityData, setMunicipalitydata] = useState([]);
  const [corporation, setCorporation] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subscriptionplans, setSubscriptionplans] = useState([]);
  const [isIndividual, setIsIndividual] = useState(false);

  useEffect(() => {
    fetctDetails();
  }, []);

  const districtField = watch("district_id");
  const cateoryField = watch("category_id");
  const userName = watch("business_users_name");
  const userPhone = watch("business_users_phonenumber");
  const businessType = watch('type_id');
  const localBodyType = watch('local_body_type');
  useEffect(() => {
    businessType == "1" ? setIsIndividual(true) : setIsIndividual(false);
  }, [businessType]);

  useEffect(() => {
    
    if (localBodyType == '1') {
      setShowPanchayat(true);
      setPanchayats(panchayatsData[districtField]);
      setShowMunicipality(false);
      setShowCorporation(false);
         resetField('panchayat_id' , {
          defaultValue: ''
      });    
    } else if (localBodyType == '2') {
      setShowPanchayat(false);
      setShowMunicipality(true);
      setMunicipality(municipalityData[districtField]);
      setShowCorporation(false);
         resetField('panchayat_id' , {
          defaultValue: ''
      });  
    } else if (localBodyType == '3') {
      setShowPanchayat(false);
      setShowMunicipality(false);
      setShowCorporation(true);
      setCorporation(corporationData[districtField]);
          resetField('panchayat_id' , {
          defaultValue: ''
      });  
    } else {
      setShowPanchayat(false);
      setShowMunicipality(false);
      setShowCorporation(false);
      resetField('local_body_type');
    }
      console.log("ffff");
      console.log(localBodyType);
  }, [localBodyType]);

  useEffect(() => {
    if (localBodyType == '1') {
      setShowPanchayat(true);
      setPanchayats(panchayatsData[districtField]);
      setShowMunicipality(false);
      setShowCorporation(false);
      resetField('panchayat_id' , {
          defaultValue: ''
      }); 
    } else if (localBodyType == '2') {
      setShowPanchayat(false);
      setShowMunicipality(true);
      setMunicipality(municipalityData[districtField]);
      setShowCorporation(false);
         resetField('panchayat_id' , {
          defaultValue: ''
      });
    } else if (localBodyType == '3') {
      setShowPanchayat(false);
      setShowMunicipality(false);
      setShowCorporation(true);
      setCorporation(corporationData[districtField]);
         resetField('panchayat_id' , {
          defaultValue: ''
      });
    } else {
      setShowPanchayat(false);
      setShowMunicipality(false);
      setShowCorporation(false);
         resetField('panchayat_id' , {
          defaultValue: ''
      });
    }
    // console.log(districtField);
    //setPanchayats(panchayatsData[districtField]);
    //resetField('panchayat_id');
  }, [districtField,bussinessDetails]);

  useEffect(() => {
    
    
      setSubcategories(subcategoriesData[cateoryField]);
      resetField('sub_category_id', {
          defaultValue: bussinessDetails.sub_category_id || ''
      });
     // console.log(fieldsAbout[16].value);
      //fieldsAbout[16].value = '';
      
  }, [cateoryField,bussinessDetails]);

  const handleLocalBodyChange = (event) => {
    const selectedValue = event.target.value;
   console.log(selectedValue);
  };
  const fetctDetails = async () => {
    if (userKey) {
      try {
        const response = await MakeAxiosRequest(
          "post",
          {},
          `/business/${userKey}/details`
        );
        // console.log(response)
        if (response.status == 1) {
          const categories = response.data.categories?.map((item) => ({
            id: item.id,
            name: item.title,
          }));
          setCategories(categories);
          const agentusers = response.data.agentusers?.map((item) => ({
            id: item.id,
            name: item?.first_name + " " + item?.last_name,
          }));
          setAgentusers(agentusers);
          const districts = response.data.districts?.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setDistricts(districts);
          const businesstypes = response.data.businessType?.map((item) => ({
            id: item.id,
            name: item.type,
          }));
          const bodytypes = response.data.localBodyTypes?.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setLocalBodyType(bodytypes);
          setBusinesstype(businesstypes);
          setPanchayatsdata(response.data.panchayats);
          setSubcategoriesData(response.data.subcategories);
          setCorporationdata(response.data.corporation);
          setMunicipalitydata(response.data.municipality);
          const subscriptionplans = response.data.subscriptionplans?.map(
            (item) => ({
              id: item.id,
              name: item.subscription_name,
            })
          );
          setSubscriptionplans(subscriptionplans);
          setBussinessDetails(response.data.businessdets);
          setBusinesshours(response.data.hours);
          setbusinessusers(response.data.users);
          // setPanchayats(panchayatsData[districtField])
          // console.log(response.data.businessdets);
          // window.scrollTo(0, 0);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.error("Error", error);
      }
      setIsloading(false);
    } else {
      setIsloading(false);
      try {
        const response = await MakeAxiosRequest(
          "post",
          {},
          "/business/fetchdetails"
        );
        // console.log(response)
        if (response.status == 1) {
          const categories = response.data.categories?.map((item) => ({
            id: item.id,
            name: item.title,
          }));
          setCategories(categories);
          const agentusers = response.data.agentusers?.map((item) => ({
            id: item.id,
            name: item?.first_name + " " + item?.last_name,
          }));
          setAgentusers(agentusers);
          const districts = response.data.districts?.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setDistricts(districts);
          const businesstypes = response.data.businessType?.map((item) => ({
            id: item.id,
            name: item.type,
          }));
          const bodytypes = response.data.localBodyTypes?.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setLocalBodyType(bodytypes);
          setBusinesstype(businesstypes);
          setCorporationdata(response.data.corporation);
          setMunicipalitydata(response.data.municipality);
          setPanchayatsdata(response.data.panchayats);
          setSubcategoriesData(response.data.subcategories);
          setBusinesshours(response.data.hours);
          const subscriptionplans = response.data.subscriptionplans?.map(
            (item) => ({
              id: item.id,
              name: item.subscription_name,
            })
          );
          setSubscriptionplans(subscriptionplans);
          // append({ name: "", phonenumber: "" })
          // window.scrollTo(0, 0);

        } else {
          console.log(response);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  const fieldsAbout = [
    {
      name: "title",
      label: "Name",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please enter your name" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "email",
      label: "Email",
      className: "col-md-6 col-lg-4 mb-3",
      rules: {
        // required: "Please enter your email",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        /* validate: {
        //   emailExist: async (fieldValue) => {
        //     const response = await MakeAxiosRequest(
        //       "post",
        //       { email: fieldValue, businessid: bussinessDetails?.id },
        //       "/checkbusinessemailexist"
        //     );
        //     // console.log(response.data.hasData);
        //     return response.data.hasData == 0 || "Email already exist";
        //   },
        // },*/
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "contact_number",
      label: "Contact Number",
      className: "col-md-6 col-lg-4 mb-3",
      rules: {
        required: "Please enter your contact number",
        pattern: {
          value: phoneRegex,
          message: "Please enter a valid Contact Number",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "long_description",
      label: "About (long)",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please enter long description" },
      type: "textarea",
      isShow: !isIndividual,
    },
    {
      name: "short_description",
      label: "About (short)",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please enter short description" },
      type: "textarea",
      isShow: true,
    },
    {
      name: "address",
      label: "Address",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please enter your address" },
      type: "textarea",
      isShow: true,
    },
    { 
      name: "direction",
      label: "Add Direction",
      className: "col-md-6 col-lg-4 mb-3",
      rules: {
        // required: "Please enter google map location",
        pattern: {
          value: /^(https?:\/\/)?(www\.)?(google\.com\/maps\/.*|maps\.app\.goo\.gl\/.*|www\.google\.com\/maps\?.*)$/,
          message: "Please enter a valid URL",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "district_id",
      label: "District",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select district" },
      type: "select",
      options: districts,
      isShow: true,
    },
    {
      name: "local_body_type",
      label: "Local Body Type",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select local body" },
      type: "select",
      options: localBodytype,
      isShow: true,
    },
    {
      name: "panchayat_id",
      label: "Panchayath",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select panchayath" },
      type: "select",
      options: panchayats,
      isShow: showPanchayat,
    },
    {
      name: "panchayat_id",
      label: "Municipality",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select municipality" },
      type: "select",
      options: municipality,
      isShow: showMunicipality,
    },
    {
      name: "panchayat_id",
      label: "Municipal Corporation",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select corporation" },
      type: "select",
      options: corporation,
      isShow: showCorporation,
    },
    {
      name: "whatsapp_number",
      label: "WhatsApp Number",
      className: "col-md-6 col-lg-4 mb-3",
      rules: {
        required: "Please enter your whatsapp number",
        pattern: {
          value: phoneRegex,
          message: "Please enter a valid WhatsApp Number",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "agent_user_id",
      label: "Agent",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select agent" },
      type: "select",
      options: agentusers,
      isShow: true,
    },
    {
      name: "type_id",
      label: "Business Type",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select business type" },
      type: "select",
      options: businesstype,
      isShow: true,
    },
    {
      type: "hr",
      isShow: true,
    },
    {
      name: "subscription_plan_id",
      label: "Subscription Plan",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select your subscription plan" },
      type: "select",
      options: subscriptionplans,
      // isShow: true,
      isShow: userKey ? false : true,
    },
    {
      name: "subscription_start_date",
      label: "Subscription Start Date",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please select subscription start date" },
      type: "date",
      // isShow: true,
      isShow: userKey ? false : true,
    },
    {
      name: "subscription_description",
      label: "Transaction Note",
      className: "col-md-6 col-lg-4 mb-3",
      rules: { required: "Please enter transaction note" },
      type: "textarea",
      isShow: userKey ? false : true,
    },
    {
      name: "category_id",
      label: "Category",
      className: "col-md-6 col-lg-3 mb-3",
      rules: { required: "Please select category" },
      type: "select",
      options: categories,
      isShow: true,
    },
    {
      name: "sub_category_id",
      label: "Subcategory",
      className: "col-md-6 col-lg-3 mb-3",
      // rules: { required: "Please select subcategory" },
      type: "select",
      options: subcategories,
      isShow: true,
    },
    {
      name: "owner_name",
      label: "Owner Name",
      className: "col-md-6 col-lg-3 mb-3",
      rules: { required: "Please enter owner name" },
      type: "inputfield",
      isShow: !isIndividual,
    },
    {
      name: "owner_phone",
      label: "Owner Phone Number",
      className: "col-md-6 col-lg-3 mb-3",
      rules: {
        required: "Please enter owner phone number",
        pattern: {
          value: phoneRegex,
          message: "Please enter a valid Phone Number",
        },
      },
      type: "inputfield",
      isShow: !isIndividual,
    },
  ];

  const [fieldsHours, setFieldsHours] = useState([
    // {
    //   label: "All",
    //   name: "all",
    //   is_open: false,
    //   opens_at:null,
    //   closes_at:null,
    // },
    {
      label: "Monday",
      name: "monday",
      // is_open: businesshours?.monday?.is_open || false,
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Tuesday",
      name: "tuesday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Wednesday",
      name: "wednesday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Thursday",
      name: "thursday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Friday",
      name: "friday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Saturday",
      name: "saturday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
    {
      label: "Sunday",
      name: "sunday",
      is_open: false,
      opens_at:null,
      closes_at:null,
    },
  ]);

  const fieldsSocialMedia = [
    {
      name: "fb_link",
      label: "Facebook",
      icon: Facebook,
      className: "row align-items-center",
      rules: {
        pattern: {
          value: /^(?:(?:https?:\/\/(?:www\.)?)|(?:www\.))[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?$/,
          message: "Please enter a valid link",
        },
      },
      type: "urlfield",
    },
    {
      name: "insta_link",
      label: "Instagram",
      icon: Instagram,
      className: "row align-items-center",
      rules: {
        pattern: {
          value: /^(?:(?:https?:\/\/(?:www\.)?)|(?:www\.))[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?$/,
          message: "Please enter a valid link",
        },
      },
      type: "urlfield",
    },
    {
      name: "twitter_link",
      label: "X",
      icon: Twitter,
      className: "row align-items-center",
      rules: {
        pattern: {
          value: /^(?:(?:https?:\/\/(?:www\.)?)|(?:www\.))[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?$/,
          message: "Please enter a valid link",
        },
      },
      type: "urlfield",
    },
    {
      name: "website",
      label: "Website",
      icon: Web,
      className: "row align-items-center",
      rules: {
        pattern: {
          value: /^(?:(?:https?:\/\/(?:www\.)?)|(?:www\.))[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?$/,
          message: "Please enter a valid link",
        },
      },
      type: "urlfield",
    },
  ];

  // const [fieldsusers, setFieldsusers] = useState([]);

  const {
    fields: fieldsusers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "users",
  });

  const [profileImg, setProfileImg] = useState();
  const [profileImgPathTemp, setProfileImgPathTemp] = useState(dummyProfile);
  const [profileImgPath, setProfileImgPath] = useState(dummyProfile);
  const [hasProfileImg, sethasProfileImg] = useState(0);
  const [profileImgFileKey, setProfileImgFileKey] = useState('');
  const [profileImgFileCordinates, setProfileImgFileCordinates] = useState(null);

  const profileImgRef = useRef(null);
  const selectProfileImg = () => {
    profileImgRef.current.click();
  };

  function handleProfileImgUpload(e) {
    if (e.target && e.target.files[0]) {
      setProfileImg(e.target.files[0]);
      setModalShow(true);
    }
  }
  const croppedProfileImage = (imgPath,tempKey,cordinates) => {
    sethasProfileImg(1);
    setProfileImg(null);
    setProfileImgPath(imgPath);
    setModalShow(false);
    setProfileImgFileKey(tempKey);
    setProfileImgFileCordinates(cordinates || null);
  };

  function removeProfileImg() {
    setProfileImg(null);
    setProfileImgPath(dummyProfile);
    sethasProfileImg(0);
    setProfileImgFileKey(null);
    setProfileImgFileCordinates(null);
  }

  const [bannerImg, setBannerImg] = useState();
  // const [bannerImgPathTemp, setBannerImgPathtemp] = useState();
  const [bannerImgPath, setBannerImgPath] = useState();
  const [hasBannerImg, sethasBannerImg] = useState(0);
  const [bannerImgFileKey, setBannerImgFileKey] = useState('');
  const [bannerImgFileCordinates, setBannerImgFileCordinates] = useState(null);

  const bannerImgRef = useRef(null);
  const selectBannerImg = () => {
    bannerImgRef.current.click();
  };

  function handleBannerImgUpload(e) {
    if (e.target && e.target.files[0]) {
      setBannerImg(e.target.files[0]);
      setBannerModalShow(true);
    }
  }

  const croppedBannerImage = (imgPath,tempKey,cordinates) => {
    sethasBannerImg(1);
    setBannerImg(null);
    setBannerImgPath(imgPath);
    setBannerModalShow(false);
    setBannerImgFileKey(tempKey);
    setBannerImgFileCordinates(cordinates || null);
  };

  function removeBannerImg() {
    setBannerImg(null);
    setBannerImgPath(null);
    sethasBannerImg(0);
    setBannerImgFileKey(null);
    setBannerImgFileCordinates(null);
  }


  // const uploadProfileImage = async (url,file) => {
  //   // console.log("file");
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   try {
  //     // console.log(formData);
  //     const response = await MakeFileUpload(
  //       "post",
  //       formData,
  //       url
  //     );

  //     console.log(response);
  //     // }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };



  const handleCheckboxChange = (name) => {
    setFieldsHours((prevFields) => {
      if (name === 'all') {
        const isOpenValue = !prevFields[0].is_open; // Take the value from the first field assuming all have same value initially
        return prevFields.map((field) => ({
          ...field,
          is_open: isOpenValue
        }));
      } else {
        return prevFields.map((field) =>
          field.name === name ? { ...field, is_open: !field.is_open } : field
        );
      }
    });
  };

  const onSubmit = (data) => {
    // console.log("Request data:");
    // console.log(errors);
    // console.log(data);
    console.log(businesshours);
    if (userKey) {
      update(data);
    } else {
      create(data);
    }
  };

  const create = async (data) => {
    // console.log(data);
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest("post",
      { ...data,hasProfileImg,profileImgFileKey,profileImgFileCordinates,hasBannerImg,bannerImgFileKey,bannerImgFileCordinates },
      "/business/store");
      // console.log(response);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'
        const businessKey = response.businessDetails.business_uuid;

        // Call FileUpload function with the user key
        // profileImg && (await uploadProfileImage(`business/${businessKey}/uploadimage`,profileImg));
        // bannerImg && (await uploadProfileImage(`business/${businessKey}/uploadbannerimage`,bannerImg));
        // bannerImg && (await uploadBannerImage(businessKey));

        reset();
        // handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          // text: response.message,
          text: "Business created successfully",
        });
        navigate(-1);
        // fetchData(1, perPage);
        // alert(response.message);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        alert(response.message);
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };

  const update = async (data) => {
    // alert("update");
    console.log(data ,'updatedata');
    setSubmitDisabled(true);
    try {
      const response = await MakeAxiosRequest(
        "post",
        { ...data,hasProfileImg,profileImgFileKey,profileImgFileCordinates,hasBannerImg,bannerImgFileKey,bannerImgFileCordinates },
        `/business/${userKey}/update`
      );
      console.log(data);
      if (response.status == 1) {
        // Assuming user key is present in the response data as 'userKey'
        // const userKey = response.userDetails.user_uuid;

        // Call FileUpload function with the user key
        // profileImg && (await uploadProfileImage(`business/${userKey}/uploadimage`,profileImg));
        // bannerImg && (await uploadProfileImage(`business/${userKey}/uploadbannerimage`,bannerImg));
        // profileImg && (await uploadProfileImage(userKey));
        // bannerImg && (await uploadBannerImage(userKey));

        reset();
        // handleCloseEdit();
        Swal.fire({
          icon: "success",
          title: "Success!",
          confirmButtonColor: "#F87416",
          text: response.message,
          // text: "Business updated successfully",
        });
        navigate(-1);
        // fetchData(1, perPage);
        // alert(response.message);
      }else if(response.status == 0){
        Swal.fire({
          icon: 'error', title: response.message, customClass: { confirmButton: 'btn btn-primary' }
        });
      } else {
        // setError('phone_number', { type: 'custom', message: response.message });
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitDisabled(false);
  };
  // const [time, setTime] = useState(new Date());
  // const onChangeTime = (newTime) => {
  //   setTime(newTime);
  // };

  // Handle Enter key press using event delegation
  const handleKeyDown = (event) => {
    console.log(event);
    if (event.key === 'Enter' && event.target.tagName === 'INPUT') {
      event.preventDefault();
    }
  };
  

  function formatDate(string) {
    var options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }
  
  // var dateString = "2018-05-18T04:00:00.000Z";
  // console.log(formatDate(dateString));
  // console.log(format(new Date(), 'yyyy/MM/dd kk:mm:ss'));


  return (
    <>
      <div className="page">
        {/* Content Row */}
        <div className="row align-items-center">
          <div className="col-sm-10 col-lg-7">
          <div className="d-flex align-items-center mb-3">
          <a href='javascript:void(0)' onClick={() => navigate(-1)} className="back-btn">
              <i class="fa-solid fa-circle-arrow-left me-1 me-sm-3"></i>
            </a>
            <Breadcrumbs />
          </div>
          </div>
        </div>
        {isLoading ? (
          <div >
            <center>
              <img
                src={loaderFile}
                style={{ width: '125px' }}
                alt="Loading..."
              />
            </center>
          </div>
        ) : (
        <div className="row">
          <div className="col-12">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
                <div className="card crt-bsness">
                  <div className="crte-bsness-top-banner">
                    <div className="crte-bsness-top-banner-dtls text-center py-4">
                      <div className="banner-img">
                        {hasBannerImg == "1" && (
                          <img className="" src={bannerImgPath} alt="" />
                        )}
                      </div>
                      <h3>
                        {userKey ? "Edit Business" : "Create a Business"}
                      </h3>
                      <p className="px-2">
                        {userKey
                          ? "Edit Business on Aladinz app"
                          : "Create a new Business on Aladinz app"}
                      </p>
                      <div className="d-flex justify-content-center">
                        <div className="crte-bsness-top-banner-img">
                          <img
                            className="rounded-pill"
                            src={profileImgPath}
                            alt=""
                            onClick={() => {
                              selectProfileImg();
                            }}
                          />
                          {hasProfileImg == "1" ? (
                            <a href="javascript:void(0)" className="img-cnrtl-btn">
                              <span className="dlt-icon">
                                <i
                                  className="fa-regular fa-trash-can"
                                  onClick={removeProfileImg}
                                ></i>
                              </span>
                            </a>
                          ) : (
                            <a href="javascript:void(0)" className="img-cnrtl-btn">
                              <span className="">
                                <i
                                  className="fa-regular fa-add"
                                  onClick={() => {
                                    selectProfileImg();
                                  }}
                                ></i>
                              </span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {hasBannerImg == "1" ? (
                      <a href="javascript:void(0)"
                        className="banner-btn banner-dlt-btn"
                        onClick={() => {
                          removeBannerImg();
                        }}
                      >
                        <i class="fa-regular fa-trash-can"></i>
                      </a>
                    ) : (
                      <a href="javascript:void(0)"
                        className="btn btn-outline-light rounded-pill banner-btn"
                        onClick={() => {
                          selectBannerImg();
                        }}
                      >
                        <i className="fa-solid fa-pen me-0 me-md-2"></i>
                        <span>Add a banner</span>
                      </a>
                    )}
                    {modalShow ||
                    <input
                      style={{ visibility: "hidden" }}
                      ref={profileImgRef}
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={handleProfileImgUpload}
                    />}
                    {bannerModalShow || 
                    <input
                      style={{ visibility: "hidden" }}
                      ref={bannerImgRef}
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={handleBannerImgUpload}
                    />}
                  </div>

                  <div className="card-body crt-bsness-form mt-5">
                    <div className="col-12 mt-4">
                      <div className="row">
                        {fieldsAbout?.map((field, index) => {
                          return (
                            field.isShow && (
                              <React.Fragment key={index}>
                                {(() => {
                                  switch (field.type) {
                                    case "inputfield":
                                      return (
                                        <InputField
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    case "textarea":
                                      return (
                                        <TextArea
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    case "select":
                                      return (
                                        <SelectField
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    case "date":
                                      return (
                                        <DateField
                                          key={index}
                                          {...field}
                                        />
                                      );
                                    case "hr":
                                      return <hr />;
                                    default:
                                      return null;
                                  }
                                })()}
                              </React.Fragment>
                            )
                          );
                        })}
                      </div>
                      <hr />
                      <div className="row align-items-center pt-4">
                        <div className="col-12">
                          <h4 className="">Hours</h4>
                          <div className="hours-hd">

                            <div className="row">
                              <div className="col-md-12 col-lg-4"></div>
                              <div className="col-sm-6 col-lg-4 mb-1">
                                <label>
                                  {fieldsHours.some((day) => day.is_open) &&
                                    "Opens at"}
                                </label>
                              </div>
                              <div className="col-sm-6 col-lg-4 mb-1">
                                <label>
                                  {fieldsHours.some((day) => day.is_open) &&
                                    "Closes at"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          {fieldsHours?.map((field, index) => (
                            <div className="row align-items-center" key={index}>
                              <div className="col-md-12 col-lg-4">
                                <div className="hrs-weekdays mb-2">
                                  <div className="col-12">
                                    <div className="row align-items-center">
                                      <div className="col-6 ">
                                        <h6 className="mb-0">{field.label}</h6>
                                      </div>
                                      <div className="col-6">
                                        <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
                                          <div className="form-switch-btn">
                                            <input
                                              type="checkbox"
                                              hidden="hidden"
                                              id={field.name}
                                              checked={field.is_open}
                                              onClick={() =>  handleCheckboxChange(field.name)}
                                              {...register(
                                                `hours[${field.name}].is_open`
                                              )}
                                            />
                                            <label
                                              className="switch"
                                              htmlFor={field.name}
                                            ></label>
                                          </div>
                                          <p className="mb-0 ms-3">
                                            {field.is_open ? "Open" : "Closed"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-lg-4 mb-2">
                                {field.is_open && (
                                  <>
                                  <HoursField  
                                    field={field}  
                                    label="Opens at" 
                                    type="opens_at" 
                                    rules={
                                      {
                                        required: "Please select opening time",
                                        validate:{
                                          opensAtValidation: () => {
                                            const opensat = dayjs.utc(watch(`hours[${field.name}].opens_at`));
                                            const closesat = dayjs.utc(watch(`hours[${field.name}].closes_at`));
                                            return closesat>opensat || `Opening time must be less than closing time`;
                                          },
                                        },
                                      }
                                    }
                                  />
                                  </>
                                )}
                              </div>
                                
                              <div className="col-sm-6 col-lg-4 mb-2">
                                {field.is_open && (
                                  <>
                                    <HoursField  
                                      field={field}  
                                      label="Closes at" 
                                      type="closes_at"
                                      rules={
                                        {
                                          required: "Please select closing time",
                                          validate:{
                                            opensAtValidation: () => {
                                              const opensat = dayjs.utc(watch(`hours[${field.name}].opens_at`));
                                              const closesat = dayjs.utc(watch(`hours[${field.name}].closes_at`));
                                              return closesat>opensat || `Closing time must be greater than opening time`;
                                            },
                                          },
                                        }
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                      <div className="row align-items-center pt-4">
                        <h4 className="mb-4">Social Media Links</h4>
                        <div className="col-md-12">
                          <div className="social-media-url">
                            {fieldsSocialMedia?.map((field, index) => (
                              <UrlField
                                key={index}
                                className="row align-items-center"
                                {...field}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      {!userKey && (
                        <>
                          <hr />
                          <div className="row align-items-center pt-4 mb-4">
                            <div className="col-md-6 my-2">
                              <h4 className="mb-0">Add User</h4>
                            </div>                        
                          </div>

                          <div className="col-12">
                            <div className="row align-items-center" >
                              <div className="col-md-12">
                                <div className="row align-items-start">
                                  <div className="col-md-6 col-lg-6 mb-3">
                                    <label className="form-label">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`business_users_name`,{
                                        required: userPhone && 'Please enter name',
                                      })}
                                    />

                                    {errors.business_users_name && (
                                      <span className="error">
                                        {errors.business_users_name.message}
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-md-6 col-lg-6 mb-3">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`business_users_phonenumber`,
                                        {
                                          required: userName && 'Please enter phone number',
                                          pattern: {
                                            value:
                                              phoneRegex,
                                            message:
                                              "Please enter a valid Phone Number",
                                          },
                                        }
                                      )}
                                    />
                                    {errors.business_users_phonenumber && (
                                      <span className="error">
                                        {errors.business_users_phonenumber.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                    {/* <p>{JSON.stringify(fieldsusers)}</p> */}

                    <div className="col-12 mt-3">
                      <div className="d-flex justify-content-end">
                        <Link to="/businesses" className="btn btn-outline-primary mx-1">
                          Cancel
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary mx-1"
                          disabled={isSubmitDisabled}
                        >
                          {userKey ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
            <DevTool control={control} />
          </div>
        </div>
        )}
      </div>
      {modalShow && <Modal
        show={modalShow}
        // onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        <Modal.Header>
        <CloseButton
          onClick={handleClose}
          style={{ position: "absolute", top: 10, right: 10 }}
        />
        </Modal.Header>
        <Modal.Body>
        <ImageCropper
          file={profileImg}
          onCropped={croppedProfileImage}
          aspectRatio={1/1}
        />
        </Modal.Body>
      </Modal>}
      {bannerModalShow && <Modal
        show={bannerModalShow}
        // onHide={BannerModalClose}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        <Modal.Header>
        <CloseButton
          onClick={BannerModalClose}
          style={{ position: "absolute", top: 10, right: 10 }}
        />
        </Modal.Header>
        <Modal.Body>
        <ImageCropper
          file={bannerImg}
          onCropped={croppedBannerImage}
          aspectRatio={16 / 9}
        />
        </Modal.Body>
      </Modal>}
    </>
  );
}

export default BusinessCreate;
