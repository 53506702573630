import React from 'react'
import InputField from './InputField';
import TextArea from './TextArea';
import SelectField from './SelectField';
import DropzoneField from './DropzoneField'; 
function FormFields(props) {
  return (
    <>
       {props.fields?.map((field, index) => {
            return field.isShow && (
            <React.Fragment key={index}>
                {(() => {
                switch (field.type) {
                    case "inputfield":
                    return (
                        <InputField
                        key={index}
                        {...field}
                        />
                    );
                    case "textarea":
                    return (
                        <TextArea
                        key={index}
                        className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "select":
                    return (
                        <SelectField
                        key={index}
                        className="col-md-6 col-lg-6 mb-3"
                        {...field}
                        />
                    );
                    case "dropzone":
                    return (
                        <DropzoneField
                            key={index}
                            className="col-md-12 mb-3"
                            {...field}
                        />
                    );
                    default:
                    return null;
                }
                })()}
            </React.Fragment>
            );
        })} 
    </>
  )
}

export default FormFields