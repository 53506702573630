import React from 'react'
import { MakeAxiosRequest } from '../../utils/handler';
import {clearNotificationCount,updateUnreadNotificationCount} from '../../features/authSlice';
import { useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import updateNotificationCount  from '../../features/authSlice';
function Notifications({notifications,count}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
   const markAsRead = async (notificationKey) => {
      try {
  
        const res = await MakeAxiosRequest("post", {'key' : notificationKey }, `/notifications/${notificationKey}/markasread`);
         if(res.success == '1'){
              navigate(res.url);
              const result = await MakeAxiosRequest("post", {}, `/notifications/unreadcount`);
              dispatch(updateUnreadNotificationCount(result));
         }
      } catch (error) {
        console.error('Error fetching notification:', error);
      }
    };
    const clearNotifications = async () => {
      try {
  
        const res = await MakeAxiosRequest("post", { }, '/clearnotifications');
        if(res.success == '1'){
          const result = await MakeAxiosRequest("post", {}, `/notifications/unreadcount`);
          dispatch(updateUnreadNotificationCount(result));
        }
      } catch (error) {
        console.error('Error fetching notification:', error);
      }
    };
    

  return (
    <>
     {notifications.length > 0 ? (
          <React.Fragment>
              <li className="notfctn-lst-hd d-flex justify-content-between flex-wrap px-3 py-2">
                  <h5>Notifications</h5>
                  <a href="javascript:void(0)" className="clear-all" onClick={clearNotifications}>
                      Clear All
                  </a>
              </li>
              
              {notifications.map((notification, optionIndex) => (
                  <li className={`notfctn-lst px-3 py-2 ${notification.is_read === '0' ? 'notf-unread' : 'notf-read'}`}
                  key={optionIndex}>
                      <a href='javascript:void(0)' className="d-flex align-items-center justify-content-between" onClick={() => markAsRead(`${notification.notification_key}`)}>
                          <div className="d-flex">
                            <div>
                            <img className="rounded-circle d-block" src={notification.image} />
                            </div>
                              
                              <div>
                                  <h6>{notification.name}</h6>
                                  <p>{notification.message}</p>
                                  <p className="time">{notification.time}</p>
                              </div>
                          </div>
                          {notification.is_read == '0' && <div className="d-flex align-items-center ms-2">
                          <span class="online-indctn"></span>
                              <i className="fa-solid fa-angle-right"></i>
                          </div>}
                      </a>
                  </li>
              ))}
          </React.Fragment>
      ) : <p className='no-notf-records'>No notifications to show</p>}

      {count > 3 &&
      <div className="text-center pt-2">
         <Link className="link small" to={"/notificationslist"}>View all notifications</Link>
      </div>
      }              
    </>
  )
}

export default Notifications