
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';



function SidebarItems({ item}) {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  const modifiedPath = currentPath + 's';
  const subArray = ['paymentreports', 'searchreports', 'searchreports'];
  const isActive = subArray.includes(currentPath) ? 'nav-link parentnav ' : 'nav-link collapsed';
  const [items, setItems] = useState([]);

 

  return (
    <>
      {item.sublist?.length > 0 ? (
      <li className="side-nav-item">
          <a
            className={isActive}
            data-bs-toggle="collapse"
            href={`#${item.type}collapseExample`}
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div className="reports-nav">
              <span className="material-symbols-outlined">{item.icon}</span>
              <p>{item.title}</p>
            </div>
           <span className="down-arrow"><i class="fa-solid fa-angle-up"></i></span>
          </a>

          {item.sublist?.length > 0 && (
            <div className="collapse" id={`${item.type}collapseExample`}>
              <ul className="collapse-menu">
                {item.sublist?.map((sub) => (
                  <li
                    className={currentPath === sub.type ? 'side-nav-item active' : 'side-nav-item'}
                    key={sub.type}  // Use a unique identifier as the key
                  >
                    <Link className="nav-link" to={sub.link} id="sidebarToggle">
                      <span className="material-symbols-outlined">{sub.icon}</span>
                      <p>{sub.title}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ) : (
        <li className={currentPath === item.type || modifiedPath === item.type || currentPath+'es' === item.type ? 'side-nav-item active' : 'side-nav-item'}>
         {(() => {
            let pendingCount;

            if (item.type === 'reportedissues') {
              pendingCount = useSelector((state) => state.auth.pendingcount.issueCount);
            } else if (item.type === 'reviews') {
              pendingCount = useSelector((state) => state.auth.pendingcount.revewsCount);
            } else if (item.type === 'offers') {
               pendingCount = useSelector((state) => state.auth.pendingcount.offersCount);
            }

            return (
              <Link className="nav-link" to={item.link}>
                <span className="material-symbols-outlined">{item.icon}</span>
                <p className='d-flex align-items-center'>
                {item.title} 
                {item.is_count && item.is_count === 1 && <span className='search-no ms-2'>{pendingCount}</span>}

                </p>
              </Link>
            );
          })()}
        </li>
      )}
    </>
  );
}

export default SidebarItems;
