import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import { MakeAxiosRequest } from '../../utils/handler';
import categoryDummy  from "../../Assets/Images/categorydumy.png"
import loaderFile   from '../../Assets/Images/loader.gif';
import CreateEditModal from './../Category/CreateEditModal';
import noRecords   from '../../Assets/Images/no-records.png'
import ListPagination from "../../Components/Listing/ListPagination";
const ListingComponent = ({ catData,handlePageChange }) => {
    

  const [isEdit, setisEdit] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const handleCloseEdit = () => setshowEdit(false);
  const [categoryDetails, setcategoryDetails] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [isLoaded, setIsLoaded] = useState(false);

  const totalCount = catData?.totalcount;
  const userCategories = catData?.userCategories || [];

  // const   isLoaded  = (totalCount !== undefined) ? false : true;
  const paramsObject = { selected: 0 };

  const fetchData = () => {   
    setIsLoaded(totalCount !== undefined);   
  };
  
  useEffect(() => {
    fetchData();
  }, [totalCount]);
  

  const handlePageClick = (event) => { 
    const newOffset = event.selected;  
    handlePageChange("categories", newOffset);
 
  };
  
  const handleshowEdit = (category) => {
    setisEdit(true)
    setshowEdit(true)
    setcategoryDetails(category)
  };


   
    // Status change of categories 
  const changeStatus = async (categoryKey, status) => {
    try {     
    
      const result = await Swal.fire({
        title: 'Are you sure?',
        html: 'Are you sure you want to ' + status + ' this category?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + status,
        customClass: {  confirmButton: 'btn btn-primary'  },
        cancelButtonText: 'Cancel',
        //showLoaderOnConfirm: true,
        reverseButtons: true,  
        preConfirm: async () => {
          try {
            const res = await MakeAxiosRequest(
              'post', { "status": status }, `category/${categoryKey}/changestatus`
            );
            if (res.status === 1) {
              return true;
            } else {
              throw new Error(res.message);
            }
          } catch (error) {
            console.error(error); // Log the error
            Swal.fire({
              icon: 'warning',
              title: 'Request Failed!',
              customClass: { confirmButton: 'btn btn-primary' },
              text: ` ${error.message}`,
            });
            return false; 
          }
        },
      });
      if (result.isConfirmed) {
        if (result.value === true) {        
          Swal.fire({ icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' },
           text: 'Category ' + status + 'd successfully.'
          });
          handlePageClick(paramsObject);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: 'An error occurred while changing the status.',
      });
    }
  };

   



  return (
    <>

          <div class="tab-pane fade show active" id="categories-tab-pane " role="tabpanel" aria-labelledby="categories-tab" tabindex="0">
            <div class="row d-flex align-items-center justify-content-center flex-wrap ">
              <div class="col-12 mb-3 text-center text-lg-start">
                <h4 class="mt-3">Categories</h4>
                <p class="mb-0">You can view all the categories  you have added to aladinz </p>
              </div>
              
            </div>
            <div class="row d-flex align-items-center justify-content-center flex-wrap ">
              <div class="col-12">
                <div class="tab-service-sec">
               {!isLoaded ? (<div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>):(
                  <div class="row">
                  {userCategories && userCategories.length > 0 ? (
                    <>
                      {userCategories.map((category, optionIndex) => (
                        <div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-3" key={optionIndex}>
                          <div className="services-imgs">
                            {isEmpty(category.deleted_at) ? (
                              <span className="usercat-tag approved-tag position-absolute mt-1 ms-1">Active</span>
                            ) : (
                              <span className="usercat-tag deactive-tag position-absolute mt-1 ms-1">Inactive</span>
                            )}
                            <img className="" src={category.image_path || categoryDummy} alt="" />
                            <p className="mt-2 mb-0">{category.title}</p>
                            <div className="img-drpdown">
                              <div className="btn-group option-btn">
                                <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                  <i className="fa-solid fa-ellipsis"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                <li><a href="javascript:void(0)" className="dropdown-item" onClick={() => handleshowEdit(category)}><i className="fa-solid fa-pen me-2"></i> Edit</a></li>
                                  {isEmpty(category.deleted_at) ? (
                                    <li>
                                      <a href="javascript:void(0)" className="dropdown-item reject" onClick={() => changeStatus(category.category_uuid, 'deactivate')}>
                                        <i className="fa-solid fa-trash-can me-2"></i>Deactivate
                                      </a>
                                    </li>
                                  ) : (
                                    <li>
                                      <a href="javascript:void(0)" className="dropdown-item  success" onClick={() => changeStatus(category.category_uuid, 'activate')}>
                                        <i className="fa-solid fa-square-check me-2"></i>Activate
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <ListPagination pageChange={handlePageClick} totalCount={totalCount} />
                    </>
                  ) : (
                    <>
                      <div className='mt-4 text-center'>
                        <img src={noRecords} style={{ width: '150px' }} alt="No records found" />
                        <div className='mt-3'>There are no categories to display.</div>
                      </div>
                    </>
                  )}
                  </div>
               )}
                </div>
              </div>
            </div>
          </div>
          <CreateEditModal {...{ showEdit, handleCloseEdit, isEdit, categoryDetails, fetchData, perPage, iscategory: '1' }} />    
        </>
      );
    };
  
export default ListingComponent;
