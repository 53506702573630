import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { MakeAxiosRequest } from "../../utils/handler";
import loaderFile from "../../Assets/Images/loader.gif";
import noRecords from "../../Assets/Images/no-records.png";
import dummyBanner from "../../Assets/Images/dummy.jpg";
import offerimg from "../../Assets/Images/offers.png";
import CreateEditModal from "./CreateEditModal";
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import ListPagination from "../../Components/Listing/ListPagination";
import { useNavigate, useParams } from "react-router-dom";

const Listing = ({ searchValue ,setsearchValue}) => {

  const navigate = useNavigate();
  const params = useParams();
  const sortby = params.sortby || "newest";
  const status = params.status || "pending";

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const handleCloseEdit = () => setshowEdit(false);
  const [offerDetails, setofferDetails] = useState({});
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [data, setData] = useState({});
  // const [selectedOption, setSelectedOption] = useState("pending");
  const [listParams, setListParams] = useState({
    offset: "",
    status: status,
    sortBy: sortby,

  });
  // Breadcrumbs
  const updateBreadcrumbs = () => {
    setBreadcrumbsData([{ label: "Offers" }, { label: "Listing" }]);
  };
  const { setBreadcrumbsData } = useBreadcrumb();
  const statusOptions = [
    { value: "2", className: "offer-tag rejected-tag", label: "Rejected" },
    { value: "1", className: "offer-tag approved-tag", label: "Active" },
    { value: "-1", className: "offer-tag pending-tag", label: "Pending" },
  ];
  const renderTextWithLineBreaks = (data) => {
    const html = data.replace(/\n/g, "<br />");
    return { __html: html };
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleShow = () => {
    setisEdit(false);
    setshowEdit(true);
    setofferDetails({});
  };
  const handleSelectChange = (filterName, value) => {
    setsearchValue('');
    setisTableLoaded(false); 
    if(filterName==='sortBy')
      navigate('/offers/'+value+'/'+listParams.status);     
    if(filterName==='status')
      navigate('/offers/'+listParams.sortBy+'/'+value);
    setListParams((prevParams) => ({ ...prevParams, [filterName]: value, offset:'0' }));
  };

  const handleClickChange = (filterName, value) => {
    // setSelectedOption(value);
    setListParams((prevParams) => ({ ...prevParams, [filterName]: value }));
  };

  const handleshowEdit = (offer) => {
    setisEdit(true);
    setshowEdit(true);
    setofferDetails(offer);
  };

  useEffect(() => {
    fetchData();
  }, [perPage, listParams, searchValue]);



  useEffect(() => {
    updateBreadcrumbs();
  }, []);

  const fetchData = async () => {
    try {
      const res = await MakeAxiosRequest(
        "post",
        { ...listParams, searchValue },
        "/offers"
      );
      setIsLoaded(true);
      setisTableLoaded(true);    
      setItems(res.data.offers);
      setFilters(res.data.filters);
      setData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePageClick = (event) => {
    setListParams((prevParams) => ({ ...prevParams, offset: event.selected }));
  };

  const changeStatus = async (offer, status) => {
    var msg = status == "approve" ? "activate" : (offer.status =='1' && status=='reject') ? 'deactivate' : status;
    console.log(offer)
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to " + msg + " this offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, " + msg,
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          { status: status },
          `offer/${offer.offer_uuid}/changestatus`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Offer " + msg + "d successfully.",
          });
          fetchData(1, perPage);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to change the status.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while changing the status.",
      });
    }
  };

  const markPremium = async (offerKey) => {
    console.log(offerKey);
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to mark this offer as premium?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `offer/${offerKey}/markaspremium`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Offer marked as premium.",
          });
          fetchData(1, perPage);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to upgrade the offer.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while upgrading the offer.",
      });
    }
  };

  const UnMarkPremium = async (offerKey) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to unmark premium from this offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",cancelButton: "btn btn-outline-primary"
        },
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const res = await MakeAxiosRequest(
          "post",
          {},
          `offer/${offerKey}/markasunpremium`
        );
        if (res.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Succesfully unmarked this offer from premium.",
          });
          fetchData(1, perPage);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            text: "Failed to downgrade the offer.",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        text: "An error occurred while downgrading the offer.",
      });
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        {" "}
        <center>
          <img src={loaderFile} style={{ width: "150px" }} alt="Loading..." />
        </center>{" "}
      </div>
    );
  } else {
    return (
      <>
        <div className="row d-flex align-items-center flex-wrap ">
         
          <div className="col-lg-12">
            <div className="d-flex align-content-center justify-content-center justify-content-lg-end flex-wrap">
            {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option => 
                  <option value={option.value} selected={listParams[filter.type]===option.value} >{option.option}</option>
                )}                        
              </select>
            </div>
          ))}
              <Button className="btn btn-primary my-2" onClick={handleShow}>
                Add Offer
              </Button>
            </div>
          </div>
        </div>
        {!isTableLoaded ? (<div> <center><img src={loaderFile} style={{ width: '150px' }} alt="Loading..." /></center> </div>):(
        <div className="row mt-4">
          {items.length > 0 ? (
            <>
              {items.map((offers, optionIndex) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 mb-3"
                  key={optionIndex}
                >
                  <div className="card offers-card">
                    <div className="offers-card-hd position-relative">
                      <img
                        className="offer-banner"
                        src={
                          offers.banner_image_path
                            ? offers.banner_image_path
                            : dummyBanner
                        }
                        alt=""
                      />
                      <div className="offer-optn mt-3">
                      {offers.is_expired == '1' ? (
                          <span className="offer-tag expired-tag">Expired</span>
                      ) : (
                          statusOptions.map((option) => (
                              offers.status === option.value && (
                                  <span key={option.value} className={option.className}>
                                      {option.label}
                                  </span>
                              )
                          ))
                      )}

                     
                        <div className="d-flex align-items-center">
                          {offers.is_exclusive === "1" && (
                            <span className="offer-tag  premium-tag">
                              <i className="fa-regular fa-gem me-1"></i>Premium
                            </span>
                          )}
                          <div className="btn-group option-btn">
                            <a
                              href=""
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-display="static"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  className="dropdown-item"
                                  onClick={() => handleshowEdit(offers)}
                                >
                                  <i className="fa-solid fa-pen me-2"></i>Edit
                                </a>
                              </li>
                              {(() => {
                                switch (offers.status) {
                                  case "-1":
                                    return (
                                      <>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item success"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "approve"
                                              )
                                            }
                                          >
                                            <i className="fa-solid  fa-square-check me-2"></i>
                                            Approve
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item reject"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "reject"
                                              )
                                            }
                                          >
                                            <i className="fa-regular fa-trash-can me-2"></i>
                                            Reject
                                          </a>
                                        </li>
                                      </>
                                    );
                                  case "1":
                                    return (
                                      <>
                                        {offers.is_exclusive == 1 ? (
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              className="dropdown-item"
                                              onClick={() =>
                                                UnMarkPremium(offers.offer_uuid)
                                              }
                                            >
                                              <i class="fa-regular fa-rectangle-xmark me-2"></i>
                                              Unmark Premium
                                            </a>
                                          </li>
                                        ) : (
                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              className="dropdown-item"
                                              onClick={() =>
                                                markPremium(offers.offer_uuid)
                                              }
                                            >
                                              <i className="fa-regular fa-square-check me-2"></i>
                                              Mark as premium
                                            </a>
                                          </li>
                                        )}
                                        {/* <li><a href="javascript:void(0)" className="dropdown-item" onClick={() => markPremium(offers.offer_uuid)}><i className="fa-regular fa-square-check me-2"></i> Mark as premium</a></li> */}
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item reject"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "reject"
                                              )
                                            }
                                          >
                                            <i className="fa-regular fa-trash-can me-2"></i>
                                            Deactivate
                                          </a>
                                        </li>
                                      </>
                                    );
                                  case "2":
                                    return (
                                      <>
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="dropdown-item success"
                                            onClick={() =>
                                              changeStatus(
                                                offers,
                                                "approve"
                                              )
                                            }
                                          >
                                            <i className="fa-solid  fa-square-check me-2"></i>
                                            Activate
                                          </a>
                                        </li>
                                      </>
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body offer-dtls">
                      <div className="mb-3">
                        <span className="mb-0">Business Name</span>
                        <p>{offers.subscriber_name}</p>
                      </div>
                      <div className="mb-3">
                        <span className="mb-0">Offer Title</span>
                        <p>{offers.title}</p>
                      </div>
                      <div className="mb-3">
                        <span className='mb-0'>Offer Description</span>
                        <p className='description-sec offer-dscriptn'>
                          <span dangerouslySetInnerHTML={renderTextWithLineBreaks(showFullDescription ? offers.description : (offers.description.length > 50 ? `${offers.description.slice(0, 50)}...` : offers.description))} />
                          {offers.description.length > 50 && (
                            <a className="read-more"  href="javascript:void(0)" onClick={toggleDescription}>
                              {showFullDescription ? ' Read Less' : ' Read More'}
                            </a>
                          )}
                        </p>


                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <span className="mb-0">Start date</span>
                            <p>
                              {format(new Date(offers.start_date),  'dd/MM/y')}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <span className="mb-0">End date</span>
                            <p>
                              {format(new Date(offers.end_date),  'dd/MM/y')}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="offer-img-sec">
                        <span className="">Offer Image</span>
                        <div className="img-sec">
                          <img
                            className="me-2"
                            src={
                              offers.card_image_path
                                ? offers.card_image_path
                                : offerimg
                            }
                          />
                          <p class="mb-0">{offers && offers.original_name.length < 15 ? offers.original_name : `${offers.original_name.slice(0, 15)}...`}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
              <ListPagination
                perpage={12}
                pageChange={handlePageClick}
                totalCount={data.totalcount}
              />
            </>
          ) : (
            <div className="mt-4 text-center">
              <img
                src={noRecords}
                style={{ width: "150px" }}
                alt="No records found"
              />
              <div className="mt-3">There are no offers to display.</div>
            </div>
          )}
        </div>
        )}
        <CreateEditModal
          {...{
            showEdit,
            handleCloseEdit,
            isEdit,
            offerDetails,
            fetchData,
            perPage,
          }}
        />
      </>
    );
  }
};

export default Listing;
