import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './app/store'
import { Provider } from 'react-redux'
import { BreadcrumbProvider } from "./Components/Breadcrumbs/BreadcrumbContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
      <BreadcrumbProvider>
      <App />
    </BreadcrumbProvider>
      </Provider>
    </BrowserRouter>

  </React.StrictMode>
);


