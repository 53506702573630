import React from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

function StarRating(props) {
    const total=props.totalcount || 5;
  return (
    <>
    {/* <div className='d-flex align-items-center'>  */}

        <Stack spacing={1}>
            <Rating name="half-rating-read" defaultValue={props.count} precision={0.5} readOnly />
        </Stack>
            {/* <span class="star-rating mb-2 d-block">
                {Array.from({ length: props.count }, (_, index) => (
                    <i key={index} className="fa-solid fa-star me-1"></i>
                ))}</span>
            <span class="mb-2 d-block">
                {Array.from({ length: total-props.count }, (_, index) => (
                    <i key={index} className="fa-solid fa-star me-1"></i>
                ))}</span> */}

        <p>{props.count}/{total}</p>
    {/* </div> */}
    </>
  )
}

export default StarRating