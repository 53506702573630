import React, {useState, useEffect,useRef } from 'react';
import DataTable from 'react-data-table-component';
import { MakeAxiosRequest } from '../../utils/handler';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
 import noRecords   from '../../Assets/Images/no-records.png';
 import loaderFile  from '../../Assets/Images/loader.gif';
import { useBreadcrumb } from "../../Components/Breadcrumbs/BreadcrumbContext";
import ShowMoreModal from "../../Components/Listing/ShowMoreModal";
import DataTableList from "../../Components/Datatablelist/Datatablelisting";
import UserRowActions from "../../Components/Datatablelist/Tablerowactions";
import { useNavigate, useParams } from 'react-router-dom';

function Listing({searchValue, setsearchValue}) {

  const navigate = useNavigate();
  const params = useParams();
  const sortby = params.sortby || "newest";
  const status = params.status || "pending";

  const statusOptions = [
    { value: 2, className: "status-tag status-tag-pending", label: "Pending" },
    { value: 0, className: "status-tag status-tag-inactive", label: "Rejected" },
    { value: 1, className: "status-tag status-tag-active", label: "Approved" },
  ];
  const formRef = useRef(null); 

 const [listerror, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTableLoaded, setisTableLoaded] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [issueKey, setIssueKey] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState([]);
  const [issueStatusFilters, setIssueStatusFilters] = useState([]);
  const [agentrolefilters, setReportTypefilters] = useState([]);
  const [selectedSortOption, setSortOption] = useState('newest');
  const [selectedStatusOption, setStatusOption] = useState('pending');
  const { setBreadcrumbsData } = useBreadcrumb();
  const [listParams, setListParams] = useState({
    offset: "0",
    status: status,
    sortBy: sortby,
  });
  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState(false);
  const [title, setTitle] = useState('');

  const openShowMore = (text,title) => {
    setText(text);
    setShowMore(true);
    setTitle(title);
  };

  const closeShowMore = () => {
    setShowMore(false);
  };

 
  const [isReject,setIsReject] = useState(false);

  const validationRules = {
    required: isReject ? 'Please enter reason for rejection' : 'Please enter the note',
    minLength: {
      value: 20, 
      message: isReject ? 'Reason must be at least 20 characters long' : 'Description must be at least 20 characters long',
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
   // setError,
    reset,
  } = useForm();
  const columns = [
    {
      name: <div className="datatable-hd"><h6>Name</h6></div>,
      selector: (row) => (
        <UserRowActions
        row={{
          primaryData: row.user_name,
          imagePath :row.user_image,
           viewUrl: `/user/${row.userkey}`
        }}  />    
      ),
       
      
    },
    {
      name: <div className="datatable-hd"><h6>Reported Issue</h6></div>,
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <p>{row.issue_type}</p>
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Description</h6></div>,
      selector: (row) => (
        <div data-title="" className="numeric  data-table-sec">
          <a href="javascript:void(0)" onClick={() => openShowMore(row.description,'Description')}>
            <p>{row.description.slice(0, 15)}{row.description.length > 15 && <a>...</a>}</p>
          </a>
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Date of submission</h6></div>,
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
          <span>{format(new Date(row.created_at),  'dd/MM/y')}</span>
        </div>
      ),
    },
    {
      name: <div className="datatable-hd"><h6>Status</h6></div>,
      selector: row => (
        <div data-title="" className="numeric data-table-sec">
          {statusOptions.map(option => {
            return row.status_id === option.value && (
              <span key={option.value} className={option.className}>{option.label}</span>
            );
          })}
       </div>
      ),
    },
    
      ...(selectedStatusOption === 'completed'
      ? [
          {
            name: <div className="datatable-hd"><h6>Notes</h6></div>,
            selector: (row) => (
              <div data-title="" className="numeric  data-table-sec">
                <a href="javascript:void(0)" onClick={() => openShowMore(row.action_taken, 'Note')}>
                  <p>
                    {row.action_taken ? (
                      <>
                        {row.action_taken.slice(0, 30)}
                        {row.action_taken.length > 30 && <a>...</a>}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </a>
              </div>
            ),
          },
          {
            name: <div className="datatable-hd"><h6>Action Taken By</h6></div>,
            selector: (row) => (
              <div data-title="" className="numeric data-table-sec">
                <div className="profile-sec">
                  <img src={row.action_user_image} alt="" />
                  <div>
                    <p>{`${row.action_user_name}`}</p>
                  </div>
                </div>
              </div>
            ),
          },
        ]
      : []),
  
      ...(selectedStatusOption === 'rejected' 
      ? [
        {
          name: <div className="datatable-hd"><h6>Reason for rejection</h6></div>,
          selector: (row) => (
            <div data-title="" className="numeric data-table-sec">
              {row.rejected_reason && (
                <> 
                  <a href="javascript:void(0)" onClick={() => openShowMore(row.rejected_reason, 'Reason for rejection')}>
                    <p>
                      {row.rejected_reason.slice(0, 30)}
                      {row.rejected_reason.length > 30 && <a>...</a>}
                    </p>
                  </a>
                </>
              )}
            </div>
          ),
        },
        
        
    {
      name: <div className="datatable-hd"><h6>Rejected By</h6></div>,
      selector: (row) => (
        <div data-title="" className="numeric data-table-sec">
        <div className="profile-sec">
          <img src={row.rejected_user_image} alt="" />
          <div>
            <p>{`${row.rejected_user_name}`}</p>
          </div>
        </div>
      </div>
      ),

    },
    ]
    : []),
    
    ...(selectedStatusOption === 'pending'
    ? [
        {
          name: (
            <div className="datatable-hd">
              <h6>Actions</h6>
            </div>
          ),
          selector: (row) => (
            <div data-title="" className="numeric data-table-sec">
              <div className="d-flex justify-content-end">
                <div className="btn-group option-btn">
                  <a href="" className="btn dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    <i className="fa-solid fa-ellipsis"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a href="javascript:void(0)" className="dropdown-item success" onClick={() => statusChange(row.issue_uuid, 'Approve')}>
                        <i className="fa-solid fa-square-check me-2"></i>Take Action
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="dropdown-item reject" onClick={() => statusChange(row.issue_uuid, 'Reject')}>
                        <i className="fa-regular fa-trash-can me-2"></i>Reject
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ),
        },
      ]
    : []),
  ];
  


const statusChange = async (issueKey,status) => {
  formRef.current.reset();
  reset(); 
  if(status==='Approve'){
    $('#maintext').text('Is this issue resolved?')
    $('#subtext').text('What action did you take to resolve this issue')
    $('#desclabel').text('Note')
    setIsReject(false)
  }else{
    $('#maintext').text('Reject this issue?')
    $('#subtext').text('State the reason for rejection')
    $('#desclabel').text('Reason for rejection')
    setIsReject(true)
  }

  setIssueKey(issueKey);
  setSelectedStatus(status);
 
  $('#statusChgModal').modal('show');
  
};
 

useEffect(() => {
  reportIssueData();
}, [listParams,searchValue]);
    // Breadcrumbs
    const updateBreadcrumbs = () => {
      setBreadcrumbsData([
        { label: "Reported issues" },
        { label: "Listing"},
      ]);
    };  
    useEffect(() => {
      updateBreadcrumbs();
    }, []); 

  const reportIssueData = async () => {   
     
      const res = await MakeAxiosRequest("post", {...listParams,searchValue}, "/reportedissues");
      
      setIsLoaded(true);
      setisTableLoaded(true);
      setReportData(res.data.issues);
      setFilters(res.data.filters)
      setIssueStatusFilters(res.data.issuestatusFilters)

      let totalCount; 

      if (selectedStatusOption === 'pending') {
        totalCount = res.data.pendingcount;
      } else if (selectedStatusOption === 'completes') {
        totalCount = res.data.completedcount;
      } else if (selectedStatusOption === 'rejected') {
        totalCount = res.data.rejectedcount;
      }
      setTotalRows(totalCount)
      
  }



 
    const handlePageChange = page => {
      const newOffset = (page - 1) ;     
      setListParams(prevParams => ({ ...prevParams, offset: newOffset.toString() }));    
    }
 
  const handleSelectChange = (filterName,value) => {  
    setsearchValue(''); 
    setisTableLoaded(false); 
    // alert(selectedStatusOption)
    if(filterName==='sortBy')
      navigate('/reportedissues/'+value+'/'+listParams.status);   
    if(filterName==='status')
      navigate('/reportedissues/'+listParams.sortBy+'/'+value);
    filterName==='sortBy'?    setSortOption(value) : setStatusOption(value);   
    setListParams(prevParams => ({ ...prevParams,  [filterName]: value, offset:'0' }));
  };


   

    const issueStatus = async () => {
     const key = issueKey
      try {
        const description = $("#description").val();    
          const response = await MakeAxiosRequest("post", {"status": selectedStatus,"description":description}, `/reportedissue/${key}/changestatus`);
          if (response.status===1) {
            Swal.fire({
              icon: 'success',    title: 'Success!',     customClass: {   confirmButton: 'btn btn-primary' },
              text: response.message
            });  
            formRef.current.reset();  
            $('#statusChgModal').modal('hide'); 
            reportIssueData();          
          }else{
            Swal.fire({ icon: 'error', title: 'Error!',   customClass: {  confirmButton: 'btn btn-primary' }, text: response.message  }); 
          }         
    
      } catch (error) {     
        console.error(' error:', error);
      }
    };

 



  if (listerror) {
    return <div>Error: {listerror.message}</div>;
  } else if (!isLoaded) {
    return <div> <center><img src={loaderFile} style={{ width: '125px' }} alt="Loading..." /></center> </div>;
  } else {
    return (<>
    
    <div className="row d-flex align-items-center flex-wrap ">
   
        <div className="col-xl-12 mb-3">
          <div className="d-flex align-content-center justify-content-center justify-content-md-end flex-wrap">
          {filters?.map((filter, index) => (
            <div className="sort-sec my-2 me-2" key={index}>
              <p className="me-1">{filter.title} : </p>
              <select  className="form-select"  aria-label="Default select example" onChange={(e) => handleSelectChange(filter.type, e.target.value)}  >
                {filter.options?.map( option =>                   
                  <option value={option.value} selected={listParams[filter.type]===option.value} >{option.option}</option>
                )}                        
              </select>
            </div>
          ))}
            
          </div>
          </div>
        </div>
        <div className="">  
       
         <DataTableList  pageChange={handlePageChange}   totalCount={totalRows}  columns={columns}  tableData={reportData}   type ={'issues'} isTableLoaded ={isTableLoaded}/>    
      
        </div>
       
            

        
          <div class="modal fade" id="statusChgModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12 mt-4">
                        <div class="text-center">
                          <h5 id="maintext"></h5>
                          <p class="gray" id="subtext"></p>
                        </div>                            
                    </div>
                  </div>
                  <form class="row mt-4" ref={formRef}    onSubmit={handleSubmit(issueStatus)}>                  
                      <div class="col-md-12 col-lg-12">
                        <div class="row ">
                          <input type="hidden" id="issuekey" value=""/>
                          <input type="hidden" id="status" value=""/>
                          <div class="col-12">
                            <label for="exampleFormControlTextarea1" id="desclabel" class="form-label"></label>
                            <textarea class="form-control" id="description" name="description" rows="3"
                             {...register("description",validationRules)}
                            ></textarea>
                            {errors.description && (
                              <span className="error">{errors.description.message}</span>
                            )}

                          </div>
                        </div>                        
                      </div>
                      <div class="modal-footer b-0 mt-5">
                        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-primary" >Submit</button>
                      </div>
                  </form>
                </div>
              
              </div>
            </div>
          </div>
          <ShowMoreModal {...{ showMore, closeShowMore, text,title }} />
      </>
    );
  }
}

export default Listing;
